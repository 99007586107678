import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';
import { transparentTheme } from 'theme';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import iso from 'img/logo.svg';
import plus from 'img/plus.svg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
  main: {
    position: 'relative',
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 75,
    },
  },
  sitemap: {
    maxWidth: 1400,
  },
  title: {
    whiteSpace: 'nowrap',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginBottom: 10,
    }
  },
  divider: {
    // marginTop: 18,
  },
  collapse: {
    paddingBottom: 18,
  }
}));

const Plus = () => (
  <div style={{
    display: 'inline-block',
    backgroundImage: `url('${plus}')`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 13,
    height: 13,
    marginRight: 12,
  }} />
)

const Item = ({children, showArrow = false, to = '/'}) => (
  <Link to={to}>
    <Typography component='div' color='textPrimary' variant='body2'>
      {showArrow &&
        <ArrowForwardIosIcon fontSize='inherit' style={{
          marginLeft: 16,
          marginRight: 4,
          position: 'relative',
          top: 2
        }} />
      }
      {children}
    </Typography>
  </Link>
);


const Payment = ({ classes, mobile }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box component={Typography} fontSize={{xs: 18}} classes={{ root: classes.title }} onClick={() => setOpen(state => !state)}>
        {mobile && <Plus />}
        MEDIOS DE PAGO
      </Box>
      <Collapse in={!mobile || open} classes={{ wrapperInner: classes.collapse }}>
        <Item showArrow={mobile}>Visa</Item>
        <Item showArrow={mobile}>Mastercard</Item>
        <Item showArrow={mobile}>American Express</Item>
        <Item showArrow={mobile}>Maestro</Item>
        <Item showArrow={mobile}>Mercado Pago</Item>
      </Collapse>
    </>
  )
};



const Us = ({ classes, mobile }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box component={Typography} fontSize={{xs: 18}} classes={{ root: classes.title }} onClick={() => setOpen(state => !state)}>
        {mobile && <Plus />}
        TODO EN ORDEN
      </Box>
      <Collapse in={!mobile || open} classes={{ wrapperInner: classes.collapse }}>
        <Item showArrow={mobile} to='/nosotros'>Nosotros</Item>
        <Item showArrow={mobile} to='/faq'>Empleos</Item>
        <Item showArrow={mobile} to='/faq'>Prensa</Item>
        <Item showArrow={mobile}>Planes</Item>
        <Item showArrow={mobile} to='/faq'>Empresas</Item>
        <Item showArrow={mobile}>Contacto</Item>
      </Collapse>
    </>
  )
};



const Support = ({ classes, mobile }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box component={Typography} fontSize={{xs: 18}} classes={{ root: classes.title }} onClick={() => setOpen(state => !state)}>
        {mobile && <Plus />}
        SOPORTE TÉCNICO
      </Box>
      <Collapse in={!mobile || open} classes={{ wrapperInner: classes.collapse }}>
        <Item showArrow={mobile} to='/faq'>Reglas</Item>
        <Item showArrow={mobile} to='/faq'>Preguntas frecuentes</Item>
        <Item showArrow={mobile} to='/tyc'>Confdencialidad</Item>
        <Item showArrow={mobile} to='/tyc'>Área de cobertura</Item>
        <Item showArrow={mobile} to='/tyc'>Términos y condiciones</Item>
      </Collapse>
    </>
  )
};



const Social = ({ classes, mobile }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box component={Typography} fontSize={{xs: 18}} classes={{ root: classes.title }} onClick={() => setOpen(state => !state)}>
        {mobile && <Plus />}
        SEGUINOS
      </Box>
      <Collapse in={!mobile || open} classes={{ wrapperInner: classes.collapse }}>
        <Item showArrow={mobile}>Facebook</Item>
        <Item showArrow={mobile}>Instagram</Item>
      </Collapse>
    </>
  )
};


const FooterLanding = () => {
  const theme = useTheme();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ThemeProvider theme={transparentTheme}>
      <Grid container classes={{ root: classes.main }}>
        
        <Grid item container xs={12} justify='center' style={{marginTop: 70, marginBottom: 30}}>
          <Grid item xs={xs ? 10 : 12}>
          <Typography component='div' align={'center'} >
              <div style={{
                display: 'inline-block',
              }}>
                <div style={{
                  width: 70,
                  height: 70,
                  backgroundImage: `url('${iso}')`,
                  backgroundPosition: '50% 50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  marginRight: 10,
                  top: 7,
                  position: 'relative',
                }} />
              </div>
              </Typography>
              <Typography component='div' align={'center'} >
              Hecho con ♥ en Argentina 2018 ©&nbsp;
              <Hidden smUp>
                <br />
              </Hidden>
              Todo en Orden. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default FooterLanding
