export const endpoint = `${process.env.REACT_APP_API}/register`;

export const adapterOut = (collected) => {
  const {
    name,
    last,
    mail,
    phone,
    address,
    addressNumber,
    apartment,
    zip,
    state,
    password,
    confirm,
    is_company,
    company:{
      name: company_name,
      address: company_address,
      cuit: company_cuit,
      role: company_role,
      phone: company_phone,
    },
    terms,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    name: name,
    last: last,
    mail: mail,
    phone: phone,
    address: address,
    address_number: addressNumber,
    apartment: apartment,
    zip: zip,
    state: state,
    password: password,
    confirm: confirm,
    is_company: is_company,
    company_name: company_name,
    company_address: company_address,
    company_cuit: company_cuit,
    company_role: company_role,
    company_phone: company_phone,
    terms: terms,
  };
  //console.log("Adapted data: ", adapted);
  // const data = new FormData();
  // Object.keys(adapted).forEach(key => {
  //   data.append(key, adapted[key])
  // });
  return JSON.stringify(adapted);
};

export const adapterIn = (response) => {
  const {
    ...keys
  }  = response;
  //console.log("Collected data: ", collected);

  const adapted = (typeof response === 'string') ?
    response :
    {
    ...keys
    };
  //console.log("Adapted data: ", adapted);
  return adapted;
};
