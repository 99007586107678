export const endpoint = `${process.env.REACT_APP_API}/direction`;

export const adapterOut = (collected) => {
  const {
    street,
    number,
    dpto,
    zip_code,
    state,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    street : street,
    number : number,
    dpto : dpto,
    zip_code : zip_code,
    state : state,
  };
  //console.log("Adapted data: ", adapted);

  const data = new FormData();
  Object.keys(adapted).forEach(key => {
    data.append(key, adapted[key])
  });
  return data;
};

export const adapterIn = (response) => {
  const {
    directions
  }  = response;
  //console.log("Response data: ", response);
  const latest = (typeof response === 'string') ?
    response : directions.sort((a,b)=>b.id-a.id)[0];
  const {
    street,
    number,
    dpto,
    zip_code,
    city,
    id,
  } = latest;

  const adapted = {
    street,
    number,
    dpto,
    zip_code,
    state: city,
    id,
  }
  //console.log("Adapted data: ", adapted);
  return adapted;
};
