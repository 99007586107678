import React from 'react'

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

import { useTheme } from '@material-ui/core/styles';

const Us = () => {
  const theme = useTheme();

  return (
    <Container component={Box} maxWidth={false} color='primary.main' bgcolor='grey.100' top={0} p={3} disableGutters>
      <Container  maxWidth='lg' >
        <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
          <Typography variant='h4' color='secondary' >Nosotros</Typography>
          <p>Llegó el día, la hora y el momento de gritar por última vez: POR EL PODER DE GRAYSKULL y guardar la colección vintage de los muñecos de He-Man que te acompañan y ocupan lugar desde tu infancia (“Por el poder de Grayskull” era la frase que usaba el príncipe Adam para convertirse en He-Man, una serie de animación de los años 80s seguramente archivada en algún lugar de Hollywood).</p>
          <p>Todo en orden es más que una baulera y más que un depósito, es como una especie de programa de protección al testigo pero para esas cosas que ya no sabes para qué las tenés y que te sacan espacio. ¿Por qué nos comparamos con un programa de protección al testigo? Porque no hacemos desaparecer nada, simplemente guardamos, ordenamos, protegemos y te mostramos tus cosas para que sepas exactamente lo qué tenés y puedas recuperarlo cuando quieras.</p>
          <p>Quién sabe quizás algún día puedas volver a necesitar esa tabla de snowboard que nunca estrenaste, o el tamagochi con las pilas sulfatadas o tengas que enfrentarte contra Skeletor y necesites consejos de Orco. Uno nunca sabe, quizás mañana cuando He-Man vuelva a estar de moda, lo encuentres fácilmente y lo vendas a ese coleccionista por una suma exorbitante que te hará gritar POR EL PODER DE GRAYSKULL.</p>
          <br />
          <Box component={Typography} variant='h4' color='secondary.main' mb={2}>¿Por qué Todo En Orden?</Box>
          <Typography variant='h5'>Comodidad</Typography>
          <p>Hacemos todo por vos para que nunca te muevas de tu casa. Contratás un plan de manera online, coordinamos una mudanza en pocos clicks, embalamos, cargamos y luego subimos las fotos de tus cosas a tu catálogo online para veas lo que tenés y puedas recuperarlo cuando quieras.</p>
          <Typography variant='h5'>Seguridad</Typography>
          <p>Sabemos lo importate que son tus cosas. Por eso, nuestros espacios son cerrados, están vigilados las 24 horas y nadie más que nosotros tiene acceso a los depósitos. Además, todos nuestros planes cuentan con un seguro general.</p>
          <Typography variant='h5'>Control</Typography>
          <p>Queremos que tomes buenas decisiones sobre tus cosas. Gracias al catálogo online vas a poder ordenar y visualizar las fotos de todo lo que tenes para poder dimensionarlo mejor. Así podrás decidir si queres dejarlo guardado, venderlo, donarlo o regalarlo.</p>
          <Typography variant='h5'>Sustenabilidad</Typography>
          <p>Creemos en que todas las cosas tienen valor y se pueden reutilizar. A veces está bueno hacer el ejercicio de soltar lo que ocupa espacio. Quizás alguna cosa hoy no sea importante para vos pero para otra persona sí lo sea.</p>
          <Box>
            <Typography variant='h4' color='secondary'>Contacto</Typography>
            <Box component='p' color='text.secondary'>Si tenés alguna duda sobre Todo en orden, hacer un cambio en tu plan, trabajar con nosotros, contratar nuestro servicio o simplemente querés preguntarnos algo, por favor <Link to='/#atencion'>
              escribinos
            </Link> y te contestaremos lo antes posible.</Box>
          </Box>
        </Box>
      </Container>
    </Container>
  )
}

export default Us
