import React from 'react'
import { Link } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Text, Switch, Checkbox } from 'components/form';

import { useTheme } from '@material-ui/core/styles';
import { useMessage } from 'components/message';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { setRegistrationSecret } from 'features/mainSlice';

import {adapterIn, adapterOut, endpoint} from './config';

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Falta completar este campo'),
  last: Yup.string()
    .required('Falta completar este campo'),
  mail: Yup.string()
    .email('Debe ingresar un email válido')
    .required('Falta completar este campo'),
  phone: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  address: Yup.string()
    .required('Falta completar este campo'),
  addressNumber: Yup.number()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  apartment: Yup.string()
    .required('Falta completar este campo'),
  zip: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  state: Yup.string()
    .required('Falta completar este campo'),
  password: Yup.string()
    .min(6, 'Ingrese al menos 6 caracteres')
    .required('Falta completar este campo'),
  confirm: Yup.string()
    .required('Falta completar este campo')
    .test('password-check', 'Las contraseñas no coinciden', function (value) {
      return this.parent.password === value;
    }),
  is_company: Yup.boolean(),
  company: Yup.object({
    name: Yup.string()
      .required('Falta completar este campo'),
    address: Yup.string()
      .required('Falta completar este campo'),
    cuit: Yup.string()
      .required('Falta completar este campo'),
    role: Yup.string()
      .required('Falta completar este campo'),
    phone: Yup.string()
      .required('Falta completar este campo'),
  }).when('is_company', (is_company, schema) =>
    is_company ? schema.required('Debe llenar los datos de la empresa') : Yup.mixed()
      .notRequired()
      .strip()
  ),
  terms: Yup.boolean()
    .oneOf([true], 'Debe aceptar los términos y condiciones del servicio para registrarse')
    .required(),
})

const HiddenForm = ({ flag = '', children }) => {
  const { values } = useFormikContext();
  return (
    <Hidden xsUp={!values[flag]}>
      {children}
    </Hidden>
  )
}


const TermsError = () => {
  const {errors:{terms: error}, touched:{terms: touched}} = useFormikContext();
  return !touched ? null:(
    <Box display='flex' justifyContent='center' position="relative">
      <FormHelperText error>{error}</FormHelperText>
    </Box>
  );
};


const Register = () => {
  const theme = useTheme();
  const { enqueueMessage } = useMessage();
  const history = useHistory();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Formik
      initialValues={{
        name: '',
        last: '',
        mail: '',
        phone: '',
        address: '',
        addressNumber: '',
        apartment: '',
        zip: '',
        state: '',
        password: '',
        confirm: '',
        is_company: false,
        company: {
          name: '',
          address: '',
          cuit: '',
          role: '',
          phone: '',
        },
        terms: false,
      }}
      validationSchema={validationSchema}

      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const response = await fetch(endpoint, {
          method: "POST",
          body: adapterOut(values),
          headers: {
            'Content-Type': `application/json`,
            accept: `application/json`,
          }
        });
        setSubmitting(false);

        let result = {};
        try {
          result = await response.json();
          if(!response.ok) throw new Error();

          const adapted = adapterIn(result);
          dispatch(setRegistrationSecret(adapted)); //
          history.push('/validate')
        } catch(e){
          if(result.errors) {
            setErrors(result.errors)
          };
          Object.values(result.errors).forEach( message =>
            enqueueMessage({
              title: message,
              // body: 'No hemos podido crear su cuenta',
              variant: 'error',
            })
          )
        }
      }}
    >
    {({ isSubmitting }) =>
      <Box component={Form} bgcolor='grey.100' py={6}>
        <Backdrop open={isSubmitting} style={{position:'absolute', zIndex:5}}>
          <CircularProgress/>
        </Backdrop>
        <Box component={Grid} container justify='center' px={3}>
          <Grid container>
            <Hidden mdUp>
              <Grid item container xs={12} justify='center'>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Espacio
                </Box>
                <Box component={Typography} variant='body1' color='text.secondary' mx={1} mb={3}>
                  Mudanza
                </Box>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Pago
                </Box>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Resumen
                </Box>
              </Grid>
            </Hidden>
            <Grid item container xs={12} justify='center' style={{ paddingBottom: 50, }}>
              <Hidden smDown>
                <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>
                    Creá tu cuenta en Todo en Orden
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Box component={Typography} fontWeight='bold' variant='h2' color='text.secondary' align='center' gutterBottom>
                    Creá tu cuenta
                </Box>
              </Hidden>
            </Grid>
          </Grid>
          <Box component={Grid}
            container
            style={{
              maxWidth: 1000,
              backgroundColor: theme.palette.bg,
              borderRadius: 15,
          }} justify='center' spacing={3}
            pb={{xs: 2, sm: 6}}
          >
            <Hidden smDown>
              <Grid item container xs={12} justify='center'>
                <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={3}>
                  Espacio
                </Box>
                <Box component={Typography} variant='subtitle2' color='text.secondary' mx={3} mt={3}>
                  Mudanza
                </Box>
                <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={3}>
                  Pago
                </Box>
                <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={3}>
                  Resumen
                </Box>
              </Grid>
            </Hidden>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='name' label='Nombre *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='last' label='Apellido *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='mail' label='Email *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='phone' label='Teléfono *' type='tel' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='address' label='Dirección *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={5} sm={5} justify='center'>
                <Text fullWidth color='secondary' name='addressNumber' label='Número *' style={{ paddingRight: 20 }} />
              </Grid>
              <Grid item container xs={5} sm={4} justify='center'>
                <Text fullWidth color='secondary' name='apartment' label='Piso/Depto *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='zip' label='CP *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='state' label='Provincia/Localidad *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='password' label='Contraseña *' type='password' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='confirm' label='Repetir contraseña *' type='password' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9}>
                <Typography color='secondary' style={{ marginRight: 30 }}>
                  ¿Sos empresa?
                </Typography>
                <Switch name={'is_company'} />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'></Grid>
            <HiddenForm flag='is_company'>
              <Grid item container xs={12} sm={6} justify='center'>
                <Grid item container xs={10} sm={9} justify='center'>
                  <Text fullWidth color='secondary' name='company.name' label='Nombre *' />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} justify='center'>
                <Grid item container xs={10} sm={9} justify='center'>
                  <Text fullWidth color='secondary' name='company.address' label='Dirección *' />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} justify='center'>
                <Grid item container xs={10} sm={9} justify='center'>
                  <Text fullWidth color='secondary' name='company.cuit' label='CUIT de la empresa *' />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} justify='center'>
                <Grid item container xs={10} sm={9} justify='center'>
                  <Text fullWidth color='secondary' name='company.role' label='Tu rol en la empresa *' />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} justify='center'>
                <Grid item container xs={10} sm={9} justify='center'>
                  <Text fullWidth color='secondary' name='company.phone' label='Teléfono de la empresa *' type='tel' />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} justify='center'></Grid>
            </HiddenForm>

            <Grid item container xs={12} justify="center" style={{ paddingTop: 50, }}>
              <Hidden mdUp>
                <Box component={Grid}
                  item container
                  xs={12} md={10}
                  mb={1}
                  justify="center" direction="column"
                  >
                  <TermsError />
                  <Box component={Typography} variant={'body1'} color="text.secondary" align='center' fontSize='min( 4vw, 20px )' mb={4}>
                    <Checkbox name='terms' style={{ display: 'inline-block' }} />
                    Acepto los <a href="/tyc" target="_blank">términos y condiciones</a>
                  </Box>
                </Box>
              </Hidden>
              <Grid item container xs={12} md={10} justify='space-between'>
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <Link to='/'>
                    <Button
                      variant="contained"
                      color="primary"
                      size={xs?'large':'medium'}
                    >
                      Atrás
                    </Button>
                  </Link>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  position='relative'
                >
                  <Hidden smDown>
                  	<Box component={Typography}
                      variant={'h6'}
                      color="text.secondary"
                      align='center'
                      fontSize={{ sm: 16 }}
                      height={35}
                    >
                  	  <Checkbox
                        name='terms'
                        style={{ display: 'inline-block' }}
                        offColor={theme.palette.grey[500]}
                      />
                  	  Acepto los <Box component='a' href="/tyc" target="_blank" color="text.secondary">términos y condiciones</Box>
                  	</Box>
                  </Hidden>
                  <Link to={`${sm?'/':''}#atencion`}>
                    <Box
                      component={Typography}
                      variant={'body2'}
                      color="text.secondary"
                      fontSize='min( 3.4vw, 16px )'
                    >
                      ¿Necesitás ayuda?
                    </Box>
                  </Link>
                  <Hidden smDown>
                    <TermsError />
                  </Hidden>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size={xs?'large':'medium'}
                    type='submit'
                  >
                    Seguir
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    }
    </Formik>
  )
}

export default Register
