import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Carousel from 'nuka-carousel';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import funciona1 from 'img/funciona1.svg';
import funciona2 from 'img/funciona2.svg';
import funciona3 from 'img/funciona3.svg';
import funciona4 from 'img/funciona4.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '70px 20px',
    [theme.breakpoints.up('sm')]:{
      padding: '70px 40px',
    },
    [theme.breakpoints.up('md')]:{
      padding: '150px 40px',
    },
    '&>*':{
      maxWidth: 1400,
    }
  },
  title: {
    padding: '0 0 40px',
    [theme.breakpoints.up('sm')]:{
      padding: '0 0 40px',
    },
    [theme.breakpoints.up('md')]:{
      padding: '0 0 100px',
    },
  },
  caption:{
    maxWidth: 250,
  },
  subtitle:{
    marginBottom: 15,
    marginTop: 10,
    [theme.breakpoints.up('xs')]:{
      marginTop: 30,
    },
  },
}));

const Image = ({src}) => {
  return (
    <Box
      height={{
        xs: 130,
        sm: 200
      }}
      style={{
        width: '100%',
        minHeight: 150,
        maxHeight: 200,
        backgroundImage: `url('${src}')`,
        backgroundPosition: '50% 100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

const Caption = ({classes={}, children, ...props}) => (
  <Typography classes={{root: classes.caption}} variant='body2' color='textSecondary' align='center' {...props}>{children}</Typography>
)
const Title = ({classes={}, children, ...props}) => (
  <Typography classes={{root: classes.subtitle}} variant='h6' color={'textSecondary'} align='center' {...props}>{children}</Typography>
)
const Card = ({children, ...props}) => (
  <Grid item container direction='column' xs={12} md={3} alignItems='center' {...props}>{children}</Grid>
)

const HowItWorks = () => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const Wrapper = sm ? Carousel :  ({children}) => <React.Fragment>{children}</React.Fragment>;

  return (
    <Grid classes={{root: classes.wrapper}} container direction='column' alignItems='center'>
      <Grid classes={{root: classes.title}} item container direction='column'>
        <Typography variant='h2' color='textSecondary' align='center'>
        Cómo funciona nuestro servicio de alquiler de guardamuebles y bauleras
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Wrapper
          autoplay
          wrapAround
          renderBottomCenterControls={null}
          slidesToShow={xs?1:2}
          renderTopLeftControls={({previousSlide})=>(
            <Box
              marginTop={{xs: '50px', sm: '80px'}}
            >
              <IconButton onClick={previousSlide}>
                <Typography color='textSecondary'>
                  <ArrowBackIosIcon/>
                </Typography>
              </IconButton>
            </Box>
          )}
          renderTopRightControls={({nextSlide})=>(
            <Box
              marginTop={{xs: '50px', sm: '80px'}}
            >
              <IconButton onClick={nextSlide}>
                <Typography color='textSecondary'>
                  <ArrowForwardIosIcon/>
                </Typography>
              </IconButton>
            </Box>
          )}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
        	<Card>
        	  <Image src={funciona1} />
        	  <Title classes={classes}>
        	    Mudamos
        	  </Title>
        	  <Caption classes={classes}>
              Nos encargamos de embalar y mover tus cosas a nuestro depósito. 
              <br />
              Vos no haces nada.
        	  </Caption>
        	</Card>
        	<Card>
        	  <Image src={funciona2} />
        	  <Title classes={classes}>
              Controlás tus cosas
        	  </Title>
        	  <Caption classes={classes}>
              Accedé a tus cosas desde el catálogo online en tu teléfono.
        	  </Caption>
        	</Card>
        	<Card>
        	  <Image src={funciona3} />
        	  <Title classes={classes}>
              Pedís lo que quieras
        	  </Title>
        	  <Caption classes={classes}>
              Elegí los objetos que quieras en tu catálogo y te lo llevamos a donde vos digas.
        	  </Caption>
        	</Card>
        	<Card>
        	  <Image src={funciona4} />
        	  <Title classes={classes}>
        	    Liberamos
        	  </Title>
        	  <Caption classes={classes}>
        	    Te ayudamos a desprenderte de las cosas que ya no necesitás, vendiéndolas o donándolas.
        	  </Caption>
        	</Card>
        </Wrapper>
      </Grid>
    </Grid>
  )
}

export default HowItWorks