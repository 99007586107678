import React, { useState, useMemo } from 'react'
import { Link, useHistory } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Text, Phone, Date, Switch, Checkbox } from 'components/form';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useSelector, useDispatch } from 'react-redux';
import { updatePlan, selectPlan } from 'features/mainSlice';

import { formatCurrency } from 'utils';

import { Formik, Form, useFormikContext, yupToFormErrors, useField } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';

const validationSchema = Yup.object({
  date: Yup.object()
    .nullable()
    .required('Elija un día'),
  time: Yup.string()
    .oneOf(['10:00 a 12:00', '12:00 a 16:00', '16:00 a 18:00'])
    .nullable()
    .required('Elija un rago horario'),
})


const TimeRanges = ({options: timeRanges, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const callbacks = useMemo(() =>
    timeRanges.map(range => () => helpers.setValue(range))
  , [timeRanges, helpers]);

  return(
  <>
    {timeRanges.map((range, i) =>
      <Box component={Grid} item container maxWidth={xs ? 200 : 250} key={range} pb={xs ? 1 : 3}>
        <Button
          variant='contained'
          color={range===field.value?'primary':'secondary'}
          onClick={callbacks[i]}
          fullWidth
          size={xs?'large':'medium'}
        >
          {range}
        </Button>
      </Box>
    )}
  </>
  )
};


const Appointment = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const history = useHistory();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Formik
      initialValues={{
        date: moment(),
        time: '',
      }}
      validationSchema={validationSchema}

      onSubmit={(values, { setSubmitting }) => {
        dispatch(updatePlan({
          date: values.date.format('YYYY-MM-DD'),
          from_hour: values.time.match(/\d\d/g)[0], // TODO: This is a hack, handle times better
          to_hour: values.time.match(/\d\d/g)[2],
        }))
        setSubmitting(false);
        history.push('/payment')
      }}

    >{({ errors, isSubmitting }) => (
      <Box component={Form} bgcolor='grey.100'>
        <Box
          component={Grid}
          item container
          xs={12}
          direction='column'
          alignItems='center'
          justify='center'
          pb={{md: 6}} pt={6}
        >
          <Hidden mdUp>
            <Grid item container xs={12} justify='center'>
              <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                Espacio
              </Box>
              <Box component={Typography} variant='body1' color='text.secondary' mx={1} mb={3}>
                Mudanza
              </Box>
              <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                Pago
              </Box>
              <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                Resumen
              </Box>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>Coordinación de mudanza</Typography>
          </Hidden>
          <Hidden mdUp>
            <Box
              component={Typography}
              fontWeight='bold'
              variant='h2'
              color='text.secondary'
              align='center'
              gutterBottom
            >
                Coordinación de mudanza
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box color='text.secondary' fontSize={20} fontWeight='bold'>¿Cuándo?</Box>
          </Hidden>
        </Box>
        <Grid container justify='center'>
          <Box component={Grid}
            container
            bgcolor={{ md: theme.palette.bg }}
            px={{xs:2, sm:0}}
            style={{
              maxWidth: 1000,
              borderRadius: 15,
              paddingBottom: 60,
            }}
            justify='center'
          >
            <Box component={Grid} container justify='center' color='text.secondary' pt={5}>
              <Hidden smDown>
                <Grid item container xs={12} justify='center'>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Espacio
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='text.secondary' mx={3} mb={5}>
                    Mudanza
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Pago
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Resumen
                  </Box>
                </Grid>
              </Hidden>
              <Box
                component={Grid}
                container item
                xs={12} sm={7}
                borderRight={1}
                borderColor='black'
                pr={{md:4}}
              >
                <Hidden xsDown>
                  <Box component={Grid} container fontWeight='bold' fontSize={20} justifyContent={{xs:'center', md:'left'}}>
                    ¿Cuándo?
                  </Box>
                </Hidden>
                <Box component={Grid} item xs={12} container pb={1}>
                  <Box component={Grid} item xs={12} md={8} textAlign={{xs:'center', md:'left'}} fontSize={{xs: 16, sm: 14}} pb={{xs: 2, md: 0}}>
                    Elegí la fecha de la mudanza
                  </Box>
                  <Hidden smDown>
                    <Box component={Grid} item xs={4}>
                      Rango de horario
                    </Box>
                  </Hidden>
                </Box>
                <Box component={Grid} item container xs={12} md={8} pr={{md: 2}} justify='center' className="calendar-custom-bg">
                  <Date name='date' variant="static" disableToolbar disablePast/>
                  <FormHelperText error>{errors.date}</FormHelperText>
                </Box>
                <Box component={Grid}
                  item container
                  xs={12} md={4}
                  px={{sm:4, md:0}}
                  direction='column'
                  alignItems='center'
                >
                  <Hidden mdUp>
                    <Box component={Grid} item pt={4} pb={2} fontSize={{xs: 16,sm:20}}>
                      Rango de horario
                    </Box>
                  </Hidden>
                  <TimeRanges options={['10:00 a 12:00', '12:00 a 16:00', '16:00 a 18:00']} name='time'/>
                  <FormHelperText error>{errors.time}</FormHelperText>
                </Box>
              </Box>
              <Hidden xsDown>
                <Box component={Grid} item xs={3} pl={4}>
                  <Box mb={2} fontWeight='bold' fontSize={20}>
                    Resumen
                  </Box>
                  <Box>
                    Costo Plan {plan.planInfo.name}
                  </Box>
                  <Box fontWeight='bold' mb={6}>
                    {formatCurrency(plan.planInfo.price)} / mes
                  </Box>
                  <Box>
                    Costo estimado de mudanza
                  </Box>
                  <Box fontWeight='bold'>
                    a confirmar / única vez
                  </Box>
                </Box>
              </Hidden>
            </Box>
            <Box
              component={Grid}
              item container
              xs={12} sm={10}
              justify='space-between'
              alignItems='center'
              pt={2}
            >
              <Box
                display='flex'
                alignItems='center'
              >
                <Link to='/confirm-address'>
                  <Button
                    variant="contained"
                    color="primary"
                    size={xs?'large':'medium'}
                  >
                    Atrás
                  </Button>
                </Link>
              </Box>
              <Box item container xs={5} justify='center' alignItems='center'>
                <Link to={`${sm?'/':''}#atencion`}>
                  <Box
                    component={Typography}
                    variant={'body2'}
                    color="text.secondary"
                    fontSize='min( 3.4vw, 16px )'
                  >
                    ¿Necesitás ayuda?
                  </Box>
                </Link>
              </Box>
              <Box
                display='flex'
                alignItems='center'
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size={xs?'large':'medium'}
                  disabled={isSubmitting}
                >
                  Seguir
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
      )}
    </Formik>
  )
}

export default Appointment
