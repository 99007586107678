import React, { useState, useRef, useEffect } from 'react'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useSelector } from 'react-redux';
import { selectUser } from "features/mainSlice";

import logo from 'img/logo.svg';

const useStyles = makeStyles(theme => ({
  main:{
    display: 'flex',
    alignItems: 'center',
    padding: '20px 26px 20px 5px',
    [theme.breakpoints.up('sm')]:{
      padding: '10px 80px 10px 80px'
    },
    [theme.breakpoints.up('lg')]:{
      padding: '10px 150px 10px 150px'
    },
    [theme.breakpoints.up('xl')]:{
      padding: '10px 150px 10px 150px'
    },
  },
  logoTransparent: {
    display: 'flex',
    alignItems: 'center',
    height: 100,
    transition: 'height 0.5s ease',
    [theme.breakpoints.up('sm')]:{
      height: 100,
    },
    [theme.breakpoints.down('sm')]: {
      height: 80,
      textAlign: 'center',
      justifyContent: 'center',
    },
    '& a, & img': {
      height: '100%',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
    transition: 'height 0.5s ease',
    [theme.breakpoints.up('sm')]:{
      height: 200,
    },
    [theme.breakpoints.down('sm')]: {
      height: 100,
      alignItems: 'right',
      justifyContent: 'center',
    },
    '& a, & img': {
      height: '100%',
    },
  },
  menuIcon:{
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]:{
      fontSize: theme.typography.pxToRem(40),
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    "&>*+*": {
      marginLeft: 80,
    },
  },
  menuMobile:{
    backgroundColor: theme.palette.primary.main,
  },
}));

const  Header = ({redirect, transparent}) => {
  const {name} = useSelector(selectUser);
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const menuMobile = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if (!small) setMenuOpen(false);
  }, [small])

  return (
    <Container
      component={Box}
      style={{
        transition: '.5s background-color',
      }}
      bgcolor={transparent ? 'transparent' : 'primary.main'}
      classes={{root: classes.main}}
      maxWidth={false}
      disableGutters
    >
      <Container classes={{root: transparent ? classes.logo : classes.logoTransparent}} maxWidth='xl' disableGutters>
        {small&&
        <>
        <IconButton color='primary' onClick={setMenuOpen} ref={menuMobile}>
          <MenuIcon classes={{root: classes.menuIcon}}/>
        </IconButton>
        <Menu
          anchorEl={menuMobile.current}
          classes={{paper: classes.menuMobile}}
          open={Boolean(menuOpen)}
          onClose={()=>setMenuOpen(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Link to='/nosotros'>
            <MenuItem>
              <Box color='text.primary'>
                Nosotros
              </Box>
            </MenuItem>
          </Link>
          <Divider />
          <Link to='/#planes'>
            <MenuItem>
              <Box color='text.primary'>
                Planes
              </Box>
            </MenuItem>
          </Link>
        </Menu>
        </>
        }
        <Link to="/">
          <img src={logo} alt="logo"/>
        </Link>

        <Container  classes={{root: classes.menu}} maxWidth={false} disableGutters>
        {!small&&
          <>
            <Link to='/nosotros'>
              <Button size='medium'>
                Nosotros
              </Button>
            </Link>
            <Link to='/#planes'>
              <Button size='medium'>
                Planes
              </Button>
            </Link>
          </>
        }
        <Box
          position='relative'
          {...(redirect?
            {
              component: 'a',
              href: '/user/catalogue'
            }:
            {
              component: Link,
              to: !name ? '/login' : '/#planes'
            }
          )}>
          <Button
            style={{
              // position: 'relative',
              // transition: '.5s opacity',
              // opacity: transparent ? 1 : 0,
            }}
            variant="contained"
            color="primary"
            size='medium'
          >
            <Box minWidth={{sm: 100}}>
              {name || 'Mi cuenta'}
            </Box>
          </Button>
          <Box
            component={Button}
            style={{
              position: 'absolute',
              left: 0,
              transition: '.5s opacity',
              opacity: transparent ? 0 : 1,
            }}
            variant="contained"
            bgcolor="text.primary"
            color="primary.main"
            size='medium'
          >
            <Box minWidth={{sm: 100}}>
              {name || 'Mi cuenta'}
            </Box>
          </Box>
        </Box>
      </Container>

      </Container>

    </Container>
  )
}

const Component = ({redirect}) => {
  const [transparent, setTransparent] = useState(true)
  useEffect(() => {
    const checkScroll = () => {
      setTransparent(window.scrollY < 100)
    };

    window.addEventListener('scroll', checkScroll);
    checkScroll();
    return () => {
      window.removeEventListener('scroll', checkScroll);
    }
  }, [])
  return (
    <>
      <Box position='fixed' width='100%' zIndex={100}>
        <Header redirect={redirect} transparent={transparent}/>
      </Box>
      <Box style={{opacity: 0}}>
        <Header redirect={redirect} />
      </Box>
    </>
  )
}

export default Component;