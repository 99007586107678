import React, { useRef } from 'react'

import { Link } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useTheme } from '@material-ui/core/styles';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)
const scrollToTop = () => window.scrollTo(0, 0)

const Faq = () => {
  const theme = useTheme();
  const refs = [];

  refs['Preguntas Generales'] = useRef();
  refs['Planes y Contratación'] = useRef();
  refs['Cajas y Embalaje'] = useRef();
  refs['Mudanza'] = useRef();
  refs['Guardado'] = useRef();


  return (
    <Container component={Box} maxWidth={false} color='primary.main' bgcolor='grey.100' top={0} p={3} disableGutters>
      <Box display={{md:'none'}} position='fixed' bottom={20} right={20}>
        <Fab
          onClick={scrollToTop}
          color="secondary"
        >
          <ExpandLessIcon />
        </Fab>
      </Box>
      <Container component={Grid} container maxWidth='lg' >
        <Grid component={MenuList} item xs={12} md={3}>
          <Box position={{xs:'relative', md:'fixed'}} pb={1}>
            <MenuItem style={{cursor:'pointer'}} onClick={()=>scrollToRef(refs['Preguntas Generales'])}>Preguntas Generales</MenuItem>
            <MenuItem style={{cursor:'pointer'}} onClick={()=>scrollToRef(refs['Planes y Contratación'])}>Planes y Contratación</MenuItem>
            <MenuItem style={{cursor:'pointer'}} onClick={()=>scrollToRef(refs['Cajas y Embalaje'])}>Cajas y Embalaje</MenuItem>
            <MenuItem style={{cursor:'pointer'}} onClick={()=>scrollToRef(refs['Mudanza'])}>Mudanza</MenuItem>
            <MenuItem style={{cursor:'pointer'}} onClick={()=>scrollToRef(refs['Guardado'])}>Guardado</MenuItem>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
            <Typography ref={refs['Preguntas Generales']} variant='h2' color='secondary'>Preguntas Generales</Typography>
            <br />
            <h3>¿Qué es Todo En Orden?</h3>
            <p>Es un servicio para guardar tus cosas, ordenarlas y para ayudarte a liberar espacio en tu casa o empresa. Nuestra misión es reinventar la experiencia completa de mudanzas, guardado, depósitos y bauleras en la ciudad.</p>
            <br />
            <h3>¿Cómo funciona?</h3>
            <p>Es muy simple: calculás el espacio exacto que necesitás para guardar tus cosas, las pasamos a buscar, te ayudamos a embalarlas si es que lo deseas, las guardamos en un lugar seguro y subimos fotos de tus cosas a un catálogo virtual al que solo vos accedas y en donde puedas ver y pedir lo que quieras cuándo quieras. Todo esto sin moverte, sin cargar nada y pagando igual o menos que en las bauleras tradicionales.</p>
            <br />
            <h3>¿Por qué elegir Todo En Orden?</h3>
            <p>Básicamente, porque hacemos más fácil y eficiente el proceso entero. No solo guardamos tus cosas sino que también las ordenamos y te las mostramos para que puedas tomar mejores decisiones. Además, te ayudamos calcular el espacio, coordinamos toda la mudanza, te ofrecemos los materiales de embalaje, te evitamos cargar peso y lo más importante es que nunca más vas a pisar un depósito. Solo vas a pagar por el espacio que utilizás. Nada de jaulas con espacios vacíos. Todo esto es posible gracias a la tecnología y a nuestra logística de guardado y delivery.</p>
            <br />
            <h3>¿Quién usa Todo En Orden?</h3>
            <p>Sirve para cualquiera que necesite optimizar su espacio. Por ejemplo, gente que se muda, alguien que necesita guardar su ropa de invierno, empresas que necesitan un depósito o que prefieren operar en espacios más chicos, personas que se van a vivir a otro país o alguien que todavía no se anima a desprenderse de algunas pertenencias pero las quiere tener a mano.</p>
            <br />
            <h3>¿Cuánto Cuesta?</h3>
            <p>Todo En Orden cobra sólo por el espacio que ocupan tus cosas. Te asignamos un plan a medida que se paga con una suscripción mensual según la cantidad de metros cúbicos utilizados.<br />Los materiales de embalaje y el costo de traslado se cobran por separado cada vez que se solicite y ocurra una mudanza.</p>
            <br />
            <h3>¿En qué zonas funciona?</h3>
            <p>Por ahora, sólo operamos en Capital Federal y en algunas zonas del Gran Buenos Aires.</p>
            <h3>¿Dónde guardan las cosas?</h3>
            <p>Todo se guarda en nuestros depósitos con espacios asegurados, que cuentan con vigilancia durante las 24 horas del día y sólo el personal autorizado de Todo En Orden puede acceder.</p>
            <br />
            <h3>¿Cómo veo mis cosas?</h3>
            <p>Creamos un “catálogo virtual”en donde cada cliente puede visualizar sus pertenencias de manera privada y online durante las 24 horas del día.<br />Si necesitan algo en particular, simplemente lo piden y se lo llevamos en el día y horario que acordemos.<br />Generalmente, en las bauleras tradicionales no existen espacios preparados para ordenar las cosas. Queda todo amontonado en una jaula y esto hace que olvidemos lo que tenemos guardado. En Todo En Orden creemos que una buena visualización es clave para tomar mejores decisiones. Por ejemplo, regalar lo que no nos sirve, venderlo, donarlo o seguir guardándolo.</p>
            <br />
            <h3>¿Cómo empiezo a usar Todo En Orden?</h3>
            <p>Ingresá tu código postal en la página principal, elegí un plan acorde a tus necesidades y desde allí te ayudaremos a coordinar la mudanza. Podés empezar desde <Link to="/">acá</Link>. Si todavía tenes dudas sobre qué plan elegir, podés usar nuestro calculador personalizado o directamente escribirnos en el chat.</p>
          </Box>
          <br />
          <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
            <Typography ref={refs['Planes y Contratación']} variant='h2' color='secondary'>Planes y Contratación</Typography>
            <br />
            <h3>¿Qué tipo de planes ofrecen y qué contienen?</h3>
            <p>Nuestros planes incluyen diferentes espacios hasta un máximo de 90 metros cúbicos, incluyen materiales de embalaje según el plan elegido y un seguro general contra siniestros (más información <Link to="/faq">acá</Link>).<br />Si estás buscando un espacio más grande, lo podrás calcular manualmente o con la ayuda de uno de nuestros especialistas.<br />Además, ofrecemos servicios profesionales de mudanza, embalaje y un catálogo online para que puedas visualizar, agregar o pedir tus cosas cuando quieras.<br />Para aprender más sobre nuestros planes y precios, por favor ingresá <span>acá</span> o comunicate con nosotros <span>aquí</span></p>
            <br />
            <h3>¿Cuál es el mejor plan para mi?</h3>
            <p>Los planes “S” (hasta 12 m3) y “M” (hasta 18 m3) son ideales para gente que necesita un poco más de espacio en su casa. Funcionan como si fuesen un placard extra o un cuarto más afuera de casa. Son convenientes para guardar cosas sueltas por ejemplo ropa, libros, papeles o utencillos y muebles. Son ideales para estudiantes, familias que se va a vivir afuera o se mudan de ciudad, personas que quieren guardar ropa de temporada o alguien que alquila su departamento temporalmente y necesita guardar las cosas pequeñas. También son útiles para empresas que quieran guardar papeles, archivos o documentos importantes. Generalmente en estos planes se guardan cajas y algunas cosas un poco más grandes.<br />El plan “L” (hasta 30 m3) es ideal para gente que está remodelando su casa y necesita vaciar habitaciones o ambientes enteros por un tiempo. En este plan entra todo lo que se puede guardar en un departamento chico. También sirve para empresas que necesitan más espacio de trabajo y deciden almacenar muebles, documentos o stock en general.<br />Los planes “XL” (hasta 48 m3) y “XXL” (hasta 90 m3) están pensados para familias enteras que se mudan, vacían una casa o departamento grande o que directamente se va a vivir a otro país y necesitan dejar todo ordenado por un tiempo. También lo pueden utilizar empresas que necesitan almacenar stock.<br />Si todavía no sabes qué plan es el más conveniente para vos, podés {null||`usar nuestro <Link to="/calculator">calculador manual</Link> o`} consultar con uno de nuestros especialistas desde <Link to="/">aquí</Link></p>
            <br />
            <h3>¿Qué pasa si necesito usar un espacio menor al del Plan “Chico”?</h3>
            <p>No hay problema. Existe la posibilidad de ocupar menos de 12 metros cúbicos. Pero es importante aclarar que el precio mínimo para guardar objetos corresponde al precio del plan “Chico”.</p>
            <br />
            <h3>¿Qué precios tienen los planes?</h3>
            <p>El precio de los planes se calcula multiplicando la cantidad de metros cúbicos por el valor del metro cúbico de Todo En Orde (puede ajustarse según la inflación y se notificará a los clientes con anticipación vía mail). Los planes más grandes tienen cierto descuento por volumen.<br />El precio de los planes a medida se calcula según el espacio que ocupen los objetos seleccionados. Por ejemplo, entre 0,1 y 12 m3 se te asignará un plan “S”, entre 12,1 y 18 se te asignará un plan “M”, entre 18,1 y 30 te corresponderá el plan “L”, entre 30,1 y 48 será el plan “XL” y entre 48,1 y 90 metros cúbicos te asignaremos el plan “XXL”. A partir de ahí, iremos sumando fracciones de metros cúbicos según la necesidad.<br />Podés consultar los precios de los planes desde <Link to='/'>acá</Link> {null||`o calcular tu plan a medida clickeando <Link to="/calculator">aquí</Link>`}<br />Vale aclarar que el costo del traslado de tus cosas se paga aparte, cada vez que se realice una mudanza (buscando o devolviendo tus cosas) y el pago se hace en el momento.</p>
            <br />
            <h3>¿Cómo se contratan los planes?</h3>
            <p>Todos los planes se contratan desde la página web de Todo En Orden.<br />Durante el proceso de contratación elegirás tu plan, te ayudaremos a coordinar la mudanza y a suscribirte al servicio de pago mensual.<br />El pago se hace a través de tarjeta de crédito, usando “Mercado Pago” como intermediario de la operación. En ese momento se generará una suscripción que se renovará automáticamente todos los meses. El pago se debitará a tu tarjeta elegida al principio de cada ciclo mensual.<br />Es importante remarcar que durante el proceso de pago te pediremos que aceptes los términos y condiciones de Todo En Orden. Esto funciona como un contrato en el que ambas partes nos comprometemos a cumplir con lo acordado. Nosotros a guardar, ordenar y cuidar tus cosas y vos a cumplir con el pago del servicio. Este contrato se enviará por mail posteriormente de pagar y aceptar los términos y condiciones. Por tal motivo, te recomendamos que leas las reglas de Todo En Orden antes de contratar nuestro servicio, accediendo <Link to="/faq">aquí</Link><br />Si en el futuro surgen modificaciones en tu plan (ya sea sacando o agregando objetos), la suscripción se actualizará según el espacio que ocupen tus cosas y el plan que te corresponda. Al mes siguiente el precio de tu suscripción se ajustará para que pagues lo que te corresponde por los días que ocupes dicho espacio.</p>
            <br />
            <h3>¿Cuándo empiezo a pagar?</h3>
            <p>El ciclo de pago comienza en el momento en que se contrata el servicio. Desde ese instante empezaremos a realizar algunas tareas internas como gestionar el espacio en nuestro depósito, asignarte un asistente especial para tu pedido, planear la logística, enviarte los materiales de embalaje y coordinar tu mudanza.</p>
            <br />
            <h3>¿Cómo funciona el ciclo de pagos?</h3>
            <p>El servicio de Todo En Orden funciona con una modalidad de suscripción mensual. Los ciclos de las suscripciones tienen una fecha de inicio y una fecha de cierre.<br />La fecha de inicio del ciclo mensual corresponder al primer día de cada mes y la fecha de cierre es siempre el día anterior a que empiece el siguiente ciclo mensual de pago.<br />Es importante aclarar que el servicio de Todo En Orden se paga a principios de mes. O sea, a mes adelantado.<br />Para el primer ciclo tomaremos el momento de contratación como la fecha y horario de inicio del servicio. En ese momento tendrás que pagar por única vez un proporcional del precio del plan elegido por los días restantes del mes que ocupes el espacio. A partir del primer día del mes siguiente, se iniciarán los ciclos que correrán con normalidad. Los ciclos se podrán alterar cuando decidas modificar tu plan ya sea sumando o pidiendo que te devolvamos algunas cosas.<br />Los ciclos de pago se renovarán automáticamente cada mes a través del servicio de Mercado Pago. Los traslados de tu locación al depósito y viceversa se pagarán aparte y en el mismo momento de la mudanza.</p>
            <br />
            <h3>¿Qué formas de pago existen?</h3>
            <p>El servicio de Todo En Orden se paga con tarjeta de crédito a través del servicio de Mercado Pago que está integrado a nuestra plataforma.<br />Las mudanzas se pagan por única vez, en efectivo o a través de Mercado Pago. La diferencia entre ambas formas de pago es que el servicio de guardado se contrata a través de una suscripción que se renueva automáticamente y la mudanza se paga por única vez.</p>
            <br />
            <h3>¿Cómo hago para calcular el espacio exacto que necesito?</h3>
            <p>Te recomendamos que primero hagas un listado general de las cosas que necesitás guardar (aunque sea en un papel y estimando la cantidad de cosas). Esto te va a ayudar a tener mayor claridad para calcular y elegir tu plan.<br />A la hora de decidir por un plan, te recomendamos que primero veas la descripción de cada uno desde <Link to='/'>acá</Link>. Allí te explicaremos a grandes rasgos qué tipo de espacios ofrecemos.{null||`<br />Si todavía hay dudas, te ofrecemos la calculadora manual. Podés probarla <Link to="/calculator">acá</Link>. Allí podrás sumar uno por uno los objetos de tu lista. Como última opción, te ofrecemos la posibilidad de hablar con uno de nuestros especialistas quien te guiará en el proceso de selección.<br />Y si elegís un plan y durante la mudanza nos damos cuenta que te corresponde menos o más espacio, lo podremos modificar sin problemas.`}</p>
            <br />
            <h3>¿Qué pasa si contrato un plan y luego me doy cuenta que necesito menos o más espacio?</h3>
            <p>No hay problema. Podés modificar tu plan en cualquier momento (luego de contratarlo, en el momento de la mudanza o mientras tus cosas estén guardadas en nuestros espacios)<br />Tu plan se puede cambiar en varias instancias. Una de ellas es hacerlo luego de contratar el plan y previo a la mudanza. En ese caso, nos podrás contactar desde el chat, por teléfono o vía mail. Si todavía tenés dudas, podés conservar tu plan y esperar al día de la mudanza para que uno de nuestros operadores te guíen según los objetos que necesites guardar. En el caso en que el volumen que ocupen tus objetos supere o esté por debajo del plan elegido previamente, te actualizaremos el plan en ese momento.<br />Si decidís realizar un cambio mientras tus cosas estén en el depósito, el plan también se actualizará cuando concretemos la mudanza.<br />Es importante aclarar que haremos la modificación en el momento pero la actualización de plan se efectivizará al mes siguiente (ya sea bajando a un plan más chico o subiendo a un plan más grande). O sea, no habrá reembolsos ni pagos extras en el momento.</p>
            <br />
            <h3>Ya tengo un plan de Todo En Orden ¿Qué pasa si agrego o saco cosas?</h3>
            <p>No hay problema. Podrás sacar y agregar todas las cosas que quieras, las veces que quieras. Lo importante es que siempre vas a pagar por el espacio que estén ocupando en nuestros espacios. Te actualizaremos el plan según los metros que pases a ocupar. En nuestro proceso interno, esto significa dar de baja tu suscripción vieja y darle de alta a la nueva.<br />Si bien el cambio se realiza en el momento, el precio de la suscripción nueva se actualizará en el próximo ciclo de pago que comenzará en los primeros días del mes siguiente. Es decir, no habrá reembolsos ni pagos extras en el momento del cambio.<br />Por cualquier consulta sobre este tema, por favor contactanos desde <span>aquí</span>.</p>
            <br />
            <h3>Quiero cancelar mi plan ¿Cómo y cuándo puedo hacerlo?</h3>
            <p>Podés cancelar el plan en el momento que quieras.<br />Si decidís hacerlo antes de que te mandemos las cajas vacías y los materiales de embalaje, te cobraremos una tarifa atada a los días del mes en los que hayamos realizado tareas de gestión de espacio, asignación de un asistente, plan de logística, coordinación de mudanza y otros costos operativos. Si existe alguna diferencia con el proporcional pagado al comienzo del mes, te haremos un reembolso via transferencia electrónica.<br />Si decidís cancelar el plan una vez que los materiales y cajas vacías se hayan entregado en la dirección solicitada, eliminaremos la suscripción y también te cobraremos una tarifa según días de trabajo de gestión y por los materiales de embalaje. Lo mismo sucede con los materiales de embalaje, tendrás que pagar por los mismos y por su transporte.<br />Si decidís cancelar tu plan a partir del momento de la mudanza o mientras tus cosas están guardadas en nuestro depósito, tendremos que coordinar una mudanza para devolverte todo. Recordá que el plan se paga a mes adelantado, por lo que si cancelás tu plan a mediados de mes, igualmente ya habrás pagado por el mes completo. En este caso no habrá reembolsos. En todo caso, tendrás que avisarnos con anticipación para dar de baja tu plan antes de que comience un nuevo ciclo y se te cobre por adelantado. Además, tendrás que pagar por el costo de la mudanza de devolución de tus cosas.</p>
            <br />
            <h3>¿Los planes vienen con cajas y materiales de embalaje?</h3>
            <p>Cada plan incluye un mínimo de cajas según el tamaño del mismo, aunque siempre existe la posibilidad de pedir más cajas durante el proceso de contratación (esas cajas extra se cobrarán por separado).<br />El plan “S” incluye 2 cajas. El plan “M” incluye 3 cajas. El plan “L” incluye 3 cajas. El plan “XL” incluye 4 cajas. El plan “XXL” incluye 4 cajas.<br />Con respecto a los materiales de embalaje (cintas adhesivas, pluriball), no están incluídos en los planes y se tendrán que pedir por separado.</p>
            <br />
            <h3>¿Qué pasa si no quiero que me envíen cajas?</h3>
            <p>No hay problema. Si sólo necesitás embalar objetos grandes que no entren en nuestras cajas, podrás notificarlo durante el proceso de contratación eligiendo la opción “No quiero recibir cajas”. El precio del plan será el mismo ya que las cajas están incluídas.<br />Podrás optar por materiales específicos como pluriball, cinta adhesiva o cajas para archivos. Previo a la mudanza te enviaremos todos los materiales que necesites.<br />Si decidís no recibir ninguno de nuestros elementos de embalaje ni cajas, entonces saltaremos el envío y nos encontraremos directamente en la mudanza.</p>
            <p>En ese caso, es importante recordar que:</p>
            <ul>
              <ul>
                <li>Si a último momento decidís guardar objetos pequeños y sueltos, sí o sí tendrás que usar nuestras cajas ya que son especiales para optimizar nuestro espacio y están diseñadas para conservar mejor tus pertenencias.</li>
              </ul>
            </ul>
            <br />
            <ul>
              <li>Todos los objetos que traslademos tienen que estar debidamente embalados. Podrás hacerlo por tus propios medios (con o sin nuestros materiales de embalaje) o con nuestra asistencia profesional.</li>
            </ul>
            <br />
            <h3>¿Cómo son las cajas?</h3>
            <p>Nuestras cajas ocupan 0,8 metros cúbicos. Cada una mide 60 cm x 40 cm x 34 cm soportando un peso de hasta 20 kilos.<br />Son ideales para objetos sueltos como ropa, libros, revistas, platos, cubiertos, utencillos, papeles, álbumes de fotos o adornos chicos.<br />Para tener una referencia, nuestras cajas pueden contener hasta 12 pares de zapatos ó 30 camisas más 10 sweaters. Si necesitás guardar libros, te recomendamos que los dividas entre varias cajas para evitar exceder los 20 kilos.<br />Si todavía tenés dudas de lo que entra en una de nuestras cajas, escribinos <span>aquí</span> y alguno de nuestros expertos podrá ayudarte.</p>
            <br />
            <h3>¿Puedo guardar solamente cajas?</h3>
            <p>Sí. Existe la posibilidad de guardar sólo cajas.<br />Nuestro plan más pequeño es el “S” y ocupa desde 0,1 a 12 metros cúbicos. Si necesitás guardar pocas cajas o menos de 12 metros cúbicos, no hay inconveniente. Las guardaremos pero deberás abonar el valor mínimo de contratación que es equivalente al del plan “S”.</p>
          </Box>
          <br />
          <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
            <Typography ref={refs['Cajas y Embalaje']} variant='h2' color='secondary'>Cajas y Embalaje</Typography>
            <br />
            <h3>¿Qué tipos de cajas ofrece Todo En Orden?</h3>
            <p>Ofrecemos 3 tipos de cajas:<br />La caja común de mudanza que mide 60 cm x 40 cm x 34 cm soportando un peso de hasta 20 kilos y también cajas más pequeñas para almacenar archivos y documentos importantes.</p>
            <br />
            <h3>¿Las cajas son gratuitas?</h3>
            <p>Cada plan incluye cierta cantidad de cajas según el cubicaje del mismo (ver referencias <span>aquí</span> ). Si necesitás cajas extra para guardar más cosas, tendrás que pedirlas durante el proceso de contratación en el paso “Servicios”. Se te cobrarán por unidad y lo incluiremos en el costo de servicio de mudanza.<br />Durante la mudanza también podrás solicitar cajas a nuestros mudadores. Se te cobrarán al final del servicio.</p>
            <br />
            <h3>¿Puedo usar mis propias cajas?</h3>
            <p>Desafortunadamente, para todos los planes tendrás que usar las cajas de Todo En Orden. Nuestras cajas tienen un diseño estandarizado para optimizar la utilización del espacio en nuestros depósitos. Este detalle hace que seamos más eficientes en el guardado y así lograr que pagues sólo por el espacio que usas y no por jaulas que no tienen lógica de orden.</p>
            <br />
            <h3>¿Hay un máximo de peso para llenar una caja?</h3>
            <p>Sí. El máximo de peso es de 20 kg por caja. Esto sirve para asegurarnos de que tus pertenencias estén más seguras mientras están guardadas en nuestros espacios.<br />Te recomendamos que dividas los objetos más pesados entre varias cajas, mezclándolos con cosas más livianas como por ejemplo ropa. El exceso de peso podría dañar las cajas.</p>
            <br />
            <h3>¿Cómo me llegan las cajas/materiales para embalar mis cosas?</h3>
            <p>La mudanza puede tener dos etapas. La primera es opcional y es cuando te mandamos los materiales de embalaje para que vayas preparando tus cosas y así ganar tiempo en el día de la mudanza. La segunda es cuando vamos a buscar todo para llevarlo a nuestro espacio.<br />Todo se coordina durante el proceso de contratación acá en nuestro website. Vas a poder elegir día y horario para la entrega de materiales de embalaje. Una vez seleccionada la fecha y horario, podrás elegir un día y horario para la mudanza. Intentá que existan algunos días en el medio para que tengas tiempo de embalar tus cosas.<br />Es importante que cuando vayamos a retirar todo, tus cosas ya estén lo más preparadas posible. De esta manera optimizaremos los tiempos y te evitaremos pagar horas extra de nuestros mudadores. Si necesitás asistencia profesional para embalar todo, no hay problema. Podemos hacerlo pero impactará en el costo del servicio de mudanza.</p>
            <br />
            <h3>¿Puedo pedir más cajas y más materiales de embalaje?</h3>
            <p>Claro que sí. En los planes incluímos un servicio básico con cajas según el espacio que contrates.<br />Pero además existe la oportunidad de pedir materiales como pluriball, cinta adhesive y más cajas. Todos estos materiales extra serán cobrados aparte.<br />Nuestros mudadores siempre llevan materiales y cajas en los vehículos. Por lo tanto, podrás pedir materiales extra en el momento de la mudanza.</p>
            <br />
            <h3>¿Cuál es la mejor forma de embalar?</h3>
            <p>Lo más importante es la planificación. Por eso, te recomendamos que trates de visualizar todo lo que necesitás mudar. Podés hacer una lista de objetos aunque sea en un papel. De esta manera vas a poder cuantificar todo de una manera visual.<br />Para las cosas más pequeñas te sugerimos agruparlas de tal manera que cuando decidas pedir una caja de regreso, podamos llevarte todo lo que necesitás de una sola vez. Por ejemplo, los vestidos de verano tienen que ir todos juntos para cuando cambie la temporada. Si vas a guardar botas de lluvia, deberían estar con un paraguas. Los adornos de navidad también deberían estar todos juntos.<br />Intentá distribuir las cosas pesadas en diferentes cajas. Tratá de dividir los libros y mezclarlos con ropa o utencillos más pequeños. Recordá que el máximo de peso de las cajas tiene que ser 20kg.<br />Te recomendamos que enrolles la ropa y las toallas para maximizar el espacio en las cajas. Tratá de guardar medias y cosas chiquitas dentro de los zapatos, zapatillas y botas. De esta manera vas a optimizar el espacio de las cajas y vas a ayudar a los zapatos a conservar su forma y que no queden aplastados.<br />Para cosas más grandes como sillas, mesas, camas u objetos frágiles, te recomendamos que uses materiales especiales de embalaje y que te tomes un tiempo empacarlo. Si necesitás ayuda profesional, con gusto te ayudaremos.<br />De más está decir que en las cajas no se pueden guardar drogas, comida, explosivos, armas, inflamables, animales, insectos, hongos y bacterias o items tóxicos. En las reglas de Todo En Orden queda detallado este punto.</p>
            <br />
            <h3>¿Cómo meto mis cosas en las cajas?</h3>
            <p>Previo a la mudanza te enviaremos los materiales de embalaje. Recordá que cada plan incluye una serie de cajas según el tamaño. De todas maneras, podrás solicitar cinta adhesiva y pluriball que se pagarán aparte.<br />Es importante que no cierres las cajas hasta el día de la mudanza ya que uno de nuestros especialistas sacará fotos de su contenido. Esto sirve para que sepas lo que hay en el interior de las cajas a la hora de pedirlas de vuelta.<br />Luego de sacar las fotos, podremos cerrar las bolsas y las cajas con un precinto/cinta inviolable por razones de seguridad y privacidad.</p>
            <br />
            <h3>¿Puedo quedarme con las cajas cuando me devuelvan mis cosas?</h3>
            <p>Sí. Podés quedarte con las cajas y las bolsas y usarlas para lo que quieras.</p>
            <br />
            <h3>¿Qué pasa si pedí menos o más cajas de las que necesito?</h3>
            <p>No hay problema.<br />Después de contratar el servicio en nuestro website, podrás solicitar más cajas contactándote con uno de nuestros especialistas. Te enviaremos la cantidad de cajas que desees. Las cajas extra serán cobradas aparte durante la mudanza final.<br />Si por alguna razón necesitás más materiales y por razones de tiempo no podemos llevártelas antes de la mudanza, no hay problema. Nuestros mudadores siempre llevan cajas y bolsas de repuesto durante la mudanza.<br />De todas maneras, recomendamos que solicites más cajas de las que pensás usar (a veces pensamos que necesitamos menos cajas de lo que realmente precisamos). Te las enviaremos previo a la mudanza. Si no las utilizás nos las llevaremos de regreso. Sólo vas a pagar por las cajas llenas que entran en nuestros depósitos.<br />También existe la opción de no recibir cajas. Por favor, notificanos durante el proceso de contratación para evitar el traslado de las mismas a la locación solicitada. Recordá que no podremos guardar cajas particulares. Sólo aceptaremos las cajas especiales de Todo En Orden.</p>
            <br />
            <h3>¿Cuánto tiempo tengo para empacar mis cosas?</h3>
            <p>Una vez que recibas las cajas y los materiales de embalaje tenés hasta el día que acordemos la mudanza para empacar todo. Te recomendamos que empaques con anticipación para poder agilizar el proceso y no extendernos con las horas de mudanza.<br />Si por alguna razón las cosas no están embaladas, podrás solicitar el servicio en el momento o reagendar la mudanza para otro momento. Recordá que para ese momento, el plan de suscripción mensual ya estará efectivo. Por eso es importante optimizar el tiempo.</p>
            <br />
            <h3>¿Puedo pedir las cajas, llenarlas y mandarlas enseguida?</h3>
            <p>Sí. Sólo si son cajas.<br />Una vez coordinada la mudanza, nos podrás indicar vía mail, chat o teléfono que el mismo día que te enviemos las cajas vacías te gustaría que nos llevemos todo al depósito. Nuestros mudadores van a esperar hasta 30 minutos mientras embalás antes de volver al depósito. Es importante que nos avises sobre esta modalidad con anticipación así podemos resolver todo en el momento, evitando la mudanza posterior y costos extra.</p>
            <br />
            <h3>¿Qué pasa si tengo que guardar cosas grandes que no entran en cajas?</h3>
            <p>No hay problema. También podemos guardar cosas grandes y pesadas. Ofrecemos materiales de embalaje para poder cuidar tus cosas mientras están guardadas.<br />También ofrecemos servicios de embalaje profesional para todos lo que necesites y para objetos frágiles.<br />Por favor, durante el proceso de contratación indicanos si tendríamos que cargar objetos pesados que superan los 50kg y si tenemos que subir y bajar escaleras. Todo esto nos ayudará a planificar mejor tu mudanza.</p>
            <br />
            <h3>¿Todo En Orden ofrece servicios para empacar?</h3>
            <p>Sí. Si precisás servicios de embalaje, nuestros mudadores pueden ayudarte. Es importante que lo indiques durante el proceso de contratación así podemos planificar mejor tu mudanza y las horas dedicadas.<br />Si por alguna razón llegamos con la mudanza y nos damos cuenta que necesitás ayuda, te brindaremos todos los materiales de embalaje y calcularemos las horas extra en ese mismo momento.</p>
            <br />
            <h3>¿Todo En Orden saca fotos de lo que hay dentro de mis cajas?</h3>
            <p>Sí. Los mudadores de Todo En Orden sacarán fotos del contenido de tus cajas por dos motivos: el primero es para hacer un inventario y así poder confirmar el espacio contratado. El segundo es para poder subir las fotos al catálogo online y que puedas visualizar mejor lo que tenés guardado.<br />Por eso es importante que el día de la mudanza encontremos todo empacado pero con las cajas abiertas.</p>
            <br />
            <h3>¿Por qué es importante hacer un inventario antes y durante la mudanza?</h3>
            <p>Te recomendamos que hagas una breve descripción de todo lo que hay guardado en cada caja. Esto nos va a ayudar para que en el futuro podamos devolverte la caja específica que necesitás.<br />En tu catálogo online subiremos la foto exacta de tu caja con un código especial y una breve descripción de lo que contiene.</p>
          </Box>
          <br />
          <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
            <Typography ref={refs['Mudanza']} variant='h2' color='secondary'>Mudanza</Typography>
            <br />
            <h3>¿Cómo coordino la mudanza?</h3>
            <p>Es muy fácil. Luego de elegir tu plan, te guiaremos en un proceso de contratación y te explicaremos lo que incluye cada plan, de qué se trata el servicio básico de mudanza, su costo estimado y te ayudarmos a coordinarla. Además de fecha y horario, podrás elegir la dirección en la que deseas que te llevemos los materiales de embalaje y la posterior mudanza.<br />Para cualquier tipo de cambio de fecha, horario, dirección o plan, podrás contactarnos por teléfono o desde <span>aquí</span>.</p>
            <br />
            <h3>¿Por qué es importante hacer una lista de mis cosas antes de la mudanza?</h3>
            <p>Es importante hacer una lista previa para que podamos planificar mejor la mudanza. Durante el proceso de contratación habilitaremos un espacio para que puedas describir tus objetos lo más detallado posible.<br />Esa lista también nos va a servir para cargar las fotos en tu catálogo online. Sirve para describir el contenido de las cajas y que nosotros podamos planear mejor la mudanza.</p>
            <br />
            <h3>¿En qué consiste el servicio básico de mudanza?</h3>
            <p>Para cada plan es necesario contemplar un servicio básico de mudanza que dependerá del tamaño del plan que elijas. Estos servicios son obligatorios y contemplan lo básico e indispensable para poder realizar la mudanza con éxito. Es importante aclarar que los precios reflejados en la web de Todo En Orden son estimados ya que durante la mudanza podrán ocurrir modificaciones. El servicio de mudanza se paga por separado a la suscripción del plan.<br />El servicio básico está compuesto por un vehículo y horas de trabajo de mudadores acorde al espacio contratado y a las especificaciones de la mudanza (escaleras, objetos pesados, tiempo de embalaje) y a la distancia de tu locación y nuestro espacio. A este servicio básico se le pueden sumar materiales y pedidos especiales que podrán alterar el tiempo y el precio.<br />Si por alguna razón, durante la mudanza surgen cambios, se extiende el horario o se solicitan materiales y cajas, lo calcularemos en el momento y lo agregaremos al ticket de pago de la mudanza.</p>
            <br />
            <h3>¿El precio de la mudanza puede variar?</h3>
            <p>El precio inicial del servicio es estimativo. Se calcula en base al plan que elijas y a las especificaciones de tu mudanza. Durante la mudanza pueden surgir cambios, por ejemplo mayor cantidad de horas dedicadas, la necesidad de embalar algo frágil o la solicitud de materiales extra para embalar. El precio de la mudanza se terminará de calcular a criterio del mudador y con el acuerdo posterior del cliente.<br />El precio inicial se estima gracias a la combinación de los siguientes factores: tipo de vehículo, cantidad de mudadores, cantidad de horas dedicadas, distancia con respecto a nuestro espacio más cercano al lugar de la mudanza, materiales extra de embalaje y servicios adicionales que se soliciten (embalaje profesional, cargar cosas pesadas, escaleras, etc.).</p>
            <br />
            <h3>¿Cuándo y cómo se paga la mudanza?</h3>
            <p>Todo En Orden estima un precio de la mudanza según el plan elegido y algunas solicitudes especiales. Como dijimos en respuestas anteriores, este precio es estimativo ya que la mudanza puede sufrir cambios sobre la marcha.<br />Por eso es que el servicio se paga una vez finalizada la mudanza. A diferencia del plan de suscripción mensual, la mudanza se paga por única vez y cada vez que se realice una.<br />El método de pagoda la mudanza es en efectivo o con tarjeta de crédito/débito a través de Mercado Pago. Se debitará como pago único.</p>
            <br />
            <h3>¿Cuánto tardan en venir a buscar o devolverme mis cosas?</h3>
            <p>Recordá que previo a retirar tus cosas y llevarlas a nuestro espacio, puede existir la instancia en la que te enviemos los materiales de embalaje en el mismo lugar acordado para la mudanza (esto último es opcional). Si es que elegis recibir materiales de embalaje, desde el momento en que te entreguemos las cajas vacías tendrás un tiempo pre-acordado para empacar lo que necesites antes de hacer la mudanza total. Generalmente es un máximo de 15 días y un mínimo de 2 días.<br />Tanto el envío de materiales como el día y horario de la mudanza se coordinan durante el proceso de contratación.<br />La solicitud para devolverte tus cosas se realiza desde el catálogo online que accederás con usuario y contraseña. Allí podrás seleccionar los objetos que querés recuperar y nuevamente coordinaremos el envío. Podemos devolverte tus cosas en 48 horas luego de solicitarlas, dependiendo de la disponibilidad de agenda de nuestros mudadores.<br />Nuestros días y horarios de mudanza son de lunes a viernes de 10 am a 6 pm y los sábados de 8 am a 11 pm.</p>
            <br />
            <h3>¿Cómo hago para editar/suspender/cancelar la mudanza sobre la marcha?</h3>
            <p>¿Necesitás cambiar o cancelar una mudanza? No hay problema. Podés contactarnos vía mail, chat o teléfono.<br />Podrás cancelar/suspender/modificar tu mudanza hasta 24 horas hábiles antes de la misma. En este caso, podremos cancelarla, agregar o quitar materiales y personal profesional para ayudarte a empacar y mudar tus cosas.<br />Si decidís cancelar o suspender la mudanza dentro de las 24 horas de lo acordado o durante el momento de la misma, se te cobrarán cargos adicionales por la cancelación y tendremos que reagendarla.<br />No podremos cancelar tu pedido una vez que retiramos tus cosas y las llevamos a nuestro espacio. En ese caso, tendrás que gestionar un pedido de devolución y coordinar fechas nuevamente.</p>
            <br />
            <h3>¿Qué pasa si cancelo la orden cuando llegan las cajas vacías a mi casa?</h3>
            <p>En ese caso te cobraremos por los días en que el servicio de Todo En Orden estuvo activo y por el transporte que utilizamos para enviarte y volver a buscar los materiales de embalaje en la dirección que indiques.<br />Recordá que podrás cancelar la mudanza antes de las 24 horas antes de la misma. De lo contrario se te cobrarán cargos adicionales por cancelación.</p>
            <br />
            <h3>¿Qué pasa si la mudanza dura menos o más de lo previsto?</h3>
            <p>No hay problema. Uno de nuestros mudadores re-calculará las horas dedicadas de nuestro personal. Recordá que los precios de mudanza calculados en nuestra web son estimados. Si por alguna razón tardamos menos, te descontaremos sobre el precio final de la mudanza. Lo mismo si dedicamos más horas por algún imprevisto (como escaleras, ascensores averiados o porque en el momento nos solicitás que te ayudemos a empacar).<br />Al terminar el trabajo, uno de nuestros mudadores confirmará junto con vos el precio final de la mudanza, se realizará un breve inventario y se emitirá una orden de pago de Mercado Pago que se cobrará en el momento, en efectivo o con tarjeta de crédito y por única vez.</p>
            <br />
            <h3>¿Qué pasa si llega la mudanza y todavía no empaqué mis cosas?</h3>
            <p>En este caso, te daremos 30 minutos para que empaques tus cosas. Pasados esos 30 minutos empezaremos a cobrarte por el tiempo que dediquen nuestros mudadores. Podemos ayudarte a empacar todo pero se te cobrará por las horas extra de trabajo de cada uno de nuestros mudadores. Lo mismo si necesitás más cajas o materiales extra de embalaje. Se cotizarán por separado.</p>
            <br />
            <h3>¿Quién hace la mudanza y cómo la hace?</h3>
            <p>El servicio estará a cargo de mudadores profesionales del equipo de Todo En Orden.<br />Según los servicios solicitados durante el proceso de contratación en nuestro website, te enviaremos un vehículo con mudadores y materiales de embalaje.<br />Por favor asegurate de que todas las cajas estén llenas y listas para ser fotografiadas antes de cerrarlas. Lo mismo con tus objetos más grandes. Podrás embalarlos por tu cuenta aunque nosotros también ofrecemos ese servicio. Esto nos va ayudar a identificar tus pertenencias fácilmente cuando las pidas de vuelta.<br />Cuánto más ágil y rápido sea el proceso, mejor para todos. Nosotros podemos realizar todo el embalaje. Si es necesario, te ayudaremos a empacar tus cosas y te proveeremos de materiales extra de embalaje. Pero recordá que todo lo que sea extra al servicio básico se sumará al precio final de la mudanza.<br />Una vez fotografiadas las cajas, cerraremos todo con un precinto/cinta inviolable por razones de seguridad y privacidad.<br />Al terminar el trabajo, uno de nuestros mudadores confirmará junto con vos el precio final de la mudanza según el tiempo utilizado y los materiales solicitados, se realizará un breve inventario y se emitirá una orden de pago de Mercado Pago que se cobrará en el momento, con tarjeta de crédito/débito y por única vez.<br />Pero la parte más importante de la mudanza es el momento en el que el mudador de Todo En Orden y vos aceptan los términos y condiciones del servicio. Funciona como un contrato en el que ambas partes nos comprometemos a cumplir con las reglas generales del servicio. Es importante para ambas partes que se firme este contrato antes de llevarnos tus cosas a nuestro espacio. Podés consultar los términos y condiciones desde <Link to="/faq">aquí</Link>.</p>
            <br />
            <h3>¿Cómo sé cuánto personal necesito para la mudanza?</h3>
            <p>No tenés que preocuparte por esto. Según el tamaño del plan que elijas te asignaremos un servicio de mudanza básico que estima la cantidad de personal y horas necesarias para realizar tu mudanza con éxito. De todas maneras, nos comunicaremos con vos para terminar de ultimar detalles.</p>
            <br />
            <h3>¿Qué tipos de vehículos y materiales usan para las mudanzas?</h3>
            <p>Todo En Orden cuenta con vehículos de distintos tamaños para realizar las mudanzas, dependiendo el plan y las características de tu pedido.<br />Con respecto a los materiales, proveemos cajas especialmente diseñadas para optimizar nuestro espacio y para proteger tus cosas. También proveemos cinta adhesiva y rollos de pluriball.</p>
            <br />
            <h3>¿Puedo pedir asistencia profesional para embalar objetos frágiles?</h3>
            <p>Claro que sí. Durante el proceso de contratación podrás solicitar ayuda profesional para embalar la cantidad de objetivos frágiles que quieras. De hecho, podrás solicitar que empaquemos todas tus pertenencias que no entran en una caja.<br />Es importante que sepas que esta ayuda implica horas extra de trabajo para nuestros mudadores. Esto se reflejará en el precio final de la mudanza.</p>
            <br />
            <h3>¿Qué pasa si durante la mudanza me doy cuenta que la cantidad de<br />cosas que quiero guardar no coinciden con el espacio que contraté?</h3>
            <p>No te hagas problema. Sólo te cobraremos por el espacio que ocupen las cosas que nos llevamos.<br />Uno de nuestros mudadores te ayudará a hacer un inventario durante la mudanza. Esto nos va a servir para ser eficientes a la hora de devolverte tus cosas y para ser precisos al calcular el espacio que ocuparás. Si existe alguna incongruencia entre lo previamente solicitado y lo que nos llevamos al depósito, actualizaremos tu plan de suscripción mensual. Al mes siguiente tendrás un plan acorde al espacio que utilices. Es importante decir que no habrán reembolsos ni pagos extra en relación al primer pago proporcional a los días restantes de ese mes. Por cualquier duda, por favor escribinos a <span>soporte@novadevtools.com</span>.</p>
            <br />
            <h3>¿Existe algún tipo de inventario para constatar el estado de mis objetos?</h3>
            <p>Sí. Uno de nuestros mudadores profesionales realizará un inventario y constatará el estado de tus objetos. Es importante hacer esta parte del proceso para evitar malos entendidos en el futuro. Una vez finalizado el inventario, firmaremos una aceptación mutua de términos y condiciones.</p>
            <br />
            <h3>¿Tengo que firmar algún comprobante/contrato de entrega de mis cosas?</h3>
            <p>Sí. Es una de las partes más importantes del proceso. Al final de la mudanza se firmará un contrato de aceptación de términos y condiciones generales del servicio en donde ambas partes nos comprometemos a cumplir con nuestra responsabilidad.</p>
            <br />
            <h3>¿Quién me avisa cuándo las cosas llegan al depósito?</h3>
            <p>Cuando tus cosas lleguen a nuestro espacio de guardado, te enviaremos un mail de confirmación. Generalmente, tardamos 1 día hábil en cargar tus fotos y descripciones en el catálogo online.</p>
            <br />
            <h3>¿Cuántas veces puedo pedir una mudanza?</h3>
            <p>Todas las veces que quieras. Tanto para sumar cosas a tu plan actual como para que te devolvamos cosas que necesitás. Es importante aclarar que cada mudanza se pagará por separado y en el momento de la misma.</p>
            <br />
            <h3>¿Tengo que pagar cada vez que hago una mudanza?</h3>
            <p>Sí. Las mudanzas se pagan por separado y cada vez que se realizan. Según lo que solicites (sumar cosas a tu plan o que te devolvamos algo), se calculará un servicio básico de mudanza y te daremos la opción de agregar servicios adicionales y hasta cambiar la dirección de la mudanza.</p>
            <br />
            <h3>¿Puedo cambiar la dirección de la mudanza o de la entrega?</h3>
            <p>Sí. Podemos devolverte tus cosas en otros lugares (siempre y cuando estén dentro de nuestra zona de cobertura). También podemos ir a buscar más objetos que quieras sumar a tu plan en otros domicilios.</p>
          </Box>
          <br />
          <Box component='article' borderRadius={15} padding={3} bgcolor={theme.palette.bg} color='text.secondary'>
            <Typography ref={refs['Guardado']} variant='h2' color='secondary'>Guardado</Typography>
            <br />
            <h3>¿Dónde están guardadas mis cosas?</h3>
            <p>Contamos con una red descentralizada de espacios de guardado alrededor de la ciudad. Nos encargaremos de guardar tus cosas en el lugar más cercano a tu ubicación.</p>
            <br />
            <h3>¿Puedo entrar al depósito?</h3>
            <p>Nuestros depósitos no tienen acceso al público por razones de seguridad. Sólo el personal autorizado y los mudadores podrán acceder al depósito.</p>
            <br />
            <h3>¿Quién entra al depósito?</h3>
            <p>Sólo el personal autorizado por Todo En Orden puede acceder a los depósitos.</p>
            <br />
            <h3>¿Cómo veo mis cosas?</h3>
            <p>Podrás visualizar tus cosas a través de un catálogo online las 24 horas del día y todos los días del año. Solo vos podrás acceder a ese catálogo.<br />Accederás al mismo a través del botón de login con usuario y contraseña, en la pantalla principal de nuestro website.<br />Allí podrás ver el estado de tus cosas, identificarlas por nombre, código de referencia, pedirlas de vuelta y sumar más objetos para ampliar tu plan.</p>
            <br />
            <h3>¿Cuándo suben las fotos de mis cosas?</h3>
            <p>Generalmente subimos las fotos al catálogo un día después de la mudanza. Primero tenemos que hacer un inventario interno, cargar los datos al sistema y las imágenes.</p>
            <br />
            <h3>¿Qué pasa si olvidé mi contraseña?</h3>
            <p>No te preocupes. Podrás gestionar una nueva clave con alguno de nuestros representantes vía chat, teléfono o escribiendo a nuestros especialistas <span>aquí</span>.</p>
            <br />
            <h3>¿Cómo recupero mis cosas?</h3>
            <p>Es muy simple. Desde el catálogo online podés seleccionar los objetos/cajas que deseás que te devolvamos y luego coordinás una fecha y horario para la mudanza.<br />Si deseás que te devolvamos todo junto, coordinaremos la mudanza y se dara de baja la suscripción el día que tus cosas salgan del depósito. La mudanza se pagará por separado.</p>
            <br />
            <h3>¿Cómo pido/agrego cosas a mi plan actual?</h3>
            <p>Podrás pedir de vuelta o agregar objetos a tu plan actual desde el catálogo online. El proceso de coordinación de mudanza es el mismo de siempre.<br />Lo importante es que siempre te asignaremos un plan según el espacio que estén ocupando tus cosas en nuestros depósitos. Actualizaremos el plan según los metros que ocupes a partir del siguiente ciclo de pago. Esto significa generar una suscripción nueva.<br />Ante cualquier duda, por favor escribinos <span>aquí</span>.</p>
            <br />
            <h3>¿Cómo hago para recordar qué deje en mis cajas?</h3>
            <p>En el catálogo online podrás visualizar fotos de tus objetos antes de ser embalados y de tus cajas abiertas con la descripción de su contenido. Por eso insistimos en la importancia de hacer un inventario junto al mudador durante la mudanza.</p>
            <br />
            <h3>¿Puedo pedirles que metan cosas en mis cajas?</h3>
            <p>Desafortunadamente no podemos guardar cosas en tus cajas una vez que las retiramos por la dirección de la mudanza. La privacidad de tus cosas es muy importante para nosotros y por razones de seguridad no abrimos las cajas.<br />Sin embargo, podés pedir que te devolvamos cajas específicas. Cuando coordines la mudanza podés indicar que esperemos hasta que guardes nuevas cosas en su interior. Otra vez le sacaremos una foto y la sellaremos con un precinto/cinta inviolable. El máximo de espera para esta operación es de 30 minutos hasta que volvamos con la caja nuevamente al depósito.</p>
            <br />
            <h3>¿Pueden ayudarme a vender, donar o alquilar mis cosas?</h3>
            <p>Por supuesto. Tenemos un espíritu sustentable y creemos en la reutilización de las cosas. Quizás hoy los objetos no tengan demasiado valor para vos y sirvan para otras. En ese caso, tenemos opciones para ayudarte a vender, alquilar o donar tus cosas. Escribinos <span>aquí</span>.</p>
          </Box>
        </Grid>
      </Container>
    </Container>
  )
}

export default Faq
