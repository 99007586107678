import React from 'react'
import Questions from './questions'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import computer from 'img/computer.png';

const Promo = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid container justify='center'>
      <Grid style={{maxWidth:1200, padding:40}} container spacing={4}>
        <Grid item xs={12} sm={6}>
          <div style={{
            margin: 20,
            maxWidth: '100%',
            height: '50vw',
            maxHeight: 360,
            backgroundImage: `url('${computer}')`,
            backgroundPosition: '50% 50%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}/>
        </Grid>
        <Grid container item xs={12} sm={6} direction='column' justify='center'>
          <Typography style={{paddingBottom: 30}} variant='h6' color={sm?'secondary':'textSecondary'} align={xs?'center':'left'}>
            Tus cosas bien guardadas
          </Typography>
          <Typography style={{
            fontFamily:'ProximaNova',
            ...xs?{paddingLeft: 20, paddingRight: 20}:{}}}
            variant='body2' color='textSecondary' align={xs?'center':'left'}>
            Esta baulera hace todo por vos. Así es. Calculamos el espacio, embalamos, mudamos y guardamos.<br />
            Además, nuestro servicio de alquiler de bauleras a medida viene con un inventario online para que puedas ver tus cosas y pedirlas cuando quieras. Nosotros te lo enviamos y vos no te movés de tu casa.<br />
            Consulta el precio de tu baulera.

          </Typography>
        </Grid>
      </Grid>
      <Questions />
    </Grid>
  )
}

export default Promo
