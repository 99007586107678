import React, { useState, useEffect } from 'react'

const useMercadopago = () => {
  const [mercadopago, setMercadopago] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [error, setError] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      if(window.Mercadopago){
        clearInterval(interval);
        setMercadopago(window.Mercadopago);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if(!mercadopago) return;
    mercadopago.setPublishableKey(process.env.REACT_APP_MP_KEY);
    mercadopago.getIdentificationTypes( (status, response) => {
      if (status !== 200 && status !== 201) {
        setError('No se pudo obtener los medios de pago');
      } else {
        setPaymentMethods(response)
      }
    })
  }, [mercadopago])

  return [mercadopago, paymentMethods, error];
}

export default useMercadopago
