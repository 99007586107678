import React, { useState, useEffect } from 'react';

import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import Container from '@material-ui/core/Container';

import Hero from 'features/hero';
import HeroLanding from 'features/hero-landing';
import Hire from 'features/hire';
import HowItWorks from 'features/howitworks';
import HowItWorksLanding from 'features/howitworks-landing';
import CustomerService from 'features/customer-service';
import Promo from 'features/promo';
import PromoLanding from 'features/promo-landing';
import Footer from 'features/footer';
import FooterLanding from 'features/footer-landing';
import Register from 'features/register';
import Validate from 'features/validate';
import ConfirmAddress from 'features/confirm-address';
import Appointment from 'features/appointment';
import Payment from 'features/payment';
import Summary from 'features/summary';
import HeaderMobile from 'features/header-mobile';
import Faq from 'features/faq';
import Us from 'features/us';
import Tyc from 'features/tyc';
import Login from 'features/login';
import Marker from 'components/marker';
import ScrollToTop from 'components/scroll-to-top';

import styles from './App.module.scss';

import Hidden from '@material-ui/core/Hidden';

import { useSelector } from "react-redux";
import { selectPlan, selectLogin, selectToken } from "features/mainSlice";

import moment from 'moment';
import "moment/locale/es";

moment.locale('es');

function App() {
  const token = useSelector(selectToken);
  const plan = useSelector(selectPlan);
  const login = useSelector(selectLogin);

  const [download, setDownload] = useState(0)
  const [pending, setPending] = useState(!!login);
  const [done, setDone] = useState(!!login);


  /// SCROLL W/HASH HANDLING
  const location = useLocation();

  useEffect(()=>{
      const hash = location.hash.slice(1);
      if(!hash) return;
      setTimeout(()=>{
        const ref = document.querySelector(`#${hash}`)
        if(!ref) return;
        ref.scrollIntoView(true);
      }, 200);
    },
    [location]
  );


  useEffect(() => {
    if(!token) return;
    let cancelled = false;
    const getPending = async ()=> {
      const response = await fetch(`${process.env.REACT_APP_API}/transaction/pending`,{
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      const result = await response.json();
      if(cancelled) return;
      setPending(!!result.length);
      setDownload(d => d+1)
    }
    getPending();
    return () => { cancelled = true; }
  }, [token]);

  useEffect(() => {
    if(!token) return;
    let cancelled = false;
    const getDone = async ()=> {
      const response = await fetch(`${process.env.REACT_APP_API}/transaction/done`,{
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      const result = await response.json();
      if(cancelled) return;
      setDone(!!result.length);
      setDownload(d => d+1)
    }
    getDone();
    return () => { cancelled = true; }
  }, [token]);

  const redirectLogin = (download === 2) && (pending || done);

  return (
    <Container className={styles.main} disableGutters>
      <Switch>
        <Route path="/faq" exact>
          <ScrollToTop />
          <HeaderMobile redirect={redirectLogin} />
          <Faq />
        </Route>
        <Route path="/nosotros" exact>
          <ScrollToTop />
          <HeaderMobile redirect={redirectLogin} />
          <Us />
        </Route>
        <Route path="/tyc" exact>
          <ScrollToTop />
          <HeaderMobile redirect={redirectLogin} />
          <Tyc />
        </Route>
        <Route path="/login" exact>
          <ScrollToTop />
          <HeaderMobile redirect={redirectLogin} />
          <Login />
        </Route>
        <Route>


{/*
    Desktop
*/}
          <Hidden smDown>

            {/* Landing Header */}
            <Route path="/landing" exact>
              <HeroLanding redirect={redirectLogin} />
              <HowItWorksLanding />
            </Route>

            {/* Normal Header */}
            <Route path="/" exact>
              <Hero redirect={redirectLogin} />
              {/* Como funciona */}
              <HowItWorks />
            </Route>

            <Switch>
              <Route path="/" exact>
                <ScrollToTop />
                {!pending && !done &&
                  <>
                    <Marker id="planes" position="absolute" top={-100} />
                    <Hire/>
                  </>
                }
              </Route>

              {/* !plan && <Redirect to="/"/> */}

              <Route path="/register" exact>
                {!!login && <Redirect to='confirm-address'/>}
                <Register />
              </Route>
              <Route path="/validate" exact>
                {!!login && <Redirect to='confirm-address'/>}
                <Validate />
              </Route>
              <Route path="/confirm-address" exact>
                <ConfirmAddress />
              </Route>
              <Route path="/appointment" exact>
                <Appointment />
              </Route>
              <Route path="/payment" exact>
                <Payment />
              </Route>
              <Route path="/summary" exact>
                <Summary />
              </Route>

            </Switch>
            <Marker id="atencion" position="absolute" top={-100} />

            {/* Remove card from landing Card */}
            <Route path="/" exact>
              <CustomerService />
              <Promo />
            </Route>

            <Route path="/landing" exact>
              <PromoLanding />
            </Route>

            
          </Hidden>
{/*
    Mobile
*/}
          <Hidden mdUp>
            <ScrollToTop />
            <Switch>
              <Route path='/' exact />
              {
              /*
              <Route>
                <HeaderMobile redirect={redirectLogin} />
              </Route>
              */
              }
            </Switch>
            <Switch>

              <Route path="/" exact>
                  <Hero redirect={redirectLogin} />
                  <HowItWorks />
                  {!pending && !done &&
                    <div id="planes">
                      <Hire/>
                    </div>
                  }
                  <Marker id="atencion" position="absolute" top={-100} />

                  <CustomerService />
                  <Promo />
              </Route>

              <Route path="/landing" exact>
                <HeroLanding redirect={redirectLogin} />
                <HowItWorksLanding />
                {
                /*
                {!pending && !done &&
                  <div id="planes">
                    <Hire/>
                  </div>
                }
                */
                }
                <Marker id="atencion" position="absolute" top={-100} />
                <PromoLanding />
              </Route>

              {/* !plan && <Redirect to="/"/> */}

              <Route path="/register" exact>
                {!!login && <Redirect to='confirm-address'/>}
                <Register />
              </Route>
              <Route path="/validate" exact>
                {!!login && <Redirect to='confirm-address'/>}
                <Validate />
              </Route>
              <Route path="/confirm-address" exact>
                <ConfirmAddress />
              </Route>
              <Route path="/appointment" exact>
                <Appointment />
              </Route>
              <Route path="/payment" exact>
                <Payment />
              </Route>
              <Route path="/summary" exact>
                <Summary />
              </Route>
            </Switch>
          </Hidden>
        </Route>
      </Switch>

      <Route path="/" exact>
        <Footer />
      </Route>

      <Route path="/landing" exact>
        <FooterLanding />
      </Route>

      
    </Container>
  );
}

export default App;
