import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme';
import MomentUtils from '@date-io/moment';

import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter as Router,
} from "react-router-dom";

const render = Component => ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router>
              <CssBaseline />
              <Component />
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById('root')
);

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
