import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Carousel from 'nuka-carousel';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import question1 from 'img/question1.png';
import question2 from 'img/question2.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '40px 20px',
    [theme.breakpoints.up('sm')]:{
      padding: '70px 40px',
    },
    [theme.breakpoints.up('md')]:{
      padding: '150px 40px',
    },
  },
  title: {
    padding: '0 0 40px',
    [theme.breakpoints.up('sm')]:{
      padding: '0 0 40px',
    },
    [theme.breakpoints.up('md')]:{
      padding: '0 0 100px',
    },
  },
  caption:{
    maxWidth: 250,
  },
  subtitle:{
    marginTop: 10,
    [theme.breakpoints.up('xs')]:{
      marginTop: 30,
    },
  },
}));

const imgStyle = image => ({
  width: '100%',
  height: '15vw',
  minHeight: 100,
  maxHeight: 120,
  backgroundImage: `url('${image}')`,
  backgroundPosition: '50% 100%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
});

const Caption = ({classes={}, children, ...props}) => (
  <Typography classes={{root:classes.caption}} variant='body2' color='textSecondary' align='center' {...props}>{children}</Typography>
)
const Title = ({classes={}, children, colorChange, ...props}) => (
  <Box width={{xs: '100%', sm: '65%', md: '100%'}}>
    <Typography classes={{root:classes.subtitle}} variant='subtitle1' color={'textSecondary'} align='center' {...props}>{children}</Typography>
  </Box>
)
const Card = ({children, ...props}) => (
  <Grid item container direction='column' xs={12} md={3} alignItems='center' {...props}>{children}</Grid>
)

const Questions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const Wrapper = sm ? Carousel : ({children}) => <React.Fragment>{children}</React.Fragment>;

  return (
    <Grid classes={{root: classes.wrapper}} container justify='center'>
      <Grid container style={{maxWidth:1300}} spacing={2} justify='space-around'>
        <Wrapper
          autoplay
          wrapAround
          renderBottomCenterControls={null}
          slidesToShow={xs?1:2}
          renderCenterLeftControls={({previousSlide})=>(
            <IconButton onClick={previousSlide}>
              <Typography color='textSecondary'>
                <ArrowBackIosIcon/>
              </Typography>
            </IconButton>
          )}
          renderCenterRightControls={({nextSlide})=>(
            <IconButton onClick={nextSlide}>
              <Typography color='textSecondary'>
                <ArrowForwardIosIcon/>
              </Typography>
            </IconButton>
          )}
        >
        	<Card>
        	  <div style={imgStyle(question1)}/>
        	  <Title classes={classes}>
        	    ¿Cómo se calcula el precio?
        	  </Title>
            <br />
        	  <Caption classes={classes}>
        	    Todo en Orden cobra sólo por el espacio que ocupen las cosas. Asignamos un plan a medida dependiendo de cuánto ocupen. Los costos de traslado y materiales de embalaje se cobran por separado.
        	  </Caption>
        	</Card>
        	<Card>
        	  <div style={imgStyle(question2)}/>
        	  <Title classes={classes}>
        	    ¿Dónde están mis cosas?
        	  </Title>
            <br />
        	  <Caption classes={classes}>
        	    Tenemos depóstios ubicados estratégicamente para devolver las cosas lo más rápido posible. Todos cuentan con seguridad las 24 horas. Además existe un catálogo online para saber exactamente qué cosas están guardadas.
        	  </Caption>
        	</Card>
        	<Card>
        	  <div style={imgStyle(question1)}/>
        	  <Title classes={classes}>
        	    ¿Cómo recupero mis cosas?
        	  </Title>
            <br />
        	  <Caption classes={classes}>
        	    Se pueden coordinar entregas desde el catálogo online para usuarios registrados. Seleccioná lo que quieras y te lo devolvemos en 24 ó 48hs. Dependiendo de la distancia y tamaño del pedido.
        	  </Caption>
        	</Card>
        </Wrapper>
      </Grid>
    </Grid>
  )
}

export default Questions