import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import InfoIcon from '@material-ui/icons/Info';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector } from 'react-redux';

import { selectToken } from 'features/mainSlice';

import { useTheme } from '@material-ui/core/styles';

import { formatCurrency } from "utils";
import moment from 'moment';

const Summary = () => {
  const theme = useTheme();
  const [pending, setPending] = useState();
  const token = useSelector(selectToken);

  useEffect(() => {
    let cancelled = false;
    const getPending = async ()=> {
      const response = await fetch(`${process.env.REACT_APP_API}/transaction/pending`,{
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      const result = await response.json();
      if(cancelled) return;
      setPending(result[result.length-1]);
    }
    getPending()
    return () => {
      cancelled = true;
    }
  }, []);

  useEffect(()=>{
    const redirect = setTimeout(() => window.location.href = '/user/catalogue', 20000);
  return () => {
    clearTimeout(redirect);
  }
  }, [])

  return (
      <Box bgcolor='grey.100' py={6} style={{position: 'relative'}}>
        <Backdrop open={!pending} style={{position:'absolute', zIndex:5}}>
          <CircularProgress/>
        </Backdrop>
        {!pending?
          <Box px={{xs:2, sm:4, md:0}}
              mx={2}
              height={600}
              style={{
                maxWidth: 1000,
                backgroundColor: theme.palette.bg,
                borderRadius: 15,
                paddingBottom: 60,
              }}
        />
        : (
        <>
          <Grid container>
            <Grid item container xs={12} justify='center' style={{ paddingBottom: 50, }}>
              <Hidden smDown>
                <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>Tu pedido se realizó con éxito!</Typography>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Box
                    component={Typography}
                    fontWeight='bold'
                    variant='h2'
                    color='text.secondary'
                    align='center'
                    gutterBottom
                  >
                      Tu pedido se realizó con éxito!
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    component={Typography}
                    fontWeight='bold'
                    variant='subtitle2'
                    color='text.secondary'
                    align='center'
                    gutterBottom
                  >
                      Gracias por confiar en Todo en Orden!
                  </Box>
                </Grid>
                <Box component={Grid} item xs={12} textAlign='center' mt={3} color='text.secondary'>
                  En breve te contactará alguien de nuestro equipo para coordinar tu mudanza
                </Box>
              </Hidden>
            </Grid>
          </Grid>
          <Grid container justify='center'>

            <Box component={Grid} container
              justify='center'
              px={{xs:2, sm:4, md:0}}
              mx={2}
              style={{
                maxWidth: 1000,
                backgroundColor: theme.palette.bg,
                borderRadius: 15,
                paddingBottom: 60,
              }}
              >
              <Box component={Grid} container justify='center' color='text.secondary' pt={6}>
                <Hidden smDown>
                  <Box component={Grid} item xs={10} textAlign='center' pb={6}>
                    En breve te contactará alguien de nuestro equipo para coordinar tu mudanza
                  </Box>
                </Hidden>
                <Box component={Grid} container item xs={12} md={7} borderRight={{md:1}} borderColor='black' pr={{md:4}}>
                  <Grid item xs={6} md={8}>
                    <Box color='secondary.main' fontWeight='bold' fontSize={23} pb={1}>
                      Plan {pending.history_plan.plan.name}
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4} container  justify='flex-end'>
                    <Box fontSize={{xs:18, sm:23}}>
                      {formatCurrency(pending.history_plan.plan.price)} / mes
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Box pb={2}>
                      Este plan incluye espacios hasta 12 m3
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box pb={1} fontWeight='bold'>
                      Costo estimado de mudanza:
                    </Box>
                  </Grid>
                  <Grid item xs={4} container justify='flex-end'>
                    <Box component={Grid} mb={{xs:3, md:6}}>
                      {formatCurrency(pending.once_pay)} / única vez
                    </Box>
                  </Grid>
                  <Box
                    component={Grid}
                    item container
                    direction='column'
                    justify='center'
                    alignItems='center'
                    bgcolor={{md:'secondary.main'}}
                    borderColor='secondary.main'
                    border={2}
                    borderRadius={15}
                    mb={{xs:3, md:0}}
                  >
                    <Box component={Grid} item xs={2} color={{xs:'secondary.main', md:'text.primary'}} px={{xs:1, md:0}}>
                      <InfoIcon style={{fontSize: 40}}/>
                    </Box>
                    <Grid item container xs={10} alignItems='center'>
                      <Box
                        component={Grid}
                        fontSize={12}
                        color={{xs:"secondary.main", md:"text.primary"}}
                        px={{xs:1, md:0}}
                      >
                        (*) Se calcula en función al cubicaje del plan elegido. El precio es un estimado y podrá variar según la complejidad de la mudanza. Se paga por única vez en el momento de la mudanza y cada vez que se requiera el servicio.
                      </Box>
                    </Grid>
                  </Box>
                </Box>
                <Box component={Grid} item xs={12} md={3} pl={{md:4}}>
                  <Box
                    pt={{xs: 3, md:0}}
                    mb={1}
                    fontWeight='bold'
                    fontSize={{xs:18, sm:16}}
                    color={{xs:'secondary.main', md:'text.secondary'}}
                  >
                    ¿Cuándo?
                  </Box>
                  <Box>
                    {moment(pending.date).format('D [de] MMMM [de] YYYY')}
                  </Box>
                  <Box fontSize={12}>
                    Entre las {pending.from_hour.slice(0, 5)} y las {pending.to_hour.slice(0, 5)}hs.
                  </Box>
                  <Box
                    component={Grid}
                    mb={1} mt={2}
                    fontWeight='bold'
                    fontSize={{xs:18, sm:16}}
                    color={{xs:'secondary.main', md:'text.secondary'}}
                  >
                    ¿Dónde?
                  </Box>
                  <Box fontSize={12}>
                    {pending.direction.street} {pending.direction.number} {pending.direction.dpto} / {pending.direction.city}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </>)}
      </Box>
  )
}

export default Summary
