import React, { useState, useRef, useEffect } from 'react'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useSelector } from 'react-redux';
import { selectUser } from "features/mainSlice";

import logo from 'img/logo.svg';

let googleTagManagerLoaded = false;
let googleTagManagerLoadedBody = false;

function loadGoogleTagManager() {

  if (!googleTagManagerLoaded) {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-W52XLNNR');
    `;
    document.head.appendChild(script);
    googleTagManagerLoaded = true;
  }
}

function loadGoogleTagManagerBody() {
  if (!googleTagManagerLoadedBody) {
    const script = document.createElement('noscript');
    script.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W52XLNNR"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(script);
    googleTagManagerLoadedBody = true;
  }
}


const useStyles = makeStyles(theme => ({
  main:{
    display: 'flex',
    alignItems: 'center',
    padding: '20px 26px 20px 5px',
    [theme.breakpoints.up('sm')]:{
      padding: '10px 80px 10px 80px'
    },
    [theme.breakpoints.up('lg')]:{
      padding: '10px 150px 10px 150px'
    },
    [theme.breakpoints.up('xl')]:{
      padding: '10px 150px 10px 150px'
    },
  },
  logoTransparent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    transition: 'height 0.5s ease',
    [theme.breakpoints.up('sm')]:{
      height: 100,
    },
    [theme.breakpoints.down('sm')]: {
      height: 90,
    },
    '& a, & img': {
      height: '100%',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'height 0.5s ease',
    height: 200,
    [theme.breakpoints.up('sm')]:{
      height: 200,
    },
    [theme.breakpoints.down('sm')]: {
      height: 150,
    },
    '& a, & img': {
      height: '100%',
    },
  },
  menuIcon:{
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]:{
      fontSize: theme.typography.pxToRem(40),
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    "&>*+*": {
      marginLeft: 80,
    },
  },
  menuMobile:{
    backgroundColor: theme.palette.primary.main,
  },
}));

const  Header = ({redirect, transparent}) => {
  const {name} = useSelector(selectUser);
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const menuMobile = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    loadGoogleTagManager();
    loadGoogleTagManagerBody();
    if (!small) setMenuOpen(false);
  }, [small])

  return (
    <Container
      component={Box}
      style={{
        transition: '.5s background-color',
      }}
      bgcolor={transparent ? 'transparent' : 'primary.main'}
      classes={{root: classes.main}}
      maxWidth={false}
      disableGutters
    >
      <Container classes={{root: transparent ? classes.logo : classes.logoTransparent}}  align={'center'}maxWidth='xl' disableGutters>
        
        <Link to="/" >
          <img src={logo} alt="logo"/>
        </Link>
      </Container>

    </Container>
  )
}

const Component = ({redirect}) => {
  const [transparent, setTransparent] = useState(true)
  useEffect(() => {
    const checkScroll = () => {
      setTransparent(window.scrollY < 100)
    };

    window.addEventListener('scroll', checkScroll);
    checkScroll();
    return () => {
      window.removeEventListener('scroll', checkScroll);
    }
  }, [])
  return (
    <>
      <Box position='fixed' width='100%' zIndex={100}>
        <Header redirect={redirect} transparent={transparent}/>
      </Box>
      <Box style={{opacity: 0}}>
        <Header redirect={redirect} />
      </Box>
    </>
  )
}

export default Component;