import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Text, Checkbox, FormattedNumber, ExpirationDate, Select } from 'components/form';
import MenuItem from '@material-ui/core/MenuItem';

import { useTheme } from '@material-ui/core/styles';
import { useMessage } from 'components/message';
import useMercadopago from './useMercadopago';
import { useSelector } from 'react-redux';

import { selectPlan, selectToken } from 'features/mainSlice';

import { Formik, Form, useFormikContext} from 'formik';
import * as Yup from 'yup';

import mpErrors from './mp-errors';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { subscribeEndpoint, subscribeAdapter, transactionEndpoint, transactionAdapter } from './config';

const validationSchema = Yup.object({
  cardNumber: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  cardholderName: Yup.string()
    .required('Falta completar este campo'),
  cardExpiration: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  docType: Yup.string()
    .required('Falta completar este campo'),
  securityCode: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  docNumber: Yup.number()
    .nullable()
    .typeError('Debe completar con un número')
    .required('Falta completar este campo'),
  terms: Yup.boolean()
    .oneOf([true], 'Debe aceptar los términos y condiciones del servicio para registrarse')
    .required(),
})

const TermsError = () => {
  const {errors:{terms: error}, touched:{terms: touched}} = useFormikContext();
  return !touched ? null:(
    <div style={{height: 0}}>
      <FormHelperText error>{error}</FormHelperText>
    </div>
  );
};


const Payment = () => {
  const plan = useSelector(selectPlan);
  const history = useHistory();
  const token = useSelector(selectToken);

  const { enqueueMessage } = useMessage();

  const createTransaction = useCallback(
    async () => {
      if(!token || !plan || !history) return;
      try {
        const response = await fetch(transactionEndpoint, {
          method: 'POST',
          body: transactionAdapter(plan),
          headers: {
            'Content-Type': `application/json;charset=UTF-8`,
            accept: `application/json, text/plain, */*`,
            authorization: `Bearer ${token}`
          }
        });
        if(!response.ok) throw new Error();
        await response.json();
      } catch(e) {
        console.log(e);
        enqueueMessage({
          title: 'No fué posible solicitar su plan!',
          body: '',
          variant: 'error',
        })
        history.push('/');
      }
    },
    [token, plan, history],
  )

  useEffect(() => {
    createTransaction();
  }, [createTransaction]);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const form  = useRef(null);
  const [Mercadopago, paymentMethods, error] = useMercadopago();

  const [thumb, setThumb] = useState();
  const guessPaymentMethod = useCallback( ({target: {value: cardnumber}}) => {
    if (cardnumber.length >= 6) {
        let bin = cardnumber.substring(0,6);
        Mercadopago.getPaymentMethod({
            "bin": bin
        }, setPaymentMethod);
    } else {
      setThumb();
    }
  }, [Mercadopago]);

  const setPaymentMethod = useCallback((status, response) => {
    if (status === 200) {
        let paymentMethod = response[0];
        const card = ['visa', 'master', 'amex'].includes(paymentMethod.id) ? paymentMethod.id : 'other'
        setThumb(`/cards/${card}.jpg`);
    } else {
        console.log(`payment method info error: ${response}`);
    }
  }, [Mercadopago]);

  return (
    <Formik
      initialValues={{
        cardNumber: '',
        cardholderName: '',
        cardExpiration: '',
        docType: '',
        securityCode: '',
        docNumber: '',
        terms: false,
      }}
      validationSchema={validationSchema}

      onSubmit={async (values, stuff) => {
        const { setSubmitting } = stuff;
        try {
          const response  = await new Promise((resolve, reject)=> Mercadopago.createToken(form.current,
            (status, response) => status<300?resolve(response):reject(response.cause[0].code)
          ));

          const subscription = await fetch(subscribeEndpoint, {
            method: 'POST',
            body: subscribeAdapter(response),
            headers: {
              authorization: `Bearer ${token}`
            }
          })

          if(subscription.ok)
            history.push('/summary')
          else
            throw new Error()
        } catch (error) {
          enqueueMessage({
            title: 'Algo salió mal!',
            body: mpErrors[error],
            variant: 'error',
          })
        }

          setSubmitting(false);
      }}

    >
    {({ isSubmitting }) => (
      <Box component={Form} bgcolor='grey.100' py={6} ref={form}>
        <Grid container justify='center'>
          <Grid container>
            <Hidden mdUp>
              <Grid item container xs={12} justify='center'>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Espacio
                </Box>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Mudanza
                </Box>
                <Box component={Typography} variant='body1' color='text.secondary' mx={1} mb={3}>
                  Pago
                </Box>
                <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
                  Resumen
                </Box>
              </Grid>
            </Hidden>
            <Grid item container xs={12} justify='center' style={{ paddingBottom: 50, }}>
              <Hidden smDown>
                <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>Ingresá tus datos</Typography>
              </Hidden>
              <Hidden mdUp>
                <Box
                  component={Typography}
                  fontWeight='bold'
                  variant='h2'
                  color='text.secondary'
                  align='center'
                  gutterBottom
                >
                    Ingresá tus datos
                </Box>
              </Hidden>
            </Grid>
          </Grid>
          <Box component={Grid}
            bgcolor={{md: theme.palette.bg}}
            pb={{md: 4}}
          container
          style={{
            maxWidth: 1000,
            borderRadius: 15,
          }} justify='center' spacing={3}>
            <Hidden smDown>
                <Grid item container xs={12} justify='center'>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={4}>
                    Espacio
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={4}>
                    Mudanza
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='text.secondary' mx={3} mt={4}>
                    Pago
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mt={4}>
                    Resumen
                  </Box>
                </Grid>
              </Hidden>
            <Grid item container xs={12} justify='center'>
              <Hidden smUp>
                <Grid item container xs={12} justify='center'>
                  <Box component={Typography} variant='subtitle2' color='text.secondary' align='center' fontWeight={600}>
                    El pago será debitado mensualmente.
                  </Box>
                </Grid>
                <Grid item container xs={12} justify='center'>
                  <Box component={Typography} variant='subtitle2' color='text.secondary' align='center' fontWeight={600}>
                    El costo de envío se cobra por única vez.
                  </Box>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <Grid item container xs={12} justify='center'>
                  <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom>
                    El pago será debitado mensualmente. El costo de envío se cobra por única vez.
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <FormattedNumber mp
                  placeholder='Número de tarjeta'
                  format='#### #### #### ####'
                  fullWidth
                  color='secondary'
                  name='cardNumber'
                  label='Número de tarjeta'
                  onChange={e => {guessPaymentMethod(e);}}
                  adornment={sm ?
                  <Box
                    width={50+4*2}
                    height={30}
                    borderRadius={10}
                    m={1}
                    bgcolor='grey.300'
                    {...thumb ? {
                        component:'img',
                        src: thumb,
                      }:{}
                    }
                  />
                  :
                  <Box
                    width={64+9*2}
                    height={37}
                    borderRadius={5}
                    m={1}
                    bgcolor='grey.500'
                    {...thumb ? {
                        component:'img',
                        src: thumb,
                      }:{}
                    }
                  />}
                />
              </Grid>/
            </Grid>
            <Grid item container xs={12} sm={6} justify='center' alignItems="flex-end">
              <Grid item container xs={10} sm={9} justify='center'>
                <Text mp fullWidth color='secondary' name='cardholderName' label='Nombre del titular de la tarjeta *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <ExpirationDate mp
                  format="##/##"
                  placeholder="MM/YY"
                  mask={['M', 'M', 'Y', 'Y']}
                  fullWidth
                  color='secondary'
                  name='cardExpiration'
                  label='Fecha de Expiración'
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Select mp fullWidth color='secondary' name='docType' label='Tipo de documento *'>
                  {paymentMethods.map(({id, name})=>
                    <MenuItem key={id} value={id}>{name}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text mp fullWidth color='secondary' name='securityCode' label='Código de seguridad *' />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text mp fullWidth color='secondary' name='docNumber' label='Número de documento *' />
              </Grid>
            </Grid>

            <Grid item container xs={12} justify="center" style={{ paddingTop: 50, }}>
              <Hidden mdUp>
                <Box component={Grid} item xs={12} md={10} mb={1}>
                    <Box component={Typography} variant={'body1'} color="text.secondary" align='center' fontSize='min(4vw, 20px)' mb={4}>
                      <Checkbox name='terms' style={{ display: 'inline-block' }} />
                      Acepto los <a href="/tyc" target="_blank">términos y condiciones</a>
                    </Box>
                    <TermsError />
                </Box>
              </Hidden>
              <Grid item container xs={10} md={10} justify='space-between'>
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <Link to='/appointment'>
                    <Button
                      variant="contained"
                      color="primary"
                      size={xs?'large':'medium'}
                    >
                      Atrás
                    </Button>
                  </Link>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  position='relative'
                >
                  <Hidden smDown>
                  	<Box component={Typography}
                      variant={'h6'}
                      color="text.secondary"
                      align='center'
                      fontSize={{ sm: 16 }}
                      height={35}
                    >
                  	  <Checkbox
                        name='terms'
                        style={{ display: 'inline-block' }}
                        offColor={theme.palette.grey[500]}
                      />
                  	  Acepto los <Box component='a' href="/tyc" target="_blank" color="text.secondary">términos y condiciones</Box>
                  	</Box>
                  </Hidden>
                  <Link to={`${sm?'/':''}#atencion`}>
                    <Box
                      component={Typography}
                      variant={'body2'}
                      color="text.secondary"
                      fontSize='min( 3.4vw, 16px )'
                    >
                      ¿Necesitás ayuda?
                    </Box>
                  </Link>
                  <Hidden smDown>
                    <TermsError />
                  </Hidden>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size={xs?'large':'medium'}
                    disabled={isSubmitting}
                  >
                    Seguir
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    )}
    </Formik>
  )
}

export default Payment
