import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';


import { useMessage } from 'components/message';
import { useSelector, useDispatch } from 'react-redux';

import { selectToken, updatePlan, selectPlan } from 'features/mainSlice';

import edit from 'img/edit.svg';

import {
  StaticGoogleMap,
  Marker,
  Path,
} from 'react-static-google-map';

import { Text, Phone, Date, Switch, Checkbox } from 'components/form';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { adapterIn, adapterOut, endpoint } from './config';

import { formatCurrency } from 'utils';

import { Formik, Form, useFormikContext, yupToFormErrors } from 'formik';
import * as Yup from 'yup';


const validationSchema = Yup.object({
  street: Yup.string().required('Falta completar este campo'),
  number: Yup.string().required('Falta completar este campo'),
  dpto: Yup.string().required('Falta completar este campo'),
  zip_code: Yup.number().nullable().typeError('Debe completar con un número').required('Falta completar este campo'),
  state: Yup.string().required('Falta completar este campo'),
})

const Edit = () => (
  <div style={{
    display: 'inline-block',
    backgroundImage: `url('${edit}')`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 40,
    height: 30,
    // marginRight: 12,
  }} />
);

const ConfirmAddress = () => {
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { enqueueMessage } = useMessage();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const token = useSelector(selectToken);
  const [ address, setAddress] = useState();

  const getAddress = useCallback(
    async () => {
    let result = {};
    try {
      const response = await fetch(endpoint, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });

      result = await response.json();

      if(!response.ok) throw new Error();

      const adapted = adapterIn(result);

      if(!adapted) throw new Error();

      setAddress(adapted);
      dispatch(updatePlan({
        address: adapted,
        direction: adapted.id,
        zip_code: adapted.zip_code,
      }))
      } catch(e) {
        history.push('/');
        enqueueMessage({
          ...result.message ? {
            title: result.message,
          } : {
            title: 'Algo salió mal!',
            body: 'No pudimos obtener su dirección',
          },
          variant: 'error',
        })
        return
      }
    },
    [token, dispatch, enqueueMessage, history],
  )

  useEffect(() => {
    getAddress();
  }, []);

  const {zip_code, street, number, dpto, state} = address || {};

  return (
    <Box bgcolor='grey.100' position='relative'>
      <Backdrop open={!address || loading} style={{position:'absolute', zIndex: 5}}>
        <CircularProgress/>
      </Backdrop>
      <Box
        component={Grid}
        item container
        xs={12}
        direction='column'
        alignItems='center'
        justify='center'
        pb={{md: 6}} pt={6}
      >
        <Hidden mdUp>
          <Grid item container xs={12} justify='center'>
            <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
              Espacio
            </Box>
            <Box component={Typography} variant='body1' color='text.secondary' mx={1} mb={3}>
              Mudanza
            </Box>
            <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
              Pago
            </Box>
            <Box component={Typography} variant='body1' color='grey.500' mx={1} mb={3}>
              Resumen
            </Box>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>Coordinación de mudanza</Typography>
        </Hidden>
        <Hidden mdUp>
          <Box
            component={Typography}
            fontWeight='bold'
            variant='h2'
            color='text.secondary'
            align='center'
            gutterBottom
          >
              Coordinación de mudanza
          </Box>
          <Box color='text.secondary' fontSize={20} fontWeight='bold'>¿Dónde?</Box>
        </Hidden>
      </Box>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        style={{display:'flex', justifyContent:'center', alignItems:'center'}}
        BackdropProps={{style:{backgroundColor:'rgba(255,255,255,0.5)'}}}
      >
        <Box maxWidth={700}>
          <Formik
            initialValues={{
              street: street,
              number: number,
              dpto: dpto,
              zip_code: zip_code,
              state: state,
            }}
            validationSchema={validationSchema}

            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setLoading(true);
              setOpen(false);
              const response = await fetch(endpoint, {
                method: "POST",
                body: adapterOut(values),
                headers: {
                  authorization: `Bearer ${token}`
                },
              });
              setSubmitting(false);

              if(!response.ok){
                enqueueMessage({
                  title: 'Algo salió mal!',
                  body: 'No pudimos actualizar su dirección',
                  variant: 'error',
                })

              }else{
                await response.json();
                await getAddress();
                setLoading(false);
              }
            }}
          >
          {({ isSubmitting }) => (
            <Form>
              <Box component={Grid}
                m={{xs:2}}
                px={{xs:2, sm:1}}
                pt={{xs:2, sm:5}}
                pb={{xs:2, sm:2}}
                container
                style={{
                  maxWidth: 1000,
                  backgroundColor: theme.palette.bg,
                  borderRadius: 15,
                }}
                justify='center'
                spacing={3}
              >
                <Grid container justify='center'>
                  <Box component={Grid}
                    item container
                    xs={10}
                    justify='space-between'
                    alignItems='center'
                    pb={{md:5}}
                  >
                    <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>Modificar domicilio</Typography>
                    <Box color='text.secondary' fontWeight='bold' p={2} onClick={()=>setOpen(false)}>
                      <CloseIcon/>
                    </Box>
                  </Box>
                </Grid>
                <Grid item container xs={12} justify='center'>
                  <Grid item container xs={10} justify='center'>
                    <Text fullWidth color='secondary' name='street' label='Dirección *' />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify='center'>
                  <Grid item container xs={5} justify='center'>
                    <Text fullWidth color='secondary' name='number' label='Número *' style={{ paddingRight: 20 }} />
                  </Grid>
                  <Grid item container xs={5} justify='center'>
                    <Text fullWidth color='secondary' name='dpto' label='Piso/Depto*' />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify='center'>
                  <Grid item container xs={10} justify='center'>
                    <Text fullWidth color='secondary' name='zip_code' label='CP *' />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify='center'>
                  <Grid item container xs={10} justify='center'>
                    <Text fullWidth color='secondary' name='state' label='Provincia/Localidad *' />
                  </Grid>
                </Grid>
                <Box component={Grid}
                  item container
                  xs={12} sm={6}
                  justify='center'
                >
                  {false && //TODO: Add again when this functionality is properly implemented
                  <Box
                    component={Grid}
                    item container
                    xs={10} sm={9}
                    justify='flex-start'
                    flexWrap='nowrap'
                    my={3}
                  >
                    <Typography color='secondary' style={{ marginRight: 30 }}>
                      Predeterminada
                    </Typography>
                    <Switch name={'is_company'} />
                  </Box>
                  }
                </Box>
                <Grid item container xs={12} sm={6} justify='center'>
                  <Box
                    component={Grid}
                    item container
                    xs={10} sm={9}
                    justifyContent='flex-end'
                    // my={4}
                    pt={{sm: 2}} pb={3}
                  >
                    <Button
                      type='submit'
                      variant="contained"
                      color="primary"
                      size='medium'
                      disabled={isSubmitting}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Form>
          )}
          </Formik>
        </Box>
      </Modal>
      <Fade in={!!address}>
        <Grid container justify='center'>
          <Box component={Grid}
            container
            bgcolor={{ md: theme.palette.bg }}
            px={{xs:2, sm:0}}
            style={{
              maxWidth: 1000,
              borderRadius: 15,
              paddingBottom: 60,
            }}
            justify='center'
          >
            <Box
              component={Grid}
              container
              justify='center'
              color='text.secondary'
              pt={5}
            >
              <Hidden smDown>
                <Grid item container xs={12} justify='center'>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Espacio
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='text.secondary' mx={3} mb={5}>
                    Mudanza
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Pago
                  </Box>
                  <Box component={Typography} variant='subtitle2' color='grey.500' mx={3} mb={5}>
                    Resumen
                  </Box>
                </Grid>
              </Hidden>
              <Box
                component={Grid}
                container item
                xs={12} sm={7}
                borderRight={{sm:1}}
                borderColor='black'
                pr={{sm: 4}}
              >
                <Grid
                  item container
                  direction='column'
                  xs={10} md={6}
                  justify='center'
                >
                  <Hidden xsDown>
                    <Box fontWeight='bold' fontSize={20}>
                      Pasaremos por
                    </Box>
                  </Hidden>
                  <Box fontSize={{xs: 18, sm: 16}}>
                    {street} {number}, {dpto}
                  </Box>
                  <Box fontSize={{xs: 18, sm: 16}}>
                    {state} (CP {zip_code})
                  </Box>
                </Grid>
                <Grid item xs={2} md={6} container justify='flex-end' alignItems='center'>
                  <Hidden smDown>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='medium'
                      onClick={()=>setOpen(true)}
                    >
                      Cambiar dirección
                    </Button>
                  </Hidden>
                  <Hidden mdUp>
                    <IconButton onClick={()=>setOpen(true)}>
                      <Edit />
                    </IconButton>
                  </Hidden>
                </Grid>
                <Grid item xs={12}>
                  <Box component={StaticGoogleMap}
                    width='100%'
                    mt={3}
                    mb={{xs:2, md:0}}
                    size={sm?"400x200": "500x180"}
                    apiKey={process.env.REACT_APP_GMAP_KEY}
                  >
                    <Marker location={`${street} ${number}, ${state}, Argentina`} />
                  </Box>
                </Grid>
              </Box>
              <Hidden xsDown>
                <Box component={Grid} item xs={3} pl={4}>
                  <Box mb={2} fontWeight='bold' fontSize={20}>
                    Resumen
                  </Box>
                  <Box>
                    Costo Plan {plan.planInfo.name}
                  </Box>
                  <Box fontWeight='bold' mb={6}>
                    {formatCurrency(plan.planInfo.price)} / mes
                  </Box>
                  <Box>
                    Costo estimado de mudanza
                  </Box>
                  <Box fontWeight='bold'>
                    a confirmar / única vez
                  </Box>
                </Box>
              </Hidden>
            </Box>
            <Box
              component={Grid}
              item container
              xs={12} sm={10}
              justify='space-between'
              alignItems='center'
              pt={2}
            >
              <Box
                display='flex'
                alignItems='center'
              >
                <Link to='/'>
                  <Button
                    variant="contained"
                    color="primary"
                    size={xs?'large':'medium'}
                  >
                    Atrás
                  </Button>
                </Link>
              </Box>
              <Box item container xs={5} justify='center' alignItems='center'>
                <Link to={`${sm?'/':''}#atencion`}>
                  <Box
                    component={Typography}
                    variant={'body2'}
                    color="text.secondary"
                    fontSize='min( 3.4vw, 16px )'
                  >
                    ¿Necesitás ayuda?
                  </Box>
                </Link>
              </Box>
              <Box
                display='flex'
                alignItems='center'
              >
                <Link to='/appointment'>
                  <Button
                    variant="contained"
                    color="primary"
                    size={xs?'large':'medium'}
                  >
                    Seguir
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Fade>
    </Box>
  )
}

export default ConfirmAddress
