import moment from 'moment';

export const transactionEndpoint = `${process.env.REACT_APP_API}/transaction`;
export const subscribeEndpoint = `${process.env.REACT_APP_API}/plans/subscribe`;

export const transactionAdapter = (collected) => {
  const {
    plan,
    planInfo,
    address,
    direction,
    zip_code,
    date,
    from_hour,
    to_hour,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    plan,
    services: [],
    materials: [],
    oncePay: 666,
    description: 'none',
    zip_code,
    planInfo: {
      ...planInfo,
      flipped: false,
      price_total_hour_men: 0,
    },
    address,
    direction,
    from_hour,
    to_hour,
    from_hour_packaging: from_hour,
    to_hour_packaging: to_hour,
    date,
    date_shipping_packaging: moment(date).subtract(1, 'day').format('YYYY-MM-DD'),
  };
  //console.log("Adapted data: ", adapted);

  return JSON.stringify(adapted);
};

export const subscribeAdapter = (response) => {
  const {
    id
  }  = response;
  //console.log("MP response data: ", response);

  const adapted = {
    token: id
  };
  //console.log("Adapted data: ", adapted);

  const data = new FormData();
  Object.keys(adapted).forEach(key => {
    data.append(key, adapted[key])
  });
  return data;
};