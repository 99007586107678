export const endpoint = `${process.env.REACT_APP_API}/login`;

export const adapterOut = (collected) => {
  const {
    username,
    password,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    username: username,
    password: password,
  };
  //console.log("Adapted data: ", adapted);

  const data = new FormData();
  Object.keys(adapted).forEach(key => {
    data.append(key, adapted[key])
  });
  return data;
};

export const adapterIn = (response) => {
  const {
    ...keys
  }  = response;
  //console.log("Collected data: ", collected);

  const adapted = (typeof response === 'string') ?
    response :
    {
    ...keys
    };
  //console.log("Adapted data: ", adapted);
  return adapted;
};
