import React, { useRef } from 'react'

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useTheme } from '@material-ui/core/styles';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100)
const scrollToTop = () => window.scrollTo(0, 0)

const Tyc = () => {
  const theme = useTheme();
  const refs = [];
  
  refs['Términos y condiciones'] = useRef();
  refs['Nuestros servicios'] = useRef();
  refs['Quienes pueden contratar'] = useRef();
  refs['Procedimiento de alta de Usuarios'] = useRef();
  refs['El uso de nuestros Servicios'] = useRef();
  refs['Visita, Embalaje y Guardado'] = useRef();
  refs['Ítems Prohibidos'] = useRef();
  refs['Ítems Frágiles'] = useRef();
  refs['Límite de responsabilidad'] = useRef();
  refs['Pago del Servicio'] = useRef();
  refs['Devolución parcial – Terminación del Servicio'] = useRef();
  refs['Propiedad Intelectual'] = useRef();
  refs['Protección de datos personales'] = useRef();
  refs['Cesión'] = useRef();
  refs['Revocación de la aceptación'] = useRef();
  refs['Acuerdo Total'] = useRef();
  refs['Modificación y/o actualización'] = useRef();
  refs['Ley Aplicable y Jurisdicción'] = useRef();

  
  

  return (
    <Container component={Box} maxWidth={false} color='primary.main' bgcolor='grey.100' top={0} p={3} disableGutters>
      <Box display={{md:'none'}} position='fixed' bottom={20} right={20}>
        <Fab
          onClick={scrollToTop}
          color="secondary"
        >
          <ExpandLessIcon />
        </Fab>
      </Box>
      <Grid container maxWidth='lg'>
        <Grid component={MenuList} item xs={12} md={3}>
          <Box position={{xs:'relative', md:'fixed'}} pb={1}>
          <MenuItem style={{ cursor: 'pointer' }} onClick={() => scrollToRef(refs['Términos y condiciones'])}>Términos y condiciones</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Nuestros servicios'])}>Nuestros servicios</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Quienes pueden contratar'])}>Quienes pueden contratar</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Procedimiento de alta de Usuarios'])}>Procedimiento de alta de Usuarios</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['El uso de nuestros Servicios'])}>El uso de nuestros Servicios</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Visita, Embalaje y Guardado'])}>Visita, Embalaje y Guardado</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Ítems Prohibidos'])}>Ítems Prohibido</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Ítems Frágiles'])}>Ítems Frágiles</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Límite de responsabilidad'])}>Límite de responsabilidad</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Pago del Servicio'])}>Pago del Servicio</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Devolución parcial – Terminación del Servicio'])}>Devolución parcial – Terminación del Servicio</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Propiedad Intelectual'])}>Propiedad Intelectual</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Protección de datos personales'])}>Protección de datos personales</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Cesión'])}>Cesión</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Revocación de la aceptación'])}>Revocación de la aceptación</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Acuerdo Total'])}>Acuerdo Total</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Modificación y/o actualización'])}>Modificación y/o actualización</MenuItem>
          <MenuItem style={{ cursor: 'pointer', fontSize: '14px', padding: '8px 16px' }} onClick={() => scrollToRef(refs['Ley Aplicable y Jurisdicción'])}>Ley Aplicable y Jurisdicción</MenuItem>


          </Box>
        </Grid>
        <Box component={Grid} item xs={12} md={9}
          borderRadius={15}
          padding={3}
          bgcolor={theme.palette.bg}
          color='text.secondary'
        >
      <article>
        <Typography ref={refs['Términos y condiciones']} variant='h2' color='secondary'>TÉRMINOS Y CONDICIONES – Todo En Orden</Typography>
        <p>Este documento contiene los términos y condiciones (en adelante, los “Términos y Condiciones”) que constituyen el contrato que regirá la relación entre cualquier persona (en adelante, el “Usuario”, o los “Usuarios” en forma plural) que contrate cualquiera de los servicios ofrecidos en la página web de su propiedad www.todoenorden.com (en adelante, “TODO EN ORDEN”) por Todo En Orden, nombre de fantasía de la firma TRANQUI SRL CUIT Nro. 30-71783572-3, con domicilio en Av. Callao 2014.</p>
        <p>El Usuario deberá leer, entender y aceptar nuestros Términos y Condiciones, previo a contratar nuestros servicios (en adelante, el “Servicio”). Se deja constancia que la contratación de nuestro Servicio implica la aceptación expresa por parte del Usuario de nuestros Términos y Condiciones. Los Términos y Condiciones son de carácter obligatorio y vinculante, por lo que en caso de que el Usuario no esté de acuerdo deberá abstenerse de contratar o utilizar nuestro Servicio.</p>
      </article>
      
      <article>
        <Typography ref={refs['Nuestros servicios']} variant='h2' color='secondary'></Typography>
        
        <h4>1. Nuestros servicios.</h4>
        <p>1.1. TODO EN ORDEN ofrece a sus Usuarios un servicio de guardado y custodia de sus bienes (en adelante, los “Ítems”) conforme las especificaciones y limitaciones que se describen en este documento en un lugar seguro sin moverte de tu casa conforme al plan de guardado que se contrate.</p>
        <p>1.2. El Usuario también podrá contratar servicios adicionales que serán cotizados y facturados en forma separada a su requerimiento conforme será especificado más adelante.</p>
        
      </article>

      <article>
        <Typography ref={refs['Quienes pueden contratar']} variant='h2' color='secondary'></Typography>
        
        <h4>2. Quienes pueden contratar.</h4>
        <p>2.1. Podrán ser Usuarios de nuestro Servicio toda persona que tenga capacidad legal para contratar en la República Argentina.</p>
        <p>2.2. Se deja constancia que TODO EN ORDEN presta un servicio local que opera en el ámbito de la Ciudad Autónoma de Buenos Aires y determinados barrios del Gran Buenos Aires. Se sugiere a los Usuarios consultar previamente las localidades habilitadas para la prestación del Servicio.</p>
        
      </article>

      <article>
        <Typography ref={refs['Procedimiento de alta de Usuarios']} variant='h2' color='secondary'></Typography>
        
        <h4>3. Procedimiento de alta de Usuarios.</h4>
        <p>3.1. El Usuario deberá llevar a cabo el procedimiento de alta según los lineamientos establecidos por TODO EN ORDEN en su página web. El mismo implicará la entrega de información, datos personales y documentación del Usuario. Los datos aportados por el Usuario se presumen válidos y ciertos, siendo el Usuario responsable en caso de cualquier falsedad o inexactitud. El Usuario se compromete a informar cualquier modificación de sus datos, y a mantenerlos actualizados a requerimiento de TODO EN ORDEN. No se podrá contratar el Servicio en nombre de terceras personas.</p>
        <p>3.2. TODO EN ORDEN se reserva el derecho de rechazar la solicitud de alta de Usuario en caso de verificar inconsistencias o incongruencias en la información, datos personales y/o documentación aportada, como así también ante hallazgos de antecedentes comerciales, públicos o de cualquier índole que considere negativos.</p>
        <p>3.3. Se deja constancia que el alta de Usuarios y contratación del Servicio implicará la generación de una cuenta personal en la página web de TODO EN ORDEN (en adelante, la “Cuenta Personal”) desde la cual gestionará la contratación del Servicio, lo cual implicará la aceptación de estos Términos y Condiciones, siendo legalmente válidos y exigibles.</p>
        <p>3.4. El Usuario deberá generar un perfil con una contraseña para acceder a su Cuenta Personal. El Usuario deberá generar una clave de confirmación siguiendo los pasos establecidos por TODO EN ORDEN, y será el único y exclusivo responsable de la confidencialidad de sus Claves, así como también de todas las operaciones y/o actividades llevadas a cabo desde su Cuenta Personal, las cuales se presumen realizadas por el Usuario.</p>
        
      </article>

      <article>
        <Typography ref={refs['El uso de nuestros Servicios']} variant='h2' color='secondary'></Typography>
        
        <h4>4. El uso de nuestros Servicios.</h4>
        <p>4.1. El Usuario se compromete a hacer uso de buena fe del Servicio, y no utilizarlo para actividades ilícitas o ilegales. El Usuario asume plena responsabilidad frente a TODO EN ORDEN y/o terceros, por cualquier daño y perjuicio que se produjera como consecuencia del mal uso que se haga del Servicio y/o incumplimiento de estos Términos y Condiciones, debiendo mantener indemne a TODO EN ORDEN ante cualquier reclamo de cualquier naturaleza que se pudiera interponer en su contra.</p>
        <p>4.2. El Usuario declara y garantiza que utilizará el Servicio para el guardado de Ítems de su propiedad.  Asimismo garantiza que ningún tercero tiene derecho de retención sobre la propiedad guardada en TODO EN ORDEN. En tal sentido, el Usuario deberá mantener indemne a TODO EN ORDEN en caso de cualquier reclamo de terceros en relación a la propiedad de Ítems que se encuentren guardados en sus depósitos.</p>
        <p>4.3. TODO EN ORDEN se reserva el derecho suspender o a dar de baja, en forma automática y sin necesidad de interpelación previa, el Servicio en caso de considerar o tener sospechas de que el Usuario está realizando un uso indebido, inadecuado o inapropiado del Servicio o ante cualquier incumplimiento de los Términos y Condiciones, sin que ello genere derecho alguno a exigir compensación de parte del Usuario. En tal caso, el Usuario será responsable de los daños y perjuicios que hubiera ocasionado.</p>
        <p>4.4. Se deja constancia que el personal de TODO EN ORDEN será el único y exclusivo habilitado para ingresar al depósito de guardado de los Ítems. En caso de requerir la devolución de algún Ítem, el Usuario deberá coordinar la fecha de entrega previamente desde su Cuenta Personal, y se hará la devolución directamente en el domicilio acordado.</p>
        
      </article>

      <article>
        <Typography ref={refs['Visita, Embalaje y Guardado']} variant='h2' color='secondary'></Typography>
        
        <h4>5. Visita, Embalaje y Guardado.</h4>
        <p>5.1. En caso de contratar nuestros Servicios, el personal de TODO EN ORDEN coordinará una visita al domicilio del Usuario a los fines de determinar el espacio necesario de guardado y el tipo de Ítems que serán guardados en los depósitos. En caso de no ser posible coordinar la visita, el Usuario deberá enviar fotos de los Ítems bajo declaración de autenticidad y veracidad. Todo ello, permitirá definir el Plan de Guardado en base a los metros necesarios (Plan ESPECIAL, S, M, L, XL o XXL) (en adelante, el “Plan de Guardado”). Se deja constancia que TODO EN ORDEN podrá modificar el precio del Servicio en caso de que el Usuario agregue, reduzca o modifique Ítems a su Plan de Guardado que no habían sido previamente informados y/o contemplados al momento de la contratación.</p>
        <p>5.2. Será responsabilidad del Usuario tener todos los Ítems debidamente guardados y embalados para la fecha de mudanza acordada. El Usuario deberá asegurarse de que la recolección de tus Ítems suceda en un ambiente seguro. Esto incluye lugares que no contengan evidencias de contaminación o infestación por chinches, polillas u otras plagas. No se podrán guardar más de veinte (20) kilos de peso en cada caja individual o contenedor. TODO EN ORDEN se reserva el derecho de negarse a aceptar cualquier artículo que pese más de veinte (20) kilos. También nos reservamos el derecho de volver a embalar cualquier objeto para seguridad o integridad de los Ítems.</p>
        <p>5.3. El personal de TODO EN ORDEN estará facultado a abrir las cajas y/o contenedores para tomar fotos del contenido antes de que se cierren y guarden y/o para inspeccionar el contenido del mismo. Por eso, es importante que las cajas estén abiertas en el momento de la mudanza. Los aparatos como las unidades de aire acondicionado y los refrigeradores deben estar desenchufados y secos. Los televisores, estantes y los cuadros deben estar descolgados de la pared. El Usuario deberá asegurar de que haya un camino claro y cómodo hacia la calle para poder trasladar los Ítems. El Usuario será responsable de tramitar el certificado o autorización en caso de que su lugar de domicilio así lo requiera. Si se retrasa una cita o se prolonga su duración debido a la falta de preparación en debida forma por parte del Usuario, se aplicarán tarifas extra en el Servicio según la fracción de tiempo que genere el retraso. A su entera discreción, TODO EN ORDEN podrá optar por cancelar o negarse a terminar el Servicio si no se encuentra todo debidamente preparado por cualquier motivo enumerado anteriormente. Si el Usuario cancela la cita o se vuelve a programar después del período de cancelación gratuito (hasta 24 horas antes de la misma), TODO EN ORDEN podrá cobrar un cargo por cancelación, ausentismo en el momento de la cita o reprogramación.</p>
        <p>5.4. Se informa al Usuario que TODO EN ORDEN no presta el servicio de traslado de los Ítems hasta el lugar de guardado. El Usuario podrá a su propio cargo y costo ocuparse del traslado de los Ítems. Sin perjuicio de ello, a requerimiento del Usuario, TODO EN ORDEN podrá coordinar con terceros independientes especializados el traslado de los Ítems hasta el lugar de guardado. Por lo tanto, se deja constancia que TODO EN ORDEN no será responsable de cualquier daño o perjuicio que ocurra durante el traslado de los Ítems. Los Usuarios entienden y reconocen que TODO EN ORDEN no transporta en ninguna forma los Ítems, razón por la cual comprenden y aceptan que, TODO EN ORDEN referencia a los terceros transportistas y son éstos quienes realizan el transporte de los Ítems, y por tanto son éstos los responsables exclusivos por el servicio de transporte, por los bienes entregados y/o contenidos en el interior. De esta forma, los Usuarios reconocen y aceptan que TODO EN ORDEN no será responsable por la existencia, estado, calidad y cantidad de los Ítems enviados al guardado durante su traslado.</p>
        <p>5.5. TODO EN ORDEN tomará fotografías de todos los Ítems que sean guardados en sus bauleras a los fines del armado de un catálogo que permita constatar la cantidad de Ítems y el estado de los mismos (en adelante, el “Catálogo”). El Usuario tendrá acceso al Catálogo desde su Cuenta Personal. Se entenderá que existe conformidad del Usuario respecto del contenido del Catálogo en caso de no formular ninguna observación dentro de los diez (10) días de que fuera subido a su Cuenta Personal. El Usuario no podrá reclamar ni exigir ningún tipo de bien que no estuviera agregado en el Catálogo, por lo que sugerimos constatar el contenido e integridad del mismo. Cualquier tipo de bien que no surja del Catalogo se considerará no recibido y no formará parte del Servicio.</p>
        <p>5.6. Salvo que se contrate el servicio adicional de embalaje, el Usuario será responsable de embalar adecuadamente cada uno de los Ítems para evitar que los mismos sean dañados. En dicho caso el Usuario será el único y exclusivo responsable en caso de que el Ítem sufra algún daño, rotura o deterioro durante su traslado o período de guardado. Se sugiere tener en consideración las recomendaciones en relación a los artículos frágiles especificados en estos Términos y Condiciones.</p>
        <p>5.7. Durante la vigencia de la relación contractual el Usuario podrá agregar Ítems adicionales al Plan de Guardado. Para ello deberá realizar la solicitud mediante el acceso a su Cuenta Personal. En caso que corresponda, TODO EN ORDEN cotizará el precio de los Ítems adicionales, los cuales también serán agregados al Catálogo del Usuario.</p>
        
      </article>

      <article>
        <Typography ref={refs['Ítems Prohibidos']} variant='h2' color='secondary'></Typography>
        
        <h4>6. Ítems Prohibidos</h4>
        <p>6.1. Se informa a los Usuarios que los siguientes Ítems están prohibidos y no podrán ser incluidos dentro del Servicio: (i) armas de fuego y cualquier otra cosa usada principalmente como arma; (ii) cualquier cosa que sea ilegal, drogas, dispositivos relacionados a drogas, cosas robadas y/o cualquier otra cosa por la que puedas ser arrestado por posesión ilegal; (iii) cualquier cosa que contenga líquidos y/o pueda transpirar o gotear (a modo de ejemplo, esto incluye cosas como botellas de shampoo, latas de pintura y aerosoles, entre otras); (iv) cualquier elemento explosivo o combustible (a modo de ejemplo, municiones, explosivos, fuegos artificiales y otros materiales inflamables como gas, petróleo, querosén, pintura y líquido encendedor); (v) cualquier cosa que estuvo o esté viva. Esto incluye cosas como frutas, carnes, quesos, animales, insectos, hongos o cultivos bacterianos; (vi) cualquier cosa que sea comestible. Esto incluye cualquier alimento perecedero y no perecedero; y (vii) cualquier cosa que tenga olor así como cualquier tipo de Ítem peligroso, materiales tóxicos, artículos que producen gases u olores y artículos que pueden aumentar de tamaño o explotar. Básicamente, cualquier cosa que pueda salir de tu caja o contenedor y dañar a otras personas, las pertenencias de otros clientes, empleados o nuestras instalaciones.</p>
        <p>6.2. El incumplimiento de lo expuesto en el presente implicaría la finalización inmediata del Servicio, y devolución de todos los Ítems a cargo del Usuario, sin derecho a compensación alguna, y con la posibilidad de que TODO EN ORDEN pueda reclamar legalmente por los daños y perjuicios causados. Asimismo, el Usuario deberá mantener indemne a TODO EN ORDEN por cualquier daño o perjuicio que pudiera ocasionar el incumplimiento a lo previsto en la Cláusula 5.1.</p>
        <p>6.3. Está absolutamente prohibido la guarda de dinero en efectivo (en cualquier moneda), monedas metálicas o cualquier otro valor, obras de arte, tarjetas de crédito, débito o compra, billetes de lotería y/o cualquier otro tipo de comprobantes de juego de azar, joyas, o cualquier otro tipo de bien que posea un gran valor pecuniario. El Usuario será el único y exclusivo responsable en caso de a agregar alguno de dichos bienes de valor dentro de los Ítems de guardado En caso de que TODO EN ORDEN tome conocimiento acerca de este tipo de bienes tendrá la facultad de devolver inmediatamente a los usuarios los mismos.</p>

      </article>

      <article>
        <Typography ref={refs['Ítems Frágiles']} variant='h2' color='secondary'></Typography>
        
        <h4>7.  Ítems Frágiles.</h4>
        <p>7.1. Sugerimos a nuestros Usuarios evitar el guardado de “artículos frágiles” conforme lo estipulado en esta cláusula (en adelante, los “Artículos Frágiles”) por cuanto los mismos tienen mayor probabilidad de sufrir daño, rotura o deterioro.</p>
        <p>7.2. En caso de que los Usuarios decidan la guarda de artículos frágiles las cajas deberán de ser señalizados como tales. Recomendamos a nuestros Usuarios envolver sus Ítems con materiales de embalaje apropiados como pluribol (burbujas) y/o papel especial de embalaje. Asimismo, deberán de rellenar los espacios vacíos con material suave para la prevención de golpes. TODO EN ORDEN se reserva el derecho de rechazar cualquier Ítem que parezca poco resistente o mal embalado para el tránsito o el guardado. En ese caso, podrá exigirle al Usuario comprar suministros especiales y/o sugerir contratar el servicio de embalaje. TODO EN ORDEN no se responsabilizará por daños, rotura o deterioros de Artículos Frágiles.</p>
        <p>7.3. Cualquier Ítem hecho de mármol, granito, piedra, fórmica, acrílico, vidrio, cristal o similar deberá ser previamente embalado por los Usuarios. Eventualmente, TODO EN ORDEN podrá embalarlo en caso que considere necesario. Si estos objetos van incorporados a un mueble (por ejemplo, parte de una mesa, lámpara, etc.) serán desarmados por TODO EN ORDEN previo a su guardado.</p>
        <p>7.4. Muchas cosas del hogar pueden ser consideradas como Artículos Frágiles, tales como vidrio, platos, cerámica, adornos y cristal. Si el Usuario pretende guardar este tipo de objetos, tendrán que estar cuidadosamente embalados.</p>
        <p>7.5. Todos los aparatos electrónicos son Artículos Frágiles, incluidos, entre otros, televisores, computadoras, tablets, teléfonos, impresoras, discos duros y equipos de sonido. Los instrumentos musicales, dispositivos mecánicos y aparatos también son Artículos Frágiles. Dado que no podemos certificar la condición y la funcionalidad interna de los componentes electrónicos, instrumentos musicales, aparatos o dispositivos mecánicos antes de guardarlos, no nos hacemos responsables de cualquier desperfecto, defecto o daño interno mecánico o eléctrico que pueda ocurrir.</p>
        <p>7.6. Las tablas de melamina o madera enchapada también se consideran como Artículos Frágiles ya que están hechos de pequeñas partículas de madera, como el aserrín y astillas de madera que se pegan y se presionan para formar una lámina. Este tipo de maderas son más débiles que otros materiales y muebles como la madera maciza. Estas tablas de madera no se pueden empacar de manera segura para el transporte a menos que se desarmen completamente y se vuelvan a embalar. Por tal razón, consideramos que todos los elementos hechos de este material son frágiles. TODO EN ORDEN no será responsable por el guardado de los artículos que están hechos de madera aglomerada en caso de daño, rotura o deterioro.</p>
        <p>7.7. Algunos materiales poseen características inherentes que hace que se corroan o se degraden con el tiempo o los hace susceptibles a daños por los cambios atmosféricos en la temperatura y la humedad. Este tipo de ítems se catalogan como Artículos Frágiles. TODO EN ORDEN no será responsable por cualquier daño, rotura o deterioro derivados de estas características inherentes de los materiales y los cambios en la temperatura y la humedad, incluido, entre otros, el crecimiento de moho y hongos.</p>
        

      </article>

      <article>
        <Typography ref={refs['Límite de responsabilidad']} variant='h2' color='secondary'></Typography>
        
        <h4>8. Límite de responsabilidad.</h4>
        <p>8.1. TODO EN ORDEN no será responsable por cualquier daño, rotura, hurto o robo de los Ítems salvo caso de dolo o culpa grave. TODO EN ORDEN no será responsable en caso de daño, rotura, hurto o robo de los Ítems ocasionados por casos de fuerza mayor o caso fortuito. Son hechos de fuerza mayor o caso fortuito, los acontecimientos de la naturaleza o hechos humanos respectivamente, ambos de carácter imprevisibles o inevitables, ajenos a TODO EN ORDEN que le imposibiliten cumplir con sus obligaciones total o parcialmente, a pesar de haber actuado con la diligencia debida.</p>
        <p>8.2. Los Usuarios entienden y reconocen que, en caso de ocurrencia de algún supuesto de caso fortuito o fuerza mayor, avería o destrucción de los Ítems por defecto propio de los mismos en forma exclusiva, se entenderá que los mismos son guardados a riesgo del Usuario que invistiere el carácter de propietario de ellos y por consiguiente serán a su cargo las pérdidas, rotura, deterioro y averías que sufran.</p>
        <p>8.3. TODO EN ORDEN se reserva el derecho de abrir e inspeccionar cualquier Ítem en caso de una emergencia, presunta actividad delictiva, sospecha de almacenamiento de productos prohibidos o violación de las reglas de guardado, o en caso de incumplimiento en virtud de este acuerdo.</p>
        <p>8.4. En caso de que TODO EN ORDEN reciba una orden de inspección por parte de una agencia gubernamental y/o autoridad judicial competente, podrá, sin incurrir en ninguna responsabilidad, abrir cualquier sello, candado, cinta o precinto que proteja objetos señalados por la investigación, permitir que se realicen búsquedas entre tus ítems y, si corresponde, el objeto o su contenido podrá ser incautado. En caso de que TODO EN ORDEN reciba una citación, o un oficial de la ley o una agencia gubernamental solicite documentos o información sobre tus cosas, el Usuario acepta y consiente que TODO EN ORDEN podrá proporcionar dicha información o documentos sin incurrir en ningún tipo de responsabilidad.</p>
        <p>8.5. El Usuario declara conocer y aceptar que el Servicio no incluye seguros. Si un Usuario deseara contratar seguros, lo deberá hacer por su cuenta. Cuando un Usuario contrate seguros respecto de los Ítems, deberá exigir a su aseguradora la inclusión de una cláusula por la cual dicha compañía renuncie expresamente a promover acción de repetición y/o cualquiera que pudiera corresponderle contra TODO EN ORDEN ante cualquier hecho de pérdida o daño que pudiera afectar a los bienes asegurados y/o a terceros.</p>

      </article>

      <article>
        <Typography ref={refs['Pago del Servicio']} variant='h2' color='secondary'></Typography>
        
        <h4>9. Pago del Servicio.</h4>
        <p>9.1. El Usuario será responsable por el pago del Plan de Guardado contratado en legal tiempo y forma.</p>
        <p>9.2. El pago será efectuado a través de Mercado Pago y/o cualquier otro medio de pago que las partes acuerden previamente. TODO EN ORDEN cobrará automáticamente de la tarjeta de crédito registrada por el Usuario al inicio de cada ciclo mensual y también podremos cobrar automáticamente los cargos pendientes de la tarjeta. TODO EN ORDEN no será responsable de los cargos que cobre el banco o el emisor de la tarjeta de crédito del Usuario relacionados con los cargos efectuados con éxito.</p>
        <p>9.3. Ante cualquier cambio en la metodología del pago el Usuario deberá informarla previamente. La falta de información exime de responsabilidad a TODO EN ORDEN.</p>
        <p>9.4. En caso de falta de pago, la mora será automática y sin necesidad de interpelación previa, devengando un interés mensual equivalente a dos (2) veces la tasa Activa del Banco Nación para sus operaciones de descuento a treinta (30) días. TODO EN ORDEN se reserva el derecho a la retención de los Ítems hasta la cancelación completa y efectiva de cualquier deuda o pago pendiente del Usuario.</p>
        <p>9.5. TODO EN ORDEN puede ajustar el precio de la cuota, los montos y otras tarifas y/o cargos del Servicio, a partir del mes siguiente a la notificación al Usuario</p>

      </article>

      <article>
        <Typography ref={refs['Devolución parcial – Terminación del Servicio']} variant='h2' color='secondary'></Typography>
        
        <h4>10. Devolución parcial – Terminación del Servicio.</h4>
        <p>10.1. El Usuario podrá en cualquier momento solicitar la devolución parcial de uno o varios Ítems. Para ello, deberá hacerlo desde su Cuenta Personal identificando los Ítems que solicita a los fines de coordinar la entrega en el lugar y fecha que se acuerde conjuntamente. TODO EN ORDEN informará al Usuario el precio del servicio.</p>
        <p>10.2. Asimismo el Usuario podrá requerir la devolución de la totalidad de los Ítems, y por ende, la finalización de su Plan de Guardado. Para ello, deberá hacerlo desde su Cuenta Personal identificando los Ítems que solicita a los fines de coordinar la entrega en la fecha en el lugar y fecha que se acuerde conjuntamente. TODO EN ORDEN informará al Usuario el precio del servicio. En dicho caso se considerará finalizado el Servicio, y se liberará el espacio reservado para los Ítems del Usuario, el cual podrá ser utilizado por terceros.</p>
        <p>10.3. En caso que el Usuario no formule ningún reclamo u observación respecto del estado de los Ítems devueltos a su requerimiento dentro de las veinticuatro (24) horas de que fueran recibidos, se presumirá que existe conformidad de parte del Usuario, y no tendrá derecho a formular reclamo alguno. En caso de reclamo u observación de parte del Usuario, TODO EN ORDEN deberá dar respuesta dentro de los cinco (5) días de recibida la misma. A tales fines, se tendrá en consideración lo que surja del Catálogo. Las partes se comprometen a buscar soluciones amigables en caso de conflicto, teniendo en cuenta que los Ítems pueden sufrir deterioros por el mero transcurso del tiempo.</p>
        <p>10.4. El Usuario podrá cancelar su suscripción en cualquier momento notificando fehacientemente a TODO EN ORDEN. Si la fecha de finalización es posterior al pago inicial y previo al final de tu ciclo de pago, no habrá reembolsos. Se cobrará por el ciclo completo ya que el pago es a mes adelantado. Si la fecha de finalización es menor al plazo mínimo de suscripción (1 mes), el Usuario deberá pagar por el mes completo y no se realizarán reembolsos por los saldos prepagos. Si se cancela la suscripción antes de la mudanza inicial, te reembolsaremos la mitad del pago inicial debido a tareas previas que realizaremos. Si se cancela tu suscripción luego de enviarte los materiales de embalaje y previo a la mudanza, deberás pagar por el traslado de los materiales y por los materiales si es que no deseas conservarlos.</p>

      </article>

      <article>
        <Typography ref={refs['Propiedad Intelectual']} variant='h2' color='secondary'></Typography>
        
        <h4>11 Propiedad Intelectual.</h4>
        <p>TODO EN ORDEN es el único titular exclusivo y/o licenciatario de todos los derechos intelectuales e industriales sobre las marcas, logos, desarrollos, software y demás contenidos de cualquier tipo relacionadas con el Servicio y nuestra web (www.todoenorden.com), su contenido de texto, información, gráficos, nombre, imágenes, logos, marcas, software, bases de datos, diseños, APIs, arquitectura funcional y cualquier otro material. En ningún caso se entenderá que el Usuario tiene algún derecho sobre los mismos.</p>

      </article>

      <article>
        <Typography ref={refs['Protección de datos personales']} variant='h2' color='secondary'></Typography>
        
        <h4>12. Protección de datos personales.</h4>
        <p>12.1. En TODO EN ORDEN asumimos el compromiso para la protección de los datos personales de nuestros Usuarios. En tal sentido, garantizamos el tratamiento de sus datos conforme a los estándares de excelencia de la industria, y en cumplimiento de la normativa de protección de datos personales, en particular, la Ley de Protección de Datos Personales N° 25.326 (en adelante, Ley N° 25.326) y sus normas complementarias.</p>
        <p>12.2. Los datos de nuestros Usuarios podrán ser compartidos con nuestras filiales, entidades asociadas y empresas vinculadas para el cumplimiento de los fines anteriormente descritos. También podremos compartir los datos de nuestros Usuarios a proveedores de servicios que presten servicios en nuestro nombre de conformidad con nuestras instrucciones, como así también aliados comerciales en la prestación del Servicio.</p>
        <p>12.3. El Usuario tiene la facultad de ejercer el derecho de acceso, rectificación, actualización y supresión de sus datos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. A los fines de ejercer sus derechos podrá contactarse a soporte@todoenorden.com indicando el motivo de su requerimiento. TODO EN ORDEN deberá dar respuesta dentro de los cinco (5) días hábiles de la recepción. La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
https://www.argentina.gob.ar/aaip/datospersonales/derechos.</p>


      </article>

      <article>
        <Typography ref={refs['Cesión']} variant='h2' color='secondary'></Typography>
        
        <h4>13 Cesión.</h4>
        <p>El Usuario no podrá en ningún caso, y por ninguna circunstancia, causa o motivo, ceder, transferir o asignar los derechos y obligaciones que surgen de los Términos y Condiciones.</p>

      </article>

      <article>
        <Typography ref={refs['Revocación de la aceptación']} variant='h2' color='secondary'></Typography>
        
        <h4>14 Revocación de la aceptación.</h4>
        <p>El Usuario tiene el derecho de revocar su aceptación de los Términos y Condiciones dentro de los diez (10) días corridos computados a partir de la confirmación del alta del Usuario y aceptación de los Términos y Condiciones. A tal efecto, el Usuario deberá notificar a TODO EN ORDEN de manera fehaciente mediante los medios de comunicación establecidos.</p>

      </article>

      <article>
        <Typography ref={refs['Acuerdo Total']} variant='h2' color='secondary'></Typography>
        
        <h4>15. Acuerdo Total.</h4>
        <p>15.1. Los presentes Términos y Condiciones forman un acuerdo total que regirán la relación entre el Usuario y TODO EN ORDEN.</p>
        <p>15.2. En caso de que cualquier disposición de estos Términos y Condiciones, o la aplicación de cualquier disposición a cualquier Usuario o bajo ciertas circunstancias fuera determinada, en todo o en parte, inválida, ilegal, nula o no ejecutable, el resto de los Términos y Condiciones, y la aplicación de dicha disposición a Usuarios o circunstancias diferentes de aquélla que fuera considerada inválida, ilegal, nula o no ejecutable no será desvirtuada o de otra manera afectada, y continuará siendo válido y ejecutable en toda la extensión que sea permitido por la ley.</p>

      </article>

      <article>
        <Typography ref={refs['Modificación y/o actualización']} variant='h2' color='secondary'></Typography>
        
        <h4>16. Modificación y/o actualización.</h4>
        <p>16.1. TODO EN ORDEN se reserva el derecho de realizar modificaciones y/o actualizaciones de los Términos y Condiciones en cualquier momento. En dichos casos se notificará al Usuario publicando una versión actualizada de estos Términos y Condiciones con expresión de la fecha de la última modificación.</p>
        <p>16.2. Si el Usuario no estuviera de acuerdo con la actualización de los Términos y Condiciones, tendrá derecho a dar de baja el Servicio, sin que ello pudiera generar a su favor derecho a reclamo o compensación alguna. La continuidad del Servicio con posterioridad a la publicación de las modificaciones a los Términos y Condiciones serán considerados como aceptación de los mismos.</p>

      </article>

      <article>
        <Typography ref={refs['Ley Aplicable y Jurisdicción']} variant='h2' color='secondary'></Typography>
        
        <h4>17. Ley Aplicable y Jurisdicción.</h4>
        <p>Los Términos y Condiciones estarán regidos por las leyes vigentes en la República Argentina, con exclusión de toda norma que remita a la aplicación de una ley extranjera. Ante cualquier controversia respecto de la interpretación o cumplimiento de los Términos y Condiciones, serán competentes los Juzgados Nacionales Ordinarios de la Ciudad Autónoma de Buenos Aires, renunciando expresamente a cualquier otro fuero y/o jurisdicción que pudiera corresponderles.</p>

      </article>

        </Box>
      </Grid>
    </Container>
  )
}

export default Tyc
