import React, { useState, useEffect } from 'react'

import { useHistory } from "react-router-dom";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Hidden from '@material-ui/core/Hidden';

import grey from '@material-ui/core/colors/grey';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

import { updatePlan, selectPlans, cachePlans } from "features/mainSlice";
import { register } from 'serviceWorker';

import { formatCurrency } from 'utils';

const Image = ({ src }) => (
  <div style={{
    width: '65%',
    height: 315,
    backgroundImage: `url('${src}')`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  }} />
);



const Content = (props) => (
  <Box container style={{
    paddingLeft: 30,
    paddingTop: 40,
    paddingBottom: 40,
    transition: props.create(['opacity'], {
      delay: 300
    }),
    opacity: props.open === props.name ? 1 : 0,
    height: 610
  }}
  display="flex"
  flexDirection="column"
  justifyContent="space-between"
  >
    <Grid container spacing={2}>
      {
      /*
      <Grid item md={7}>
        <Typography variant='h2' color="textSecondary">{props.price} / mes</Typography>
      </Grid>
      
      */
      }
      <Grid item md={5}>
        <Button onClick={props.onHire} variant="contained" color="primary" size='medium'>
          Contratar
        </Button>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item md={7}>
        <Box pb={1}>
          <Typography variant="subtitle1" color="secondary">Referencias</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">{props.reference}</Typography>
      </Grid>
      <Grid item md={5} style={{
        paddingRight: 30
      }}>
        <Box pb={1}>
          <Typography variant="subtitle1" color="secondary">Incluye</Typography>
        </Box>
        {props.includes.map((txt, i) => <Typography key={i} variant="body2" color="textSecondary">{txt}</Typography>)}
      </Grid>
    </Grid>
    <Box pr={4}>
      <Grid container justify='center' alignItems='center' style={{
        overflow: 'hidden',
        backgroundColor: props.main,
        borderRadius: 15,
        maxWidth: 800,
        paddingTop: 30,
        paddingBottom: 30,
      }}>
        <Grid item container xs={3} justify='center'>
          <InfoIcon style={{
            fontSize: 50
          }} />
        </Grid>
        <Grid item xs={9} style={{
          paddingRight: 30
        }}>
          <Typography variant="body2" color="textPrimary">Dependiendo de la ubicación y detalles de tu locación se pueden sumar costos extra. Las ilustraciones son a modo de referencia.</Typography>
        </Grid>
      </Grid>
    </Box>
  </Box>
);



const NameBar = (props) => (
  <div style={{
    transition: props.transitions.create(['opacity']),
    opacity: props.open && !props.itsMe ? 1 : 0,
    maxWidth: '100%',
    maxHeight: '100%'
  }}>
    <div style={{
      width: '100%',
      height: '100%',
      display: props.open && !props.itsMe ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography style={{
        transition: props.transitions.create(['color']),
        whiteSpace: 'nowrap',
        fontWeight: 400,
        transform: 'rotate(-90deg)'
      }} variant="h2" color={props.green ? "textPrimary" : "secondary"}>
        {props.name}
      </Typography>
    </div>
  </div>
);



const Preview = (props) => (
  <Grid item style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 100%',
    position: 'relative',
    paddingTop: 40,
    transition: props.transitions.create(['opacity', 'max-width']),
    maxWidth: !props.open || !props.itsMe ? 'none' : '200px',
    opacity: !props.open || props.open === props.name ? 1 : 0
  }}>
    <Typography
      style={{ fontFamily: 'ProximaNova' }}
      variant="subtitle1"
      color="secondary"
    >
      Hasta
    </Typography>
    <Typography style={{
      whiteSpace: 'nowrap',
      fontWeight: 'normal'
    }} variant="h2" color="secondary">{props.sqm} m<sup><small>3</small></sup></Typography>
    <Image src={props.image} />
    <Box
      position="absolute"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      bottom={0}
      height={170}
      style={{ transition: props.transitions.create(['height'])}}
    >
      <Typography component={Box}
        style={{
          whiteSpace: 'nowrap',
          fontWeight: 400,
          position: 'absolute'
        }}
        color="secondary"
        bottom={70}
        variant="h2"
      >
        {props.name}
      </Typography>
    </Box>
    <Box
      bgcolor="secondary.main"
      position="absolute"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      bottom={0}
      height={!props.open ? 170 : 0}
      style={{ transition: props.transitions.create(['height'])}}
    >
      <Typography component={Box}
        style={{
          whiteSpace: 'nowrap',
          fontWeight: 400,
          position: 'absolute'
        }}
        bottom={70}
        variant="h2"
        color="textPrimary"
      >
        {props.name}
      </Typography>
    </Box>
  </Grid>
);


const Card = ({ image, sqm, name, onClick, open, reference, includes, price, green, onHire }) => {
  const theme = useTheme();
  const headerTextColorMobile = (open && green) ? "textPrimary" : "secondary";
  const itsMe = open === name;
  const small = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {/* MOBILE */}
      <Hidden lgUp>
        <Grid item container
          alignItems='center'
          onClick={() => onClick(name)}
          style={{
            marginTop: open ? 0 : 20,
            padding: 30,
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: 15,
            backgroundColor: (open && green) ? theme.palette.secondary.main : theme.palette.bg,
            overflow: 'hidden',
            transition: theme.transitions.create(['max-height', 'margin-top', 'background-color']),
          }}
        >
          <Grid item container
            justify='space-between'
            alignItems='center'
            style={{
              minHeight: 40
            }}
          >
            <Grid item>
              <Typography
                component={Box}
                style={{
                  whiteSpace: 'nowrap',
                  fontWeight: 400,
                  transition: theme.transitions.create(['color']),
                }}
                variant="h2"
                color={!open ? "textSecondary" : itsMe ? "textSecondary" : headerTextColorMobile}
                fontSize={{xs: 25}}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component={Box}
                variant="subtitle1"
                color={headerTextColorMobile}
                fontSize={{xs: 17}}
              >
                Hasta {sqm} m<sup><small>3</small></sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={3}
            style={{
              overflow: 'hidden',
              transition: theme.transitions.create(['max-height']),
              maxHeight: (!open || !itsMe) ? '0px' : '700px',
            }}
          >
            <Grid item xs={12}>
              <br />
              <Typography
                component={Box}
                pb={1}
                variant="subtitle1"
                color="secondary"
              >
                Referencias
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {reference}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component={Box}
                pb={1}
                variant="subtitle1"
                color="secondary"
              >
                Incluye
              </Typography>
              {includes.map((txt, i) =>
                <Typography key={i} variant="body2" color="textSecondary">{txt}</Typography>
              )}
            </Grid>
            <Box display="flex" justifyContent="center" width="100%">
              <Grid
                item container
                direction='row'
                justify='center'
                alignItems='center'
                style={{
                  display:'flex',
                  flexDirection:'row',
                  overflow: 'hidden',
                  backgroundColor: theme.palette.bg,
                  border: `solid 2px ${theme.palette.secondary.main}`,
                  borderRadius: 15,
                  padding: 20,
                }}
              >
                <Box flexGrow={0} flexBasis="60px">
                  <InfoIcon color='secondary' style={{ fontSize: 40 }} />
                </Box>
                <Box flexGrow={1} flexBasis={0}>
                  <Typography variant="body2" color="secondary">Dependiendo de la ubicación y detalles de tu locación se pueden sumar costos extra. Las ilustraciones son a modo de referencia.</Typography>
                </Box>
              </Grid>
            </Box>
            <Grid item container justify='space-between' alignItems='center'>
              <Grid item>
                <Button
                  component={Box}
                  onClick={onHire}
                  variant="contained"
                  color="primary"
                  size="large"
                  fontSize={16}
                  borderRadius={10}
                  py={1}
                >
                  Contratar
                </Button>
              </Grid>
              {
              /*
              <Grid item>
                <Box fontFamily="Montserrat" fontSize={{xs: 26}} color="text.secondary" align='center'>{price}</Box>
                <Box fontFamily="Montserrat" fontSize={{xs: 16}} color="text.secondary" align='center'>MENSUAL</Box>
              </Grid>
              */
              }
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {/* DESKTOP */}
      <Hidden mdDown>
        <Grid item container
          onClick={() => onClick(name)}
          style={{
            borderRadius: 15,
            backgroundColor: (open && green) ? theme.palette.secondary.main : theme.palette.bg,
            minWidth: 95,
            overflow: 'hidden',
            flexWrap: 'nowrap',
            transition: theme.transitions.create(['flex', 'margin-right', 'background-color']),
            flex: !open || open === name ? 1 : 0,
            marginRight: !open ? 20 : 0,
            position: 'relative'
          }}
        >
          <NameBar
            transitions={theme.transitions}
            itsMe={itsMe}
            name={name}
            open={open}
            green={green}
          />
          <Preview
            transitions={theme.transitions}
            itsMe={itsMe}
            image={image}
            sqm={sqm}
            name={name}
            open={open}
          />
          <Divider
            style={{
              backgroundColor: theme.palette.secondary.main,
              width: 2,
              marginLeft: 2,
            }}
            orientation='vertical'
          />
          <Content
            create={theme.transitions.create}
            main={theme.palette.secondary.main}
            name={name}
            open={open}
            reference={reference}
            includes={includes}
            price={price}
            onHire={onHire}
          />
        </Grid>
      </Hidden>
    </>
  )
};


const Hire = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState('');
  const [even, setEven] = useState(false);
  const cachedPlans = useSelector(selectPlans);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if(cachedPlans && (Date.now() - cachedPlans.ts) < 86400000){
      setPlans(cachedPlans.value);
      return;
    }
    let cancelled = false;
    const getPlans = async () => {
      const response = await fetch(`${process.env.REACT_APP_API}/plans`);
      //const response = await fetch(`http://todoenorden.com/api/plans`);
      const result = await response.json();
      if(cancelled) return;
      setPlans(result);
      dispatch(cachePlans(result))
    }
    getPlans();
    return () => {
      cancelled = true;
    }
  }, []);

  return (
    <Container component={Box} maxWidth={false} bgcolor='grey.100' py={5}>
      <Container maxWidth='lg'>
        <Typography variant='h2' color='textSecondary' align='center' style={{ padding: 20, marginBottom: medium ? 40 : 0 }}>
          Encontrá el plan perfecto para vos
        </Typography>
        <Grid container direction={medium ? 'row' : 'column'}>
          {plans.slice(0, plans.length - 1).map((plan, i) =>
            <Card
              key={plan.id}
              image={plan.image.split('/').reverse()[0]}
              sqm={plan.volume}
              name={`Plan ${plan.name}`}
              onClick={name => {
                setOpen(name);
                setEven(i % 2);
              }}
              open={open}
              reference={plan.description}
              includes={plan.include.split('\n')}
              price={formatCurrency(plan.price)}
              green={i % 2 ? !even : even}
              onHire={() => {
                dispatch(updatePlan({
                  plan: plan.id,
                  planInfo: plan,
                }));
                history.push('/register');
              }}
            />
          )}
        </Grid>
      </Container>
    </Container>
  )
}

export default Hire
