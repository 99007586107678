import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { Link } from "react-router-dom";

import { useSelector } from 'react-redux';
import { selectUser } from "features/mainSlice";

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logo from 'img/logo.svg';

const useStyles = makeStyles(theme => ({
  main:{
    display: 'flex',
    alignItems: 'center',
    padding: '20px 26px 20px 5px',
    [theme.breakpoints.up('sm')]:{
      padding: '30px 80px 30px 80px'
    },
    [theme.breakpoints.up('lg')]:{
      padding: '30px 150px 30px 150px'
    },
    [theme.breakpoints.up('xl')]:{
      padding: '30px 150px 30px 150px'
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    [theme.breakpoints.up('sm')]:{
      height: 40,
    },
    '& a, & img': {
      height: '100%',
    },
  },
  menuIcon:{
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]:{
      fontSize: theme.typography.pxToRem(40),
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    "&>*+*": {
      marginLeft: 80,
    },
  },
  menuMobile:{
    backgroundColor: theme.palette.primary.main,
  },
}));

const HeaderMobile = ({setMenuOpen, redirect, ...props}) => {
  const classes = useStyles();
  const {name} = useSelector(selectUser);
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      disableGutters
      component={Box}
      bgcolor='primary.main'
      {...props}
    >
      <Container
        classes={{root: classes.main}}
        component={Box}
        maxWidth='xl'
        disableGutters
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        py={2}
      >
        <Box
          display='flex'
          alignItems='center'
          height={{xs:'20px', sm:'40px'}}
        >
          <Hidden mdUp>
            <IconButton
              color='primary'
              onClick={setMenuOpen}
            >
              <MenuIcon classes={{root: classes.menuIcon}}/>
            </IconButton>
          </Hidden>
          <Link to="/">
            <Box
              component='img'
              src={logo}
              alt="logo"
              height={{xs:20, sm:40}}
            />
          </Link>
        </Box>
        <Box>
          <Hidden smDown>
            <Link to='/nosotros'>
              <Box component={Button} size='medium' mr={10}>
                Nosotros
              </Box>
            </Link>
            <Link to='/#planes'>
              <Box component={Button} size='medium' mr={10}>
                Planes
              </Box>
            </Link>
          </Hidden>
          <Box {...(redirect?
            {
              component: 'a',
              href: '/user/catalogue'
            }:
            {
              component: Link,
              to: !name ? '/login' : '/#planes'
            }
          )}>
            <Box
              component={Button}
              variant="contained"
              bgcolor="text.primary"
              color="primary.main"
              size='medium'
            >
              <Box minWidth={120}>
                {name || 'Mi cuenta'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  )
}

const Component = ({redirect}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <HeaderMobile redirect={redirect} style={{position:'fixed', zIndex:20}} setMenuOpen={setMenuOpen}/>
      <HeaderMobile redirect={redirect} style={{opacity:0}} setMenuOpen={setMenuOpen}/>
      <Drawer
        anchor='top'
        open={Boolean(menuOpen)}
        onClose={()=>setMenuOpen(null)}
        style={{zIndex:100}}
      >
        <Link to='/nosotros'>
          <MenuItem>Nosotros</MenuItem>
        </Link>
        <Divider />
        <Link to='/#planes'>
          <MenuItem>Planes</MenuItem>
        </Link>
      </Drawer>
    </>
  )
}

export default Component


// export default HeaderMobile
