import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import checkmark from 'img/checkmark.svg';
import error from 'img/error.svg';

import { useSnackbar } from 'notistack';

const VARIANTS = {
  default: {
    bgcolor: 'secondary.main',
    src: checkmark,
  },
  error: {
    bgcolor: 'error.main',
    src: error,
  },
  success: {
    bgcolor: 'secondary.main',
    src: checkmark,
  },
  //TODO
  warning: {
    bgcolor: 'secondary.main',
    src: checkmark,
  },
  //TODO
  info: {
    bgcolor: 'secondary.main',
    src: checkmark,
  },
}

const Message = ({title, body, variant="default", ...props}) => {
  const {bgcolor, src} = VARIANTS[variant];
  return (
    <Box
      component='div'
      bgcolor={bgcolor}
      borderRadius={15}
      display='flex'
      px={5} py={3}
      boxShadow='0px 0px 10px #333;'
      {...props}
    >
      <Box component='img' src={src} mr={5}/>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Box component={Typography} fontWeight='bold'>{title}</Box>
        <Box component={Typography}>{body}</Box>
      </Box>
    </Box>
  )
}

export default Message

export const useMessage = () =>{
  const {enqueueSnackbar, ...rest} = useSnackbar();
  const enqueueMessage = ({title, body, variant, ...params}) => {
    return enqueueSnackbar(null, {
      content: <div>
        <Message title={title} body={body} variant={variant} {...params}/>
      </div>
    })
  }
  return ({enqueueMessage, ...rest})
}