import React from 'react';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import SwitchMU from '@material-ui/core/Switch';
import CheckboxMU from '@material-ui/core/Checkbox';
import SelectMU from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';

import NumberFormat from 'react-number-format';

import { makeStyles , useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ReactRecaptcha from 'react-recaptcha';

export const Text = ({mp, ...props}) => {
  const [field, {touched, error}] = useField(props);

  return (
    <TextField error={touched && !!error}
      helperText={touched?error:''}
      {...field}
      {...props}
      {...mp?{inputProps:{name:undefined, id: props.name, 'data-checkout': props.name}}:{}}
    />
  )
}

export const Select = ({mp, label, ...props}) =>{
  const [field, {touched, error}] = useField(props);
  return (
    <Box component={FormControl} width='100%'>
      <InputLabel id={props.name}>{label}</InputLabel>
      <SelectMU
        labelId={props.name}
        error={touched && !!error}
        {...field}
        {...props}
        {...mp?{
          inputProps:{name:undefined, id: props.name, 'data-checkout': props.name},
          onChange: e => field.onChange({
              target:{
                name: props.name,
                value: e.target.value,
              }
            })
          }:{}
        }
      />
      <FormHelperText error>{touched?error:''}</FormHelperText>
    </Box>
  )
}

export const Date = ({mp, ...props}) => {
  const [field, {touched, error}] = useField(props);
  return <DatePicker
    error={touched && !!error}
    helperText={touched ? error : ''}
    format='DD MMMM'
    {...field}
    onChange={
      date => field.onChange({
        target:{
          name: field.name,
          value: date,
        }
      })
    }
    {...props}
    {...mp?{inputProps:{name:undefined, id: props.name, 'data-checkout': props.name}}:{}}
  />
}

const useSwitchStyles = makeStyles((theme) => {
const smWidth = 40;
const mdWidth = 60;
const getWidth = ({small = false}) => (small?smWidth:mdWidth);
return ({
    root: {
      width: props => getWidth(props),
      height: props => getWidth(props)/2,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: props => getWidth(props) * 0.05,
      color: theme.palette.common.white,
      '&$checked': {
        transform:`translateX(100%)`,
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    thumb: {
      width: props => getWidth(props) * 0.4,
      height: props => getWidth(props) * 0.4,
      boxShadow: 'none',
    },
    track: {
      border: `2px solid ${theme.palette.grey[500]}`,
      borderRadius: props => getWidth(props) / 2,
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
    },
    checked: {},
  })
}
);

export const Switch = ({mp, ...props}) => {
  const [field] = useField(props);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useSwitchStyles({small: sm, ...props})
  return <SwitchMU
    classes={classes}
    {...field}
    {...props}
    {...mp?{inputProps:{name:undefined, id: props.name, 'data-checkout': props.name}}:{}}
  />
}

export const Checkbox = ({mp, offColor="#9e9e9e", ...props}) => {
  const [field] = useField(props);
  return <CheckboxMU
    {...field}
    {...props}
    style={ field.value ? props.style : {color: offColor}}
    {...mp?{inputProps:{name:undefined, id: props.name, 'data-checkout': props.name}}:{}}
  />
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name || other.id,
            value: values.value,
          },
        });
      }}
    />
  );
}

export const FormattedNumber = ({mp, format, mask, children, adornment, onChange, ...props}) => {
const [field, {touched, error}] = useField(props);
  return (
    <TextField
      error={touched && !!error}
      helperText={touched? error : ''}
      inputProps={{
        format,
        mask,
        name: undefined,
        id: props.name,
        'data-checkout': props.name
      }}
      InputProps={{
        inputComponent: NumberFormatCustom,
        startAdornment: adornment,
      }}
      {...field}
      onChange={e => {
        if(onChange) onChange(e);
        field.onChange(e);
      }}
      {...props}
    />
  )
}

export const ExpirationDate = (props) => {
  const [{value='', name}] = useField(props);
  return (
    <>
      <FormattedNumber mp
        format="##/##"
        placeholder="MM/YY"
        mask={['M', 'M', 'Y', 'Y']}
        {...props}
      />
      <input type="hidden" id={`${name}Month`} data-checkout={`${name}Month`} value={value.slice(0,2)}/>
      <input type="hidden" id={`${name}Year`} data-checkout={`${name}Year`} value={value.slice(2)}/>
    </>
  )
}


export const Recaptcha = (props) => {
  const [input , meta , {setValue}] = useField(props);
  return (
    <ReactRecaptcha
      sitekey={process.env.REACT_APP_CAPTCHA}
      theme="dark"
      hl="es"
      render="explicit"
      onloadCallback={() => {}}
      verifyCallback={response => {
        setValue(response)
      }}
      {...props}
    />
  )
}