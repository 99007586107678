import React from 'react'

const Marker = ({id, top = -100}) => {
  return (
    <div style={{position: "relative"}}>
      <div id={id} style={{position: "absolute", top}}/>
    </div>
  )
}

export default Marker
