import React from 'react'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { Text, Phone, Date, Recaptcha } from 'components/form';
import {useMessage} from 'components/message';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {adapter, endpoint} from './config';

const useStyles = makeStyles(theme => ({
  button:{
    marginTop: '20px',
    [theme.breakpoints.up('sm')]:{
      marginTop: '25px'
    },
  },
}));

const AppointmentForm = ({onSent}) => {
  const { enqueueMessage } = useMessage();
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        date: null,
        //recaptcha: null,
      }}
      validationSchema={Yup.object({
        name: Yup.string(),
        phone: Yup.number()
          .typeError('Debe completar con un número')
          .required('Debe completar este campo'),
        /*
        date: Yup.object()
          .nullable()
          .required('Debe completar este campo'),
        recaptcha: Yup.string()
          .nullable()
          .required()
        */
      })}

      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const response = await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(adapter(values)),
          headers: {
            'Content-Type': `application/json;charset=UTF-8`,
            accept: `application/json, text/plain, */*`,
          },
        });

        if(!response.ok){
          enqueueMessage({
            title: 'Algo salió mal!',
            body: 'No hemos podido programar la cita',
            variant: 'error',
          })
          console.log(response);
        }else{
          onSent();
        }
        setSubmitting(false);
      }}
    >
    {({isSubmitting}) => {
    return (
      <Form>
        <Grid container spacing={small?3:6}>
          <Grid item container justify={small?'center':'flex-end'} xs={12} md={6}>
            <Text name='name' label='Nombre'/>
          </Grid>
          <Grid item  container justify={small?'center':'flex-start'} xs={12} md={6}>
            <Text name='phone' label='Teléfono *' type='tel'/>
          </Grid>
          {
          /*
          <Grid item container justify={small?'center':'flex-end'} xs={12} md={6}>
            <Date name='date' label='Día *' disablePast/>
          </Grid>
          <Grid item  container justify={small?'center':'flex-start'} xs={12} md={6}>
            <Recaptcha name='recaptcha'/>
          </Grid>
          */
          }
          <Box component={Grid} container justify="center" alignItems='center' height={83}>
          {isSubmitting
            ?
            <CircularProgress />
            :
            <Button
              type="submit"
              classes={{root:classes.button}}
              variant="contained"
              color="primary"
              size='large'
            >
              Cotizá tu baulera ahora
            </Button>
          }
          </Box>
        </Grid>
      </Form>
    )}}
    </Formik>
  )
}

export default AppointmentForm
