import React, {useEffect} from 'react'
import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Text, Phone, Date, Switch, Checkbox } from 'components/form';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useMessage } from 'components/message';
import { useHistory } from "react-router-dom";

import { adapterIn, adapterOut, endpoint } from './config';

import { selectRegistrationSecret, setLogin } from 'features/mainSlice';

import { Formik, Form, useFormikContext, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const Validate = () => {
  // const registrationSecret = useSelector(selectRegistrationSecret);
  const dispatch = useDispatch();
  const { enqueueMessage } = useMessage();
  const history = useHistory();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  // useEffect(()=>{
  //   if(!registrationSecret) history.replace('/');
  // }, [registrationSecret, history])
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}

      validationSchema={Yup.object({
        username: Yup.string().required('Falta completar este campo'),
        password: Yup.string().min(6, 'Contraseña incorrecta').required('Falta completar este campo'),
      })}

       onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const response = await fetch(endpoint, {
          method: "POST",
          body: adapterOut(values)
        });
        if(!response.ok){
          enqueueMessage({
            title: 'Algo salió mal!',
            body: 'No pudimos validar su usuario y contraseña',
            variant: 'error',
          })
          console.log(response);
        }else{
          const result = adapterIn(await response.json());
          dispatch(setLogin(result));

          const body = {
            headers: {
              authorization: `Bearer ${result.access_token}`
            }
          }

          const [pending, done] = await Promise.all([
            fetch(`${process.env.REACT_APP_API}/transaction/pending`, body).then(res=>res.json()),
            fetch(`${process.env.REACT_APP_API}/transaction/done`, body).then(res=>res.json())
          ]);

          setSubmitting(false);
          if(pending.length || done.length){
            window.location.href = '/user/catalogue';
          } else {
            history.push('/');
          }
        }
      }}
    >
    {({ isSubmitting }) =>
      <Box component={Form} bgcolor='grey.100' py={6}>
        <Backdrop open={isSubmitting} style={{position:'absolute', zIndex:5}}>
          <CircularProgress/>
        </Backdrop>
        <Grid container justify='center'>
          <Box component={Grid}
            py={3} mb={6} px={2}
            bgcolor={{ md: theme.palette.bg }}
            container style={{
              maxWidth: 700,
              borderRadius: 15,
            }}
            justify='center'
            spacing={3}
          >
            <Grid container justify='center'>
            <Hidden smDown>
              <Box
                component={Typography}
                variant='subtitle1'
                color='text.secondary'
                align='center'
                gutterBottom
              >
                  Bienvenidx nuevamente!
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box
                component={Typography}
                fontWeight='bold'
                variant='h2'
                color='text.secondary'
                align='center'
                gutterBottom
              >
                  Bienvenidx nuevamente!
              </Box>
            </Hidden>
          </Grid>
            <Box
              component={Grid}
              item container
              xs={12}
              justify='center'
              direction='column'
            >
              <Typography variant='subtitle1' color='textSecondary' align='center' gutterBottom>
                Acceso a tu cuenta
              </Typography>
            </Box>
            <Grid item container xs={12} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='username' label='Email' />
              </Grid>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text type='password' fullWidth color='secondary' name='password' label='Contraseña'/>
              </Grid>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <Button
                type='submit'
                variant="contained"
                color="primary"
                size={sm?'large':'medium'}
              >
                Enviar
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    }
    </Formik>
  )
}

export default Validate
