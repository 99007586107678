import { configureStore } from '@reduxjs/toolkit';
import mainReducer from '../features/mainSlice';

const preloadedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}

const store = configureStore({
  reducer: {
    main: mainReducer,
  },
  preloadedState
});

store.subscribe(() => {
  const { main:{ plan, ...main}} = store.getState();
  // console.log(plan)
  if(main.login) {
    localStorage.setItem('user', JSON.stringify(main.login.user));
    localStorage.setItem('token', main.login.access_token);
  }
  localStorage.setItem('reduxState', JSON.stringify({main}))
})

export default store;