import { createMuiTheme } from '@material-ui/core/styles';

/// FONTS
import ProximaNovaRegular from 'fonts/Mark Simonson - Proxima Nova Regular.otf'
import ProximaNovaThin from 'fonts/Mark Simonson - Proxima Nova Thin.otf'
import MontserratBold from 'fonts/Montserrat-Bold.ttf'
import MontserratLight from 'fonts/Montserrat-Light.ttf'
import MontserratRegular from 'fonts/Montserrat-Regular.ttf'

const proximaNovaRegular = {
  fontFamily: 'ProximaNova',
  fontWeight: 'normal',
  src: `url('${ProximaNovaRegular}')`,
}
const proximaNovaThin = {
  fontFamily: 'ProximaNova',
  fontWeight: 300,
  src: `url('${ProximaNovaThin}')`,
}
const montserratBold = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  src: `url('${MontserratBold}')`,
}
const montserratLight = {
  fontFamily: 'Montserrat',
  fontWeight: 300,
  src: `url('${MontserratLight}')`,
}
const montserratRegular = {
  fontFamily: 'Montserrat',
  fontWeight: 'normal',
  src: `url('${MontserratRegular}')`,
}

const baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ee4036',
    },
    secondary: {
      main: '#4fbfb5',
      contrastText: '#ffffff',
    },
    text: {
      primary:'#ffffff',
      secondary:'#282561',
    },
    bg: '#f6ede7', // this color is also used in index.css
  },
});

const theme = createMuiTheme({
  ...baseTheme,
  typography: {
    fontFamily: 'Montserrat, ProximaNova',
    h1:{
      fontSize: 26,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: 43,
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: 50,
      },
    },
    h2:{
      fontWeight: '500',
      fontSize: 22,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: 33,
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: 40,
      },
    },
    subtitle1:{
      lineHeight:'normal',
      fontSize: 18,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: 24,
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: 26,
      },
    },
    body1:{
      fontSize: 12,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: 18,
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
    },
    body2:{
      fontFamily: 'ProximaNova',
      fontSize: 16,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      [baseTheme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body:{
          backgroundColor: '#fff'
        },
        '@font-face': [
          proximaNovaRegular,
          proximaNovaThin,
          montserratBold,
          montserratLight,
          montserratRegular,
        ],
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'ProximaNova',
        borderRadius: 10,
        fontSize: 12,
        padding: '4px 30px',
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: 20,
        }
      },
      label: {
        textTransform: 'none',
      },
      containedSizeLarge:{
        padding: '4px 30px',
        borderRadius: 10,
        fontSize: 16,
        [baseTheme.breakpoints.up('sm')]: {
          fontSize: 26,
        }
      }
    },
    MuiIconButton:{
      colorPrimary:{
        color: baseTheme.palette.text.primary,
      },
      // label:{
      //   backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
      //   backgroundSize: '16px 16px',
      //   backgroundPosition: '5px 5px',
      //   backgroundRepeat: 'no-repeat',
      // }
    },
    MuiCheckbox: {
      root:{
        '&>span:first-child':{
          backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
          backgroundSize: '16px 16px',
          backgroundPosition: '5px 5px',
          backgroundRepeat: 'no-repeat',
        }
      }
    },
    MuiPickersDay:{
      day:{
        color: baseTheme.palette.text.secondary,
        height: 25,
        width: 25,
        marginLeft: 8,
        marginRight: 7,
      },
      daySelected:{
        boxShadow: '0px 0px 0px 3px '+baseTheme.palette.primary.main,
      }
    },
    MuiPickersBasePicker:{
      pickerView:{
        minHeight: 200,
      }
    },
    MuiPickersCalendar:{
      transitionContainer:{
        minHeight: 150,
      }
    },
    MuiPickersCalendarHeader:{
      dayLabel:{
        color: baseTheme.palette.secondary.main,
      },
      iconButton:{
        color: baseTheme.palette.primary.main,
        backgroundColor:'transparent',
      },
      switchHeader:{
        marginTop: 0,
        marginBottom: 0,
        maxHeight: 40,
      }
    },
    MuiPickersSlideTransition:{
      transitionContainer:{
        color: baseTheme.palette.text.secondary
      }
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot:{
        backgroundColor:'transparent',
        borderColor: baseTheme.palette.primary.main,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 10,
      },
    },
    MuiFormLabel:{
      root:{
        color: baseTheme.palette.secondary.main,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        }
      },
    },
    // MuiInputLabel:{
    //   formControl:{
    //     [baseTheme.breakpoints.down('sm')]: {
    //       transform: "translate(0, 20px)",
    //     }
    //   }
    // },
    MuiInputBase:{
      root:{
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        }
      }
    },
    MuiInput:{
      input:{
        color: baseTheme.palette.text.secondary,
      },
    },
    MuiMenuItem:{
      root:{
        color: baseTheme.palette.secondary.main,
      }
    }
  },
});

export const transparentTheme = createMuiTheme({
  ...theme,
  overrides:{
    ...theme.overrides,
    MuiFormLabel:{
      root:{
        color: theme.palette.text.primary,
      },
    },
    MuiInput:{
      underline:{
        '&:before': {
          borderBottomColor: theme.palette.text.primary,
        },
      },
    },
    MuiDivider:{
      root:{
        backgroundColor: theme.palette.text.primary,
      }
    },
  }
})

export default theme;