import React from 'react'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Formik, Form, useFormikContext} from 'formik';
import * as Yup from 'yup';
import { Text, Phone, Date } from 'components/form';
import {useMessage} from 'components/message';

import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import {adapter, endpoint} from './config';

const useStyles = makeStyles(theme => ({
  main:{
    backgroundColor: grey[100],
    paddingTop: 30,
    [theme.breakpoints.up('md')]:{
      paddingTop: 75,
    },

  },
  title:{
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 50,
    [theme.breakpoints.up('md')]:{
      paddingBottom: 100,
    },
    '&>*+*':{
      paddingTop: 20,
      [theme.breakpoints.up('md')]:{
        paddingTop: 35,
      }
    }
  },
}));


const CustomerService = () => {
  const classes = useStyles();
  const { enqueueMessage } = useMessage();

  return (
    <Formik
      initialValues={{
        name: '',
        mail: '',
        phone: '',
        zip:'',
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('El nombre es obligatorio'),
        mail: Yup.string()
          .email('Debe ingresar un email válido')
          .required('El Email es obligatorio'),
        phone: Yup.number()
          .typeError('Debe completar con un número')
          .when('mail', (mail, schema) =>
            mail?schema:schema.required('Agregue un medio de contacto')
          ),
        zip: Yup.number()
          .typeError('Debe completar con un número')
          .required('El CP es obligatorio'),
      })}

      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const response = await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(adapter(values)),
          headers: {
            'Content-Type': `application/json;charset=UTF-8`,
            accept: `application/json, text/plain, */*`,
          },
        });

        if(!response.ok){
          enqueueMessage({
            title: 'Algo salió mal!',
            body: 'No hemos podido enviar la solicitud de atención al cliente',
            variant: 'error',
          })
          console.log(response);
        }else{
          enqueueMessage({
            title: 'En breve nos pondremos en contacto!',
            variant: 'success',
          })
        }
        setSubmitting(false);
      }}
    >
    {({isSubmitting}) => {
    return (
      <Form>
        <Grid classes={{root: classes.main}} container justify='center'>
          <Grid classes={{root: classes.title}} container>
            <Grid item container xs={12} justify='center'>
              <Typography variant='subtitle1' color='textSecondary' align='center'>¿Necesitás más información? Ponete en contacto con nuestro servicio de atención al cliente</Typography>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <Typography variant='body2' color='textSecondary' align='center'>Llamanos al (011) 15 3790 8185 o envianos tus datos y te estaremos contactando a la brevedad</Typography>
            </Grid>
          </Grid>
          <Grid container style={{maxWidth: 1000}} justify='center' spacing={3}>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='name' label='Nombre'/>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='mail' label='Email'/>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='phone' label='Teléfono' type='tel'/>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                <Text fullWidth color='secondary' name='zip' label='CP'/>
              </Grid>
            </Grid>
          </Grid>
          <Box component={Grid}
            container
            justify="center"
            alignItems="center"
            height={200}
          >
          {isSubmitting
            ?
            <CircularProgress />
            :
            <Button
              style={{paddingLeft: 30, paddingRight: 30}}
              type="submit"
              variant="contained"
              color="primary"
              size='large'
            >
              Enviar
            </Button>
          }
          </Box>
        </Grid>
      </Form>
    )}}
    </Formik>
  )
}

export default CustomerService
