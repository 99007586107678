import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    registrationSecret: null,
    login: null,
    plan: null,
    plans: null,
  },
  reducers: {
    setRegistrationSecret: (state, action) => {
      state.registrationSecret = action.payload;
    },
    setLogin: (state, action) => {
      state.registrationSecret = null;
      state.login = action.payload;
    },
    updatePlan: (state, action) => ({
      ...state,
      plan: {
        ...state.plan || {},
        ...action.payload,
      }
    }),
    clearPlan: (state, action) => ({
      ...state,
      plan: null,
    }),
    cachePlans: (state, action) => ({
      ...state,
      plans: {
        value: action.payload,
        ts : Date.now(),
      }
    }),
  },
});

export const { setRegistrationSecret, setLogin, updatePlan, clearPlan, cachePlans } = counterSlice.actions;

export const selectRegistrationSecret = state => state.main.registrationSecret;
export const selectUser = ({main:{login}}) => login ? login.user:{};
export const selectToken = ({main:{login}}) => login ? login.access_token:'';
export const selectLogin = state => state.main.login;
export const selectPlan = state => state.main.plan;
export const selectPlans = state => state.main.plans;

export default counterSlice.reducer;
