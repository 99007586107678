import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import Message from  'components/message';
import Header from './header';
import Form from './form';

import { ThemeProvider } from '@material-ui/core/styles';
import { transparentTheme } from 'theme';

import { makeStyles } from '@material-ui/core/styles';

import heroBg from 'img/hero.jpg';
const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    background: `url('${heroBg}')`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]:{
      backgroundPosition: '50% 10%',
    },
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shade: ({shade}) => ({
    transition: theme.transitions.create('background-color'),
    backgroundColor: `rgba(35,35,35,${shade?'.8':'.4'})`,
  }),
  appointmentContainer:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 20px 60px',
    [theme.breakpoints.up('sm')]:{
      padding: '80px 80px 80px'
    },
    [theme.breakpoints.up('md')]:{
      padding: '40px 150px 130px '
    },
    [theme.breakpoints.up('lg')]:{
      padding: '100px 150px 130px'
    },
  },
  subtitle:{
    fontFamily: 'ProximaNova',
    marginTop: 20,
    marginBottom: 30,
    [theme.breakpoints.up('sm')]:{
      marginTop: 50,
      marginBottom: 80,
    }
  },
  buttonLabel: {
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up('sm')]:{
      marginLeft: 50,
      marginRight: 50,
    }
  },

  additionalText: {
    marginTop: -20,
  },

}))


const Hero = ({redirect}) => {
  const [openApointment, setOpenApointment] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useStyles({shade:openApointment && !sent});
  return (
    <ThemeProvider theme={transparentTheme}>
      <Container classes={{root: classes.container}} maxWidth={false} disableGutters>
        <Container classes={{root: classes.shade}} maxWidth={false} disableGutters>
          <Header redirect={redirect}/>
          <Container classes={{root: classes.appointmentContainer}} maxWidth={false} disableGutters>
            <Typography variant='h1' align='center' style={{fontWeight: 500}}>Alquiler de bauleras y guardamuebles</Typography>
            <Typography variant='subtitle1' align='center' classes={{root: classes.subtitle}}>
              Nos ocupamos de todo. Mudamos, guardamos y cuidamos tus cosas. 
            </Typography>
            <Typography variant='subtitle1' align='center' classes={{ root: `${classes.subtitle} ${classes.additionalText}` }}>
              Simple, seguro y sin moverte de tu casa.
            </Typography>
            <Collapse in={!openApointment}>
              <Button
                onClick={()=>setOpenApointment(true)}
                classes={{label: classes.buttonLabel}}
                variant="contained"
                color="primary"
                size='large'
              >
                Cotizá tu baulera ahora
              </Button>
            </Collapse>
            <Collapse in={openApointment && !sent}>
              <Form onSent={()=>setSent(true)}/>
            </Collapse>
            <Collapse in={sent}>
              <Message
                title='Gracias por dejarnos tus datos!'
                body='En breve nos pondremos en contacto!'
              />
            </Collapse>
          </Container>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export default Hero
