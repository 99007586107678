export const endpoint = `${process.env.REACT_APP_API}/cita`;

export const adapter = (collected) => {
  const {
  name,
  phone,
  date,
  recaptcha,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    name: name,
    phone: phone,
    date: date,
    recaptcha: recaptcha,
  };
  //console.log("Adapted data: ", adapted);
  return adapted;
};
