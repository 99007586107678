export const endpoint = `${process.env.REACT_APP_API}/atencion`;

export const adapter = (collected) => {
  const {
  name,
  mail,
  phone,
  zip,
  }  = collected;
  //console.log("Collected data: ", collected);

  const adapted = {
    name: name,
    mail: mail,
    phone: phone,
    zip: zip,
  };
  //console.log("Adapted data: ", adapted);
  return adapted;
};
