import React, {useEffect} from 'react'
import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';

import VerificationInput from 'react-verification-input'


import { Text, Phone, Date, Switch, Checkbox } from 'components/form';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useMessage } from 'components/message';
import { useHistory } from "react-router-dom";

import { adapterIn, adapterOut, endpoint } from './config';

import { selectRegistrationSecret, setLogin } from 'features/mainSlice';

import { Formik, Form, Field, useFormikContext, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const Validate = () => {
  const registrationSecret = useSelector(selectRegistrationSecret);
  const dispatch = useDispatch();
  const { enqueueMessage } = useMessage();
  const history = useHistory();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=>{
    if(!registrationSecret) history.replace('/');
  }, [])

  return (
    <Formik
      initialValues={{
        code: '',
      }}

      validationSchema={Yup.object({
        code: Yup.string()
          .length(4, 'El código no es correcto')
          .required('Falta completar este campo'),
      })}

       onSubmit={async (values, { setSubmitting }) => {
        console.log("SUBMIT ", values)
        setSubmitting(true);
        const response = await fetch(endpoint, {
          method: "POST",
          body: adapterOut({
            secret: registrationSecret,
            ...values
          }),
          headers: {
            'Content-Type': `application/json`,
            accept: `application/json`,
          }
        });
        setSubmitting(false);

        if(!response.ok){
          enqueueMessage({
            title: 'Algo salió mal!',
            body: 'No pudimos validar su código',
            variant: 'error',
          })

        }else{
          const result = adapterIn(await response.json());
          dispatch(setLogin(result));
          history.push('/confirm-address')
        }
      }}

    >
    {({ isSubmitting }) => (
      <Box component={Form} bgcolor='grey.100' pt={6}>
        <Grid container justify='center'>
          <Grid container justify='center'>
            <Hidden smDown>
              <Box
                mb={6}
                component={Typography}
                variant='subtitle1'
                color='text.secondary'
                align='center'
                gutterBottom
              >
                  Validá tu cuenta de Todo en Orden
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box
                component={Typography}
                fontWeight='bold'
                variant='h2'
                color='text.secondary'
                align='center'
                gutterBottom
              >
                  Validá tu cuenta
              </Box>
            </Hidden>
          </Grid>
          <Box component={Grid}
            py={3} mb={6} px={2}
            bgcolor={{ md: theme.palette.bg }}
            container style={{
              maxWidth: 1000,
              borderRadius: 15,
            }}
            justify='center'
            spacing={3}
          >
            <Box
              component={Grid}
              item container
              xs={12}
              justify='center'
              direction='column'
            >
              <Box
                component={Typography}
                variant='subtitle1'
                color='text.secondary'
                align='center'
                gutterBottom
                fontSize={{xs: 20}}
              >
                Te mandamos un código de seguridad a tu Email.
              </Box>
              <Box
                component={Typography}
                variant='subtitle1'
                color='text.secondary'
                align='center'
                gutterBottom
                fontSize={{xs: 20}}
              >
                Ingresalo a continuación:
              </Box>
            </Box>
            <Grid item container xs={12} justify='center'>
              <Grid item container xs={10} sm={9} justify='center'>
                {/*<Text fullWidth color='secondary' name='code' label='Código de seguridad *' /> */}
                <Field name="code">
                  { ({field}) =>
                  <VerificationInput
                    length={4}
                    placeholder=''
                    validChars='0-9'
                    characters={{
                      style: {
                        height: '70px',
                      }
                    }}
                    character={{
                      style: {
                        width: 50,
                        backgroundColor: 'white',
                        border: 'none',
                        color: theme.palette.secondary.main,
                        fontFamily: theme.typography.body1.fontFamily,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      },
                      classNameSelected: 'character--selected',
                    }}
                    classNameSelected
                    inputField={{
                      ...field,
                      autocomplete: "off",
                    }}
                    autoFocus
                  />}
                </Field>
              </Grid>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <Typography
                component={Box}
                fontSize={20}
                mt={3}
                variant='body2'
                color='textSecondary'
                align='center'
              >
                Ingresar los 4 dígitos
              </Typography>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <Box
                component={Button}
                mt={2} px={6}
                type='submit'
                variant="contained"
                color="text.primary"
                style={{backgroundColor: theme.palette.primary.main}}
                size={sm?'large':'medium'}
                disabled={isSubmitting}
              >
                Validar tu cuenta
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    )}
    </Formik>
  )
}

export default Validate
